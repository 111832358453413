import { Chip } from '@mui/material';
import { PromoCodeResponse } from '../../../../services/api/promo-code';

const PromoChipTag = ({
  promo,
  quantity,
  srcPrice
}: {
  promo?: PromoCodeResponse;
  quantity?: number;
  srcPrice: number;
}) => {
  if (!promo) {
    return <div />;
  } else if (promo.discountType === 'FIXED_AMOUNT_OFF') {
    return (
      <Chip
        label={`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(
          (quantity ?? 1) * promo.discountAmount > srcPrice
            ? srcPrice * (quantity ?? 1)
            : (quantity ?? 1) * promo.discountAmount
        )} de réduction`}
        sx={{ fontWeight: 800, height: 24 }}
        color="primary"
      />
    );
  } else if (promo.discountType === 'PERCENTAGE_OFF') {
    return (
      <Chip label={` réduction de -${promo.discountAmount}%`} sx={{ fontWeight: 800, height: 24 }} color="primary" />
    );
  } else {
    return <div />;
  }
};

export default PromoChipTag;
