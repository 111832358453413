import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import DesktopDrawer from '../../components/DesktopDrawer';
import noMessagesImage from '../../assets/images/empy/no-messages.png';
import { useAppSelector } from '../../redux/hooks';
import Login from '../Login';
import LogoButton from '../../components/LogoButton';

function Marketplace() {
  const { isAuth } = useAppSelector(state => state.authSlice);

  if (!isAuth) {
    return <Login />;
  }

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <LogoButton />
          <Typography variant="h5">{t('marketplace.marketplace')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
          <DesktopDrawer />
        </Stack>
      </Stack>
      <Box sx={{ mr: 6, ml: 6 }}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} minHeight="90vh">
          <Box>
            <img src={noMessagesImage} />
          </Box>

          <Typography variant="h4" pt={3}>
            {t('marketplace.marketPlaceCommingSoon')}
          </Typography>

          <Typography variant="xl">{t('marketplace.marketPlaceCommingSoonText')}</Typography>
          <Box height={64} />
        </Stack>
      </Box>
    </>
  );
}

export default Marketplace;
