import { Card, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { IFormData } from '../..';
import { User } from '../../../../services/api/user';
import greyScale from '../../../../themes/default/colors/greyscale';

export interface UserInfosProps {
  user: User | undefined;
  userLoading: boolean;
}

const UserInfos = (props: UserInfosProps) => {
  const { values, handleChange, touched, errors } = useFormikContext<IFormData>();

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }}>
      {
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
            <Typography variant="md" color={greyScale[700]} minWidth={70}>
              {t('checkout.lastName')}
            </Typography>
            <TextField
              name="lastName"
              variant="standard"
              value={values.lastName}
              onChange={handleChange}
              placeholder={t('checkout.lastName') ?? ''}
              fullWidth
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
            <Typography variant="md" color={greyScale[700]} minWidth={70}>
              {t('checkout.firstName')}
            </Typography>
            <TextField
              name="firstName"
              variant="standard"
              value={values.firstName}
              onChange={handleChange}
              placeholder={t('checkout.firstName') ?? ''}
              fullWidth
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
            <Typography variant="md" color={greyScale[700]} minWidth={70}>
              {t('checkout.mail')}
            </Typography>
            <TextField
              name="email"
              variant="standard"
              value={values.email}
              onChange={handleChange}
              placeholder={t('checkout.mail') ?? ''}
              fullWidth
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
        </>
      }
    </Card>
  );
};

export default UserInfos;
