import {
  Alert,
  alpha,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import staduim from '../../assets/images/stade-blagnac.jpeg';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import shoppingCartSlice from '../../services/shoppingCart';
import greyScale from '../../themes/default/colors/greyscale';
import palette from '../../themes/default/colors/palette';
import shadows from '../../themes/default/shadows';
import DesktopDrawer from '../../components/DesktopDrawer';
import Footer from '../../components/Footer';
import { useFormik } from 'formik';
import { RootState } from '../../redux/store';
import subscriptionApi, { Tarif } from '../../services/api/subscription';
// @ts-ignore
// import { SeatsioSeatingChart } from '@seatsio/seatsio-react';
//

interface TicketCategoryProps {
  tarif: Tarif;
  subscriptionUuid: string;
  isAvailable: boolean;
  quantity: number;
}
interface RankProps {
  tarif: Tarif;
  subscriptionUuid: string;
  tarifName: string;
  isAvailable: boolean;
  quantity: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  width: { xs: '80%', sm: '50%', lg: '40%', xl: '30%' }
};

const TicketRank = (props: RankProps) => {
  const { subscriptionUuid, tarifName, isAvailable, tarif } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const dispatch = useAppDispatch();
  const { tickets: selectedTickets } = useAppSelector((app: RootState) => app.shoppingCartSlice);

  const addTicket = () => {
    // if (
    //   selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity >=
    //   subTicketCategory.quantityAvailable
    // ) {
    //   setErrorMessage(`Il ne reste plus que ${subTicketCategory.quantityAvailable} places disponibles`);
    //   setErrorSnackbar(true);

    //   return;
    // }
    // if (
    //   selectedTickets.filter(e => e.categoryUuid === subTicketCategory.uuid)[0]?.quantity >=
    //     subTicketCategory.maxPerPerson &&
    //   subTicketCategory.maxPerPerson !== null
    // ) {
    //   setErrorMessage(`Vous pouvez acheter au maximum ${subTicketCategory.maxPerPerson} places par personne`);
    //   setErrorSnackbar(true);

    //   return;
    // }

    dispatch(shoppingCartSlice.actions.addSubscription({ subscriptionUuid, tarif: { ...tarif, name: tarifName } }));
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    dispatch(shoppingCartSlice.actions.resetSubscription());

    for (let index = 0; index < props.quantity; index++) {
      if (quantity < props.quantity) addTicket();
    }
  }, []);

  // TODO bug remove tarif

  const removeTicket = () => {
    if (quantity === 0) return;
    dispatch(shoppingCartSlice.actions.removeSubscription({ subscriptionUuid, tarif: { ...tarif, name: tarifName } }));
    setQuantity(quantity - 1);
  };

  useEffect(() => {}, []);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  // const ticketLeft = () => {
  //   if (subTicketCategory.quantityAvailable > 5) {
  //     return null;
  //   } else if (subTicketCategory.quantityAvailable == 0) {
  //     return null;
  //   } else {
  //     return (
  //       <Typography variant="md" color="error.main">{`(${subTicketCategory.quantityAvailable} ${t('reservation.left', {
  //         count: subTicketCategory.quantityAvailable
  //       })})`}</Typography>
  //     );
  //   }
  // };

  const selectTicketButtons = () => {
    if (true) {
      // if (tarif.isAvailable) {
      return (
        <Stack direction="row" alignItems="center">
          <IconButton
            disabled
            // onClick={removeTicket}
            sx={{
              border: 2,
              height: 40,
              width: 40,
              borderRadius: 16,
              borderColor: '#E0E0E0'
            }}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="h6" p={1.5}>
            {quantity}
          </Typography>
          <IconButton
            disabled
            // onClick={addTicket}
            sx={{
              border: 2,
              height: 40,
              width: 40,
              borderRadius: 16,
              borderColor: '#E0E0E0'
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      );
    } else {
      return (
        <Stack direction="column" alignItems="end">
          <Box bgcolor={alpha(palette.error, 0.12)} borderRadius={1} height={24} pr={2} pl={2}>
            <Typography variant="xs" color="error.main" fontWeight={700}>
              {t('reservation.notAvailable')}
            </Typography>
          </Box>
        </Stack>
      );
    }
  };

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>{' '}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column">
          <Typography variant="md" color={greyScale[500]} mb={1}>
            {tarif.description}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="xl" fontWeight={600} color={greyScale[400]}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(tarif.amount)}`}</Typography>
            &nbsp;&nbsp;
            {/* {ticketLeft()} */}
          </Stack>
        </Stack>
        {selectTicketButtons()}
      </Stack>
    </>
  );
};

const TicketCategoryComponent = (props: TicketCategoryProps) => {
  const { isAvailable, subscriptionUuid, tarif, quantity } = props;

  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={props.isAvailable ? () => setExpanded(!expanded) : () => null}
      >
        <Typography variant="h5" sx={{ opacity: props.isAvailable ? 1 : 0.4 }}>
          {tarif.name}
        </Typography>
      </Stack>
      <TicketRank
        key={tarif.name}
        subscriptionUuid={subscriptionUuid}
        tarifName={tarif.name}
        tarif={tarif}
        quantity={quantity}
        isAvailable={tarif.isAvailable}
      />
    </>
  );
};

function SubscriptionReservation() {
  const { subscriptionUuid } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  //  TODO to remove
  const subscriptionInvite = urlParams.get('subscriptionInvite');

  const navigate = useNavigate();

  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPromo, setShowPromo] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const { subscriptionsBasket } = useAppSelector(state => state.shoppingCartSlice);

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const continueButtonStyle = isDesktop ? { mb: 10 } : { position: 'fixed', bottom: 0, left: 0, right: 0, mb: 3 };

  const { data: subscription, isLoading } = subscriptionApi.endpoints.subscriptionByInvite.useQuery(
    subscriptionInvite ?? ''
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promoCode: ''
    },
    onSubmit: async values => {
      try {
        if (formik.values.promoCode === 'Caouec50') {
          setShowPromo(true);
          setShowPromoModal(false);
        } else {
          formik.setErrors({ promoCode: t('reservation.promoCodeError') ?? '' });
        }
      } catch (error) {}
    }
  });

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const checkTickets = async () => {
    if (subscriptionsBasket.length <= 0) {
      setErrorMessage(t('reservation.pleaseSelectATicket') ?? '');
      setErrorSnackbar(true);
      return false;
    }
    return true;
  };

  const goToCheckout = async () => {
    // if (await checkTickets()) {
    navigate(
      `/subscription-checkout/${subscription?.uuid}${
        subscriptionInvite ? `?subscriptionInvite=${subscriptionInvite}` : ''
      }`
    );
    //
  };

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Modal open={showPromoModal} onClose={() => setShowPromoModal(false)}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3}>
              <Typography variant="h5" textAlign="center">
                {t('reservation.promoCode')}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
                <TextField
                  name="promoCode"
                  variant="standard"
                  value={formik.values.promoCode}
                  onChange={formik.handleChange}
                  placeholder={t('reservation.promoCode') ?? ''}
                  fullWidth
                  error={formik.touched.promoCode && Boolean(formik.errors.promoCode)}
                  helperText={formik.touched.promoCode && formik.errors.promoCode}
                />
              </Stack>
              <Button type="submit">Valider</Button>
            </Stack>
          </Box>
        </form>
      </Modal>
      <Box minHeight="100vh">
        <header>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2} ml={1} mb={1} spacing={1}>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={() => navigate(`/subscription/${subscriptionUuid}`)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4">{t('reservation.reserveAplace')}</Typography>
            </Stack>
            <Box pr={2}>
              <DesktopDrawer />
            </Box>
          </Stack>
        </header>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Stack direction="column" justifyContent="center" alignItems="center" sx={{ width: { xs: '100%', md: 900 } }}>
            <Box justifyContent="center" alignItems="center" sx={{ width: { xs: '100%', md: 900 } }}>
              <Stack direction="row" justifyContent="center" alignItems="center" mt={3}>
                <img src={staduim} style={{ borderRadius: 24 }} width={isDesktop ? '70%' : '90%'} />
              </Stack>

              {!subscription || isLoading ? null : (
                <Box m={3}>
                  <Box height={24} />
                  {subscription?.tarifs?.map((t, i) => (
                    <Box
                      key={t.name}
                      sx={{
                        '&:hover:not(.Mui-disabled)': {
                          cursor: 'pointer'
                        }
                      }}
                    >
                      <TicketCategoryComponent
                        tarif={t}
                        subscriptionUuid={subscriptionUuid ?? ''}
                        isAvailable={subscription?.isAvailible ?? false}
                        quantity={subscription.quantity}
                      />
                      {subscription?.tarifs?.length === i + 1 ? null : (
                        <Box mt={1} mb={1}>
                          <Divider />
                        </Box>
                      )}
                    </Box>
                  ))}

                  <Box height={32} />
                  {/* <BasketCard event={event} /> */}
                  <Box sx={{ height: { xs: 120, md: 32 } }} />
                  <Box m={3} sx={continueButtonStyle} zIndex={1000}>
                    <Stack direction="column" justifyContent="center" alignItems="center" width="100%" spacing={1}>
                      <Button
                        variant="gradient"
                        fullWidth
                        // disabled={!subscription?.isAvailible}
                        disabled={false}
                        onClick={goToCheckout}
                        sx={{
                          height: 58,
                          boxShadow: shadows.primaryButton,
                          borderRadius: 4,
                          width: { xs: '100%', md: 200 }
                        }}
                      >
                        {/* {subscription?.isAvailible ? t('reservation.continue') : t('reservation.noTicketsAvailable')}{' '} */}
                        {true ? t('reservation.continue') : t('reservation.noTicketsAvailable')} &nbsp;-&nbsp;
                        {`${Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(subscriptionsBasket.reduce((a, b) => a + b.price * b.quantity, 0))}`}
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}

export default SubscriptionReservation;
