const secondary = {
  100: '#D8F7FF',

  200: '#9CEBFF',

  300: '#7BE5FF',

  400: '#51DCFF',

  500: '#e10c19'
};

export default secondary;
