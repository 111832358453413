import { createSlice } from '@reduxjs/toolkit';

export interface ThemeSliceProps {
  darkTheme: boolean;
}

const initialState: ThemeSliceProps = {
  darkTheme: true
};
export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    toggleTheme: state => {
      state.darkTheme = !state.darkTheme;
    }
  }
});

export default themeSlice;
