import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { Box } from '@mui/material';
import DesktopDrawer from '../../../../components/DesktopDrawer';
import SearchBar from '../../../../components/SearchBar';
import ShareModal from '../ShareModal';
import LogoButton from '../../../../components/LogoButton';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    // minHeight: 128,
  }
}));

interface DesktopAppBarProps {
  eventId: string;
}

const DesktopAppBar = (props: DesktopAppBarProps) => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{ mt: -2, mb: 0, alignItems: 'center', justifyContent: 'center' }}
      >
        <Box width="100%" maxWidth={1100}>
          <StyledToolbar sx={{ width: '100%' }}>
            <Box mt={-1}>
              <LogoButton />
            </Box>
            <Box display="flex" alignItems="center" ml={10} width="50%">
              <SearchBar />
            </Box>
            <Stack direction="row" mt={1}>
              <Box display="flex">
                <ShareModal url={`${import.meta.env.VITE_APP_BASE_URL}/event/${props.eventId}`} isDesktop />
              </Box>
              <DesktopDrawer />
            </Stack>
          </StyledToolbar>
        </Box>
      </AppBar>
    </Box>
  );
};

export default DesktopAppBar;
