import { TextField, TextFieldProps } from '@mui/material';
import { t } from 'i18next';
import { forwardRef, Ref } from 'react';

const CustomPhoneNumber = (props: JSX.IntrinsicAttributes & TextFieldProps, ref: Ref<any> | undefined) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      size="small"
      placeholder={t('login.phoneNumber') ?? ''}
      variant="outlined"
      name="phone"
      InputLabelProps={{ sx: { pt: 0.75 } }}
      inputProps={{ style: { height: 40 } }}
    />
  );
};
export default forwardRef(CustomPhoneNumber);
