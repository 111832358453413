import defaultTheme from '../../themes/default/theme';
import lesDeterminesTheme from '../../themes/lesDetermines/theme';
import NiceTheme from '../../themes/NiceTheme/theme';
import blagnacTheme from '../../themes/default-initial/theme';
import { Theme } from '@mui/material';

// Define your list of club hostnames and their corresponding themes
const clubThemeHostnameMappings: Record<string, (arg: boolean) => Theme> = {
  localhost1: defaultTheme,
  localhost: lesDeterminesTheme,
  'billetterie-blagnac.tickie.io': blagnacTheme,
  'collectibles.lesdetermines.tickie.io': lesDeterminesTheme

  // more hostnames here
};
const clubLogoHostnameMappings: Record<string, string> = {
  'collectibles.lesdetermines.tickie.io':
    'https://www.lesdetermines.fr/wp-content/themes/les-determines/assets/img/logo-white.png',
  localhost1:
    'https://upload.wikimedia.org/wikipedia/fr/thumb/5/52/Blason_%C3%A9toile_du_sahel.svg/1200px-Blason_%C3%A9toile_du_sahel.svg.png',
  localhost: 'https://www.lesdetermines.fr/wp-content/themes/les-determines/assets/img/logo-white.png',
  'billetterie-blagnac.tickie.io':
    'https://static.wixstatic.com/media/54ca1e_4934250b7cd949fabc141940acadea71~mv2.png/v1/fill/w_154,h_175,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LOGO_BR%20(1)_PNG.png'

  // more hostnames here
};

const clubNameHostnameMappings: Record<string, string> = {
  '127.0.0.1': 'DTR CUP 2023 | powered by Tickie',
  localhost: 'DTR CUP 2023 | powered by Tickie',
  'tickie-app-git-final-nisa10880.vercel.app': 'DTR CUP 2023 | powered by Tickie',
  'collectibles.lesdetermines.tickie.io': 'DTR CUP 2023 | powered by Tickie',
  'billetterie-blagnac.tickie.io': 'Blagnac Rugby'
};

const faviconHostnameMappings: Record<string, string> = {
  '127.0.0.1': 'https://www.tickie.io/favicon.ico',
  'tickie-app-git-final-nisa10880.vercel.app': 'https://www.tickie.io/favicon.ico',
  'collectibles.lesdetermines.tickie.io': 'https://www.tickie.io/favicon.ico',
  localhost1: 'https://www.etoile-du-sahel.net/assets/img/logo.png',
  localhost:
    'https://static.wixstatic.com/media/54ca1e_047f61ddf913471585783ca7b4a7c853%7Emv2_d_2213_2567_s_2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/54ca1e_047f61ddf913471585783ca7b4a7c853%7Emv2_d_2213_2567_s_2.png',
  'billetterie-blagnac.tickie.io':
    'https://static.wixstatic.com/media/54ca1e_047f61ddf913471585783ca7b4a7c853%7Emv2_d_2213_2567_s_2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/54ca1e_047f61ddf913471585783ca7b4a7c853%7Emv2_d_2213_2567_s_2.png'
};

const linksHostnameMappings: Record<string, Object> = {
  localhost1: {
    'Site Officiel': 'https://www.facebook.com/etoile.sahelienne',
    Billetterie: 'https://twitter.com/etoile_sahel',
    instagram: 'https://www.instagram.com/etoile_du_sahel_',
    Boutique: 'https://www.youtube.com/user/etoiledusahel1936',
    Buisness: 'https://www.linkedin.com/company/etoile-du-sahel'
  },
  localhost: {
    'Site Officiel': 'https://www.blagnac-rugby.fr',
    Billetterie: 'https://billetterie-blagnac.tickie.io/home',
    sousse: 'https://www.blagnac-rugby.fr/boutique',
    stade: 'https://www.blagnac-rugby.fr/blagnac-rugby-event'
  }
};

// Function to get the theme based on the hostname
export const getClubThemeFromHostname = (darkTheme: boolean): any => {
  const currentHostname: string = window.location.hostname;
  const clubThemeGetter: (arg: boolean) => Theme | undefined =
    clubThemeHostnameMappings[currentHostname] || clubThemeHostnameMappings['localhost'];
  const clubTheme: Theme | undefined = clubThemeGetter(darkTheme);

  return clubTheme;
};

export const getClubLogoFromHostname = (): string => {
  const currentHostname: string = window.location.hostname;
  const clubLogoGetter: string = clubLogoHostnameMappings[currentHostname] || clubLogoHostnameMappings['localhost'];

  return clubLogoGetter;
};

export const getClubNameFromHostname = (): string => {
  const currentHostname: string = window.location.hostname;
  const clubNameGetter: string = clubNameHostnameMappings[currentHostname] || clubNameHostnameMappings['localhost'];

  return clubNameGetter;
};

export const getFaviconFromHostname = (): string => {
  const currentHostname: string = window.location.hostname;
  const faviconGetter: string = faviconHostnameMappings[currentHostname] || faviconHostnameMappings['localhost'];

  return faviconGetter;
};

export const getLinksFromHostname = (): Object => {
  const currentHostname: string = window.location.hostname;
  const linksGetter: Object = linksHostnameMappings[currentHostname] || linksHostnameMappings['localhost'];

  return linksGetter;
};
