import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import paymentApi, { PurchaseType } from '../../services/api/payment';
import { Box, Card, CircularProgress, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import userApi from '../../services/api/user';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import BasketCard from './components/BasketCard';
import UserInfos from './components/UserInfos';
import CheckoutForm from './components/CheckoutForm';
import Footer from '../../components/Footer';
import OrganizationEventsCard from '../../components/OrganizationEventsCard';
import eventApi from '../../services/api/event';
import * as Yup from 'yup';
import { Formik } from 'formik';
import UnhandledError from '../../components/UnhandledError';
import Login from '../Login';
import PromoCodeModal from '../Reservation/components/PromoCodeModal';
import IhavePromoCodeButton from './components/IhavePromoCodeButton';
import InvitForm from './components/Invite';

export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

const Checkout = () => {
  /**
   * Router
   */
  const { eventId } = useParams();
  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');
  /**
   * API calls
   */
  const { data: user, isLoading: userLoading } = userApi.endpoints.getUser.useQuery();
  const { data: event, isLoading, isError } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const [createPaymentIntent, { data: paymentIntent }] = paymentApi.endpoints.createPaymentIntent.useMutation();
  /**
   * local state
   */
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);
  /**
   * redux
   */
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { tickets: selectedTickets, finalPrice, srcPrice, promo } = useAppSelector(app => app.shoppingCartSlice);
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  /**
   *  theme
   */
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (selectedTickets.length == 0) {
      navigate(`/reservation/${eventId}${promoCodeFromUrl ? `?promoCode=${promoCodeFromUrl}` : ''}`);
    }
  }, []);

  useEffect(() => {
    if (finalPrice > 0) {
      const fetchStripeObject = async () => {
        // If there is no accountId, do not run the loadStripe function.
        if (event?.organization?.stripeAccountId) {
          const res = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
            stripeAccount: event?.organization?.stripeAccountId
          });
          // When we have got the Stripe object, pass it into our useState.
          setStripeObject(res);
        }
      };
      fetchStripeObject();
    }
  }, [event, finalPrice]);

  useEffect(() => {
    if (user && event && finalPrice > 0) {
      createPaymentIntent({
        amount: finalPrice,
        currency: 'eur',
        metadata: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          eventUuid: eventId as string,
          places: selectedTickets.map(cat => {
            return { categoryUuid: cat.categoryUuid, quantity: cat.quantity };
          }),
          codePromoUuid: selectedTickets.find(e => e.promo)?.promo?.uuid ?? undefined,
          finalPrice: finalPrice,
          srcPrice: srcPrice,
          status: 'COMPLETE',
          userUuid: user.uuid,
          purchaseType: PurchaseType.Ticket,
          subscription: undefined
        }
      }).unwrap();
    }
  }, [user, event, finalPrice]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !event) {
    return <UnhandledError />;
  }

  function appBar() {
    return (
      <Stack direction="row" alignItems="center" mt={2} mb={3} spacing={1}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{t('checkout.recap')}</Typography>
      </Stack>
    );
  }

  const checkoutForm = () => {
    if (!isAuth && isDesktop && !eventId) {
      return null;
    }
    if (paymentIntent && paymentIntent?.client_secret && finalPrice > 0) {
      return (
        <>
          <Elements
            options={{
              clientSecret: paymentIntent?.client_secret,
              appearance: { theme: darkTheme ? 'night' : 'flat', labels: 'floating' }
            }}
            stripe={stripeObject}
          >
            <CheckoutForm paymentIntent={paymentIntent} eventId={eventId ?? ''} />
          </Elements>
        </>
      );
    } else if (finalPrice <= 0 && eventId) {
      return <InvitForm eventId={eventId} />;
    }
  };

  return (
    <Box>
      <PromoCodeModal showPromoModal={showPromoModal} setShowPromoModal={setShowPromoModal} />
      <Box minHeight="calc(100vh - 180px)">
        <header>{appBar()}</header>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email ?? ''
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t('formErrors.firstNameEmpty') ?? ''),
            lastName: Yup.string().required(t('formErrors.lastNameEmpty') ?? ''),
            email: Yup.string()
              .email(t('formErrors.mailNotValid') ?? '')
              .required(t('formErrors.mailNotValid') ?? '')
          })}
          onSubmit={async values => {
            try {
            } catch (error) {}
          }}
        >
          {userLoading ? (
            <Stack direction="row" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
              <CircularProgress></CircularProgress>
            </Stack>
          ) : (
            <Box m={2}>
              {isDesktop ? (
                <>
                  <Box display="flex" justifyContent="center">
                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                      <Stack direction="column">
                        {isAuth && <UserInfos user={user} userLoading={userLoading} />}
                        {checkoutForm()}
                      </Stack>
                      <Stack direction="column">
                        <Box width="100%" sx={{ position: { md: 'sticky' }, top: { md: 0 } }}>
                          <BasketCard event={event} showEventCard />
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                  <Stack direction="row" justifyContent="center" mb={-9}>
                    {<IhavePromoCodeButton setShowPromoModal={setShowPromoModal} />}
                  </Stack>
                  {isAuth ? null : (
                    <Card sx={{ mb: 3, mt: 8 }}>
                      <Login
                        isReservation
                        redirectLink={`${import.meta.env.VITE_APP_BASE_URL}/reservation/${eventId}`}
                      />
                    </Card>
                  )}
                </>
              ) : (
                <>
                  <OrganizationEventsCard event={event} />
                  {isAuth ? <UserInfos user={user} userLoading={userLoading} /> : null}
                  <IhavePromoCodeButton setShowPromoModal={setShowPromoModal} />
                  <BasketCard event={event} showEventCard />

                  {isAuth ? (
                    checkoutForm()
                  ) : (
                    <Card variant="contained" sx={{ mb: 3 }}>
                      <Login
                        isReservation
                        redirectLink={`${import.meta.env.VITE_APP_BASE_URL}/reservation/${eventId}`}
                      />
                    </Card>
                  )}
                </>
              )}
            </Box>
          )}
        </Formik>
      </Box>
      <Footer />
    </Box>
  );
};

export default Checkout;
