import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { Stack } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { HomeEvent } from '../../services/api/event';
import format from 'date-fns/format';
import greyScale from '../../themes/default/colors/greyscale';
import { useAppSelector } from '../../redux/hooks';
import { capitalize } from '../../utils/strings';
import vector from '/btnIcon.svg';
import defaultImg from '/default.png';

interface OnDispayEventCardProps {
  event: HomeEvent;
  isEvent?: boolean;
}

const CustomOnDispayEventCard = (props: OnDispayEventCardProps) => {
  const { event } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[420px] flex flex-col p-4 mx-5 rounded-md "
      onClick={() => navigate(props.isEvent ? `/event/${event.uuid}` : `/claim/${event.uuid}`)}
    >
      <div className="w-[300px] h-[405px] rounded-md  border border-white z-10 flex flex-col  items-center shadow-sm shadow-white">
        <img src={event.cover ?? defaultImg} alt="defaultImg" className="w-full rounded-t-md " />
        <div className="font-bold text-white mt-4 text-xl text-center"> {event?.title}</div>
        <div className="font-bold text-red-500 text-sm text-center ">{event?.subTitle}</div>
        <div className="text-md my-2">{`${capitalize(format(new Date(event?.beginAt), 'eeee dd MMMM'))}`}</div>
        <div className="text-md text-gray-400 mb-4">{event?.address?.name}</div>
        {/* <div>{event?.price}</div> */}
        <div></div>
        <div
          // onClick={() => navigate(`/event/${nearestEvent?.uuid}`)}
          className=" w-[230px] h-[54px] flex justify-center rounded-md items-center bg-red-600 font-bold cursor-pointer z-10 mb-10"
        >
          Choisir ma place
          <img src={vector} alt="vector" className="ml-5 w-[17px]" />
        </div>
      </div>
    </div>
  );
};

export default CustomOnDispayEventCard;
