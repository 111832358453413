import Reservation from './pages/Reservation';
import Checkout from './pages/Checkout';
import { createBrowserRouter, Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom';
import EventDetails from './pages/EventDetails';
import Home from './pages/Home';
import PaymentSuccess from './pages/PaymentSucces';
import MyTickets from './pages/MyTickets';
import TicketDetails from './pages/TicketDetails';
import Root from './Root';
import Login from './pages/Login';
import PhoneLogin from './pages/Login';
import CompleteProfile from './pages/CompleteProfile';
import Profile from './pages/Profile';
import Notifications from './pages/Notifications';
import Marketplace from './pages/Marketplace';
import Settings from './pages/Settings';
import OrganizationDetails from './pages/OrganizationDetails';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { useEffect } from 'react';
import userApi from './services/api/user';
import { useWeb3Auth } from './services/web3/web3auth';
import BlockchainSettings from './pages/Settings/components/Blockchain';
import EditProfile from './pages/Settings/components/EditProfile';
import { Badge, styled, Typography } from '@mui/material';
import palette from './themes/default/colors/palette';
import { auth } from './services/firebase';
import { getIdToken } from '@firebase/auth';
import Pitch from './pages/Profile/Pitch';
import Claim from './pages/Claim';
import RewardsPage from './pages/Rewards';
import CustomHome from './pages/CustomHome ';
import NiceHome from './pages/CustomHome /Nice';
import LesDeterminesHome from './pages/CustomHome /lesDetermines';
import SubscriptionDetails from './pages/SubscriptionDetails';
import SubscriptionHome from './pages/SubscriptionHome';
import SubscriptionReservation from './pages/SubscriptionReservation ';
import SubscriptionCheckout from './pages/SubscriptionCheckout';
import SubscriptionPaymentSuccess from './pages/SubscriptionPaymentSucces';
import SubscriptionPass from './pages/SubscriptionPass';
import Scan from './pages/scan';
import CustomFooter from './components/CustomFooter ';
import RootWithoutFooter from './RootWithoutFooter';

const CustomBadge = styled(Badge)(({ theme }) => ({
  background: palette.error,
  color: '#fff',
  position: 'fixed',
  right: -10,
  top: -40,
  zIndex: 1,
  transform: 'rotate(-45deg)',
  width: 56,
  height: 110,
  textAlign: 'center',
  padding: '40px 2px',
  boxShadow: theme.shadows[1]
}));

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootRouteComponent />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-with-phone',
        element: <PhoneLogin />
      },
      {
        path: '/complete-profile',
        element: <CompleteProfile />
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '',
            element: <CustomHome />
          },
          {
            path: 'home',
            element: <CustomHome />
          },
          {
            path: '/marketplace',
            element: <Marketplace />
          },
          {
            path: '/my-tickets',
            element: <MyTickets />
          },
          {
            path: '/messages',
            element: <Notifications />
          },
          {
            path: '/profile',
            element: <Profile />,
            children: []
          }
        ]
      },
      {
        path: '/event/:eventId',
        element: <EventDetails />
      },
      {
        path: '/subscription/:subscriptionUuid',
        element: <SubscriptionDetails />
      },
      {
        path: '/subscriptions',
        element: <SubscriptionHome />
      },
      {
        path: '/subscription-reservation/:subscriptionUuid',
        element: <SubscriptionReservation />
      },
      {
        path: '/subscription-checkout/:subscriptionUuid',
        element: <SubscriptionCheckout />
      },
      {
        path: '/subscription-payment-success/:subscriptionUuid',
        element: <SubscriptionPaymentSuccess />
      },
      {
        path: '/subscription-pass/:subscriptionUuid',
        element: <SubscriptionPass />
      },
      {
        path: '/claim/:eventId',
        element: <Claim />
      },
      {
        path: '/organization/:organizationId',
        element: <OrganizationDetails />
      },
      {
        path: '/reservation/:eventId',
        element: <Reservation />
      },
      {
        path: '/checkout/:eventId',
        element: <Checkout />
      },
      {
        path: '/payment-success/:eventId',
        element: <PaymentSuccess />
      },
      {
        path: '/ticket-details/:eventId',
        element: <TicketDetails />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/blockchain-settings',
        element: <BlockchainSettings />
      },
      {
        path: '/edit-profile',
        element: <EditProfile />
      },
      {
        path: '/pitch/:seasonId',
        element: <Pitch />,
        children: []
      },
      {
        path: '/rewards',
        element: <RewardsPage />,
        children: []
      },
      {
        path: '/scan/ticket/:eventId',
        element: <Scan scanType="TICKET" />,
        children: []
      },
      {
        path: '/scan/subscription/:eventId',
        element: <Scan scanType="SUBSCRIPTION" />,
        children: []
      },
      //TODO add 404 page for all routes that are not defined
      {
        path: '*',
        element: <Home />
      }
    ]
  }
]);

const NiceRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootRouteComponent />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-with-phone',
        element: <PhoneLogin />
      },
      {
        path: '/complete-profile',
        element: <CompleteProfile />
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '',
            element: <NiceHome />
          },
          {
            path: 'home',
            element: <NiceHome />
          },
          {
            path: '/marketplace',
            element: <Marketplace />
          },
          {
            path: '/my-tickets',
            element: <MyTickets />
          },
          {
            path: '/messages',
            element: <Notifications />
          },
          {
            path: '/profile',
            element: <Profile />,
            children: []
          }
        ]
      },
      {
        path: '/event/:eventId',
        element: <EventDetails />
      },
      {
        path: '/claim/:eventId',
        element: <Claim />
      },
      {
        path: '/organization/:organizationId',
        element: <OrganizationDetails />
      },
      {
        path: '/reservation/:eventId',
        element: <Reservation />
      },
      {
        path: '/checkout/:eventId',
        element: <Checkout />
      },
      {
        path: '/payment-success/:eventId',
        element: <PaymentSuccess />
      },
      {
        path: '/ticket-details/:eventId',
        element: <TicketDetails />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/blockchain-settings',
        element: <BlockchainSettings />
      },
      {
        path: '/edit-profile',
        element: <EditProfile />
      },
      {
        path: '/pitch',
        element: <Pitch />,
        children: []
      },
      {
        path: '/rewards',
        element: <RewardsPage />,
        children: []
      }
    ]
  }
]);
const LesDeterminesRouter = createBrowserRouter([
  {
    path: '/',
    element: <RootRouteComponent />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/login-with-phone',
        element: <PhoneLogin />
      },
      {
        path: '/complete-profile',
        element: <CompleteProfile />
      },

      {
        path: '/',
        element: <RootWithoutFooter />,
        children: [
          {
            path: '',
            element: <LesDeterminesHome />
          },
          {
            path: 'home',
            element: <LesDeterminesHome />
          }
        ]
      },
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '/marketplace',
            element: <Marketplace />
          },
          {
            path: '/my-tickets',
            element: <MyTickets />
          },
          {
            path: '/messages',
            element: <Notifications />
          },
          {
            path: '/profile',
            element: <Profile />,
            children: []
          }
        ]
      },
      {
        path: '/event/:eventId',
        element: <EventDetails />
      },
      {
        path: '/claim/:eventId',
        element: <Claim />
      },
      {
        path: '/organization/:organizationId',
        element: <OrganizationDetails />
      },
      {
        path: '/reservation/:eventId',
        element: <Reservation />
      },
      {
        path: '/checkout/:eventId',
        element: <Checkout />
      },
      {
        path: '/payment-success/:eventId',
        element: <PaymentSuccess />
      },
      {
        path: '/ticket-details/:eventId',
        element: <TicketDetails />
      },
      {
        path: '/settings',
        element: <Settings />
      },
      {
        path: '/blockchain-settings',
        element: <BlockchainSettings />
      },
      {
        path: '/edit-profile',
        element: <EditProfile />
      },
      {
        path: '/pitch',
        element: <Pitch />,
        children: []
      },
      {
        path: '/rewards',
        element: <RewardsPage />,
        children: []
      },
      {
        path: '/scan/ticket/:eventId',
        element: <Scan scanType="TICKET" />,
        children: []
      }
    ]
  }
]);

const getRouter = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return LesDeterminesRouter;
    case '127.0.0.1':
      return NiceRouter;
    case 'tickie-app-git-scalable-white-label-nisa10880.vercel.app':
      return LesDeterminesRouter;
    case 'collectibles.lesdetermines.tickie.io':
      return LesDeterminesRouter;
    default:
      return router;
  }
};

function RootRouteComponent() {
  return (
    <div>
      {import.meta.env.VITE_NODE_ENV != 'development' ? null : (
        <CustomBadge>
          <Typography fontWeight={600}>dev</Typography>
        </CustomBadge>
      )}

      <ScrollRestoration />
      <Outlet />
    </div>
  );
}

function AppRouter() {
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { address, wallet } = useWeb3Auth();
  const dispatch = useAppDispatch();
  const [setPublicKey] = userApi.endpoints.setPublicKey.useMutation();
  const { data: user } = userApi.endpoints.getUser.useQuery();

  const initUserPublicKey = async () => {
    try {
      const { currentUser } = auth;
      if (currentUser) {
        const idToken = await getIdToken(currentUser, true);
        await setPublicKey({ idToken, publicKey: address });
        dispatch(userApi.util.invalidateTags(['user-details']));
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (isAuth === true && wallet && user) {
      if (user.publicKey == '' || user.publicKey == null || user.publicKey == undefined) {
        auth.onAuthStateChanged(authUser => {
          if (authUser) {
            initUserPublicKey();
          } else {
            return;
          }
        });
      }
    }
  }, [user, isAuth, wallet]);

  return <RouterProvider router={getRouter()} />;
}

export default AppRouter;
