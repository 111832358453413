import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { useWeb3Auth } from '../../../../services/web3/web3auth';
import greyScale from '../../../../themes/default/colors/greyscale';
import { Ticket } from '../../../../services/api/ticket';
import { Collectible } from '../../../../services/api/event';

interface CollectibleCardProps {
  collectible: Collectible;
}

const CollectibleCard = (props: CollectibleCardProps) => {
  const { collectible } = props;
  return (
    <Card sx={{ maxWidth: 370 }}>
      <Box p={2} pb={0}>
        <img src={collectible.url ?? ''} width="100%" style={{ borderRadius: 8, objectFit: 'contain' }} />
      </Box>
      <CardContent>
        <Stack direction="row" justifyContent="start" alignItems="end">
          <Typography variant="h6" textOverflow="ellipsis" noWrap>
            {props.collectible.rarity}
          </Typography>
          <Typography variant="xl" color={greyScale[50]} textAlign="center">
            &nbsp; {`#${props.collectible.nftId}`}
          </Typography>
        </Stack>{' '}
        {/* <Typography variant="sm" color="primary" fontWeight={600}>
          {`${format(new Date(props.collectible.event.beginAt), "eee dd MMMM - HH'h'mm")}`}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default CollectibleCard;
