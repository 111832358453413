import { Outlet } from 'react-router-dom';
import TickieBottomNavigation from './components/BottomNavigation';
import Footer from './components/Footer';
import CustomFooter from './components/CustomFooter ';

const Root = () => {
  return (
    <>
      <Outlet />
      <CustomFooter />
      <TickieBottomNavigation />
    </>
  );
};

export default Root;
