import { Typography, Box, Button } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import errorImage from '../../assets/images/errors/payment-error.png';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
const UnhandledError = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh - 400px)`}>
      <Stack direction="column" spacing={2}>
        <img alt="payment-error" src={errorImage} />
        <Button variant="ContainedSecondary" onClick={() => window.location.reload()} startIcon={<ReplayIcon />}>
          {t('unhandledErrors.reload')}
        </Button>
        <Button variant="ContainedSecondary" onClick={() => navigate('/home')} startIcon={<HomeRoundedIcon />}>
          {t('unhandledErrors.returnHome')}
        </Button>
      </Stack>
    </Box>
  );
};

export default UnhandledError;
