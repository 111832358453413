/* eslint-disable camelcase */

import baseApi from './base';

export interface Event {
  id: number;
  uuid: string;
  title: string;
  description: string | null;
  minPrice: number;
  maxPrice: number;
  beginAt: string;
  endAt: string;
  cover: string;
  isAvailable: boolean;
  capacity: number;
  tags: string[];
  status: string;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  web3Address: string;
  web3Id: number;
  web3txHash: string;
  provenanceHash: string | null;
  deployerAddress: string;
  factoryAddress: string;
  chain: 'POLYGON' | 'CHILIZ';
  settings: any | null;
  addressId: number | null;
  orgaId: number;
  nft: Nft[];
  type: string;
  //
  isFavorited: boolean | undefined;
  // lottery: Lottery[];
  // discounts: Discount[];
  // ticketCategories: TicketCategory[];
  organization: Organization;
  address: AddressDetails;
  collectibles: Collectible[];
  claim: Claim[];
}

/**
 * Model Collectible
 *
 */
export type Collectible = {
  id: number;
  uuid: string;
  nftId: number;
  txHash: string;
  rarity: string | null;
  reward: string | null;
  url: string | null;
  userId: number | null;
  nftUuid: string | null;
  eventId: number | null;
};

/**
 * Model Claim
 *
 */
export type Claim = {
  id: number;
  uuid: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  quantity: number | null;
  status: String;
  eventId: number;
};

export type Nft = {
  uuid: string;
  name: string;
  description: string | null;
  url: string | null;
  ipsf: string | null;
  rarity: number | null;
  reward: string | null;
  order: number;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  eventId: number | null;
  organizationId: number | null;
};

export interface Discount {
  name: string;
  value: string;
}

export interface AddressDetails {
  name: string;
  street: string;
  city: string;
  zipCode: number;
  country: string;
  lat: number;
  lng: number;
}

export interface Lottery {
  name: string;
  description: string;
  image: string;
  quantity: number;
  color: string;
}

export interface Organization {
  id: string;
  uuid: string;
  name: string;
  logo: string;
  type: string;
  isFavorite: boolean;
  eventCount: number;
  stripeAccountId: string;
}

export interface Rank {
  name: string;
  price: number;
  available: number;
  maxPerUser: number;
  categoryId: string;
}

export interface HomeEvent {
  id: number;
  uuid: string;
  title: string;
  description: string | null;
  subTitle?: string | null;
  beginAt: string;
  cover: string;
  type: string;
  isFavorited: boolean | undefined;
  address: Address | undefined | null;
}

export interface Address {
  name: string;
  city: string;
}

export interface Tag {
  id: string;
  name: string;
  icon: string;
  img: any;
}

const eventApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getEvents: builder.query<HomeEvent[], string>({
      query: tag => ({
        url: `/events`,
        method: 'GET',
        params: { tags: [tag] }
      }),
      providesTags: ['events']
    }),
    getEventDetails: builder.query<Event, string>({
      query: uuid => ({
        method: 'GET',
        url: `/events/${uuid}`
      })
    }),
    getEventClaim: builder.query<Event, { eventUuid: string; userUuid: string }>({
      query: ({ eventUuid, userUuid }) => ({
        method: 'GET',
        url: `/events/${eventUuid}/claim/${userUuid}`
      }),
      providesTags: ['claim-event']
    }),
    claimCollectible: builder.mutation<Collectible, { eventUuid: string }>({
      query: ({ eventUuid }) => ({
        method: 'POST',
        url: `/events/${eventUuid}/claim`
        // data: { email, quantity }
      })
    })
  })
});

export default eventApi;
