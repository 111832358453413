/* eslint-disable camelcase */

import baseApi from './base';
import { HomeEvent } from './event';

export interface OrganizationDetails {
  id: string;
  name: string;
  logo: string;
  type: string;
  isFavorite: boolean;
  eventCount: number;
  events: HomeEvent[];
}

const organizationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getOrganizationDetails: builder.query<OrganizationDetails, string>({
      query: uuid => ({
        method: 'GET',
        url: `/organizations/${uuid}`
      })
    })
  })
});

export default organizationApi;
