import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { Box, FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';
import userApi from '../../services/api/user';
import greyScale from '../../themes/default/colors/greyscale';
import SearchBar from '../SearchBar';
import DesktopDrawer from '../DesktopDrawer';
import { useAppSelector } from '../../redux/hooks';
import LogoButton from '../LogoButton';
import logoTextWhite from '../../assets/images/logo/logo-white-big.png';
import logoTextDark from '../../assets/images/logo/logo-dark-big.png';
import { getClubLogoFromHostname } from '../../pages/whiteLabel/themeFactory';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2)
}));

const TickieAppBar = () => {
  const { data: user, isLoading } = userApi.endpoints.getUser.useQuery();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const { isAuth } = useAppSelector(state => state.authSlice);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const blagnacLogo = getClubLogoFromHostname();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <StyledToolbar>
            <Box display="flex">
              {!user || isLoading ? (
                <Stack direction="row" mt={-1}>
                  {isAuth ? (
                    <LogoButton />
                  ) : (
                    <Box>
                      <img src={darkTheme ? blagnacLogo : blagnacLogo} height={56} />
                    </Box>
                  )}
                </Stack>
              ) : (
                <>
                  <Box mt={-1}>
                    <LogoButton />
                  </Box>
                  <Stack direction="column">
                    <Typography variant="lg" fontWeight={400} color={greyScale[500]}>
                      {t('home.hello')}
                    </Typography>
                    <Typography variant="h5"> {`${user?.firstName ?? ''}`}</Typography>
                  </Stack>
                </>
              )}
            </Box>
            {isDesktop && (
              <Box display="flex" alignItems="center" ml={10} width="50%">
                <SearchBar showFilterIcon isInAppBar />
              </Box>
            )}
            <Stack direction="row" spacing={2}>
              <DesktopDrawer />
            </Stack>
          </StyledToolbar>
          {!isDesktop && (
            <Box sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 } }}>
              <SearchBar showFilterIcon />
            </Box>
          )}
        </AppBar>
      </Box>
    </>
  );
};

export default TickieAppBar;
