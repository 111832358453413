import { useEffect, useState } from 'react';
import paymentApi from '../../services/api/payment';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import userApi from '../../services/api/user';
import { useAppSelector } from '../../redux/hooks';
import { Stripe } from '@stripe/stripe-js';
import BasketCard from './components/BasketCard';
import UserInfos from './components/UserInfos';
import Footer from '../../components/Footer';
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';
import UnhandledError from '../../components/UnhandledError';
import Login from '../Login';

import InvitForm from './components/Invite';
import promoCodeApi from '../../services/api/promo-code';
import subscriptionApi from '../../services/api/subscription';
import OrganizationEventsCard from '../../components/OrganizationEventsCard';
import OrganizationSubscriptionCard from '../../components/OrganizationSubscriptionCard';

export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  width: { xs: '80%', sm: '50%', lg: '40%', xl: '30%' }
};

const SubscriptionCheckout = () => {
  const { subscriptionUuid } = useParams();
  const navigate = useNavigate();
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const { isAuth } = useAppSelector(state => state.authSlice);
  const { subscriptionsBasket } = useAppSelector(app => app.shoppingCartSlice);
  const { data: user, isLoading: userLoading } = userApi.endpoints.getUser.useQuery();
  const {
    data: subscription,
    isLoading,
    isError
  } = subscriptionApi.endpoints.getSubscriptionDetails.useQuery(subscriptionUuid ?? '');
  const [createPaymentIntent, { data: paymentIntent }] = paymentApi.endpoints.createPaymentIntent.useMutation();

  const [canUsePromoCode, { data: promoCodeResult }] = promoCodeApi.endpoints.canUsePromoCode.useLazyQuery();
  let [urlParams, setUrlParams] = useSearchParams();
  const invit = urlParams.get('invit');
  const subscriptionInvite = urlParams.get('subscriptionInvite');
  const [showPromo, setShowPromo] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const { darkTheme } = useAppSelector(state => state.themeSlice);

  useEffect(() => {
    if (subscriptionsBasket.length == 0) {
      navigate(
        `/subscription-reservation/${subscriptionUuid}${invit ? `?invit=${invit}` : ''}${
          subscriptionInvite ? `?subscriptionInvite=${subscriptionInvite}` : ''
        }`
      );
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: '',
      eventUuid: subscriptionUuid,
      ticketCategoriesUuids: subscriptionsBasket.map(cat => cat.tarifUuid)
    },
    onSubmit: async values => {
      try {
        await canUsePromoCode({
          ...values,
          eventUuid: subscriptionUuid!,
          ticketCategoriesUuids: values.ticketCategoriesUuids.map(a => `${a}`).join(',') as any
        }).unwrap();
        console.log('can use promo code');
        setShowPromo(true);
        setShowPromoModal(false);
      } catch (error) {
        formik.setErrors({ code: t('reservation.promoCodeError') ?? '' });
      }
    }
  });

  // useEffect(() => {
  //   const fetchStripeObject = async () => {
  //     // If there is no accountId, do not run the loadStripe function.
  //     if (subscription?.organization?.stripeAccountId) {
  //       const res = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
  //         stripeAccount: subscription?.organization?.stripeAccountId
  //       });
  //       // When we have got the Stripe object, pass it into our useState.
  //       setStripeObject(res);
  //     }
  //   };
  //   fetchStripeObject();
  // }, [subscription]);

  // useEffect(() => {
  //   if (user && subscription) {
  //     const amount = subscriptionsBasket.reduce((a, b) => a + b.price * b.quantity, 0);
  //     createPaymentIntent({
  //       amount,
  //       currency: 'eur',
  //       metadata: {
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         email: user.email,
  //         eventUuid: subscriptionUuid as string,
  //         places: subscriptionsBasket.map(cat => {
  //           return { categoryUuid: cat.tarifUuid, quantity: cat.quantity };
  //         }),
  //         codePromoUuid: undefined,
  //         finalPrice: amount,
  //         srcPrice: amount,
  //         status: 'COMPLETE',
  //         userUuid: user.uuid,
  //         purchaseType: PurchaseType.Subscription,
  //         subscription: { subscriptionUuid: subscription.uuid }
  //       }
  //     }).unwrap();
  //   }
  // }, [user, subscription]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !subscription) {
    return <UnhandledError />;
  }

  function appBar() {
    return (
      <Stack direction="row" alignItems="center" mt={2} mb={3} spacing={1}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{t('checkout.recap')}</Typography>
      </Stack>
    );
  }

  const checkoutForm = () => {
    if (!isAuth && isDesktop) {
      return null;
    }

    return <InvitForm subscriptionUuid={subscriptionUuid ?? ''} />;

    // if (paymentIntent && paymentIntent?.client_secret) {
    //   return (
    //     <Elements
    //       options={{
    //         clientSecret: paymentIntent?.client_secret,
    //         appearance: { theme: darkTheme ? 'night' : 'flat', labels: 'floating' }
    //       }}
    //       stripe={stripeObject}>
    //       <CheckoutForm paymentIntent={paymentIntent} eventId={subscriptionUuid ?? ''} />
    //     </Elements>
    //   );
    // } else {
    //   null;
    // }
  };

  return (
    <Box>
      <Modal open={showPromoModal} onClose={() => setShowPromoModal(false)}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3}>
              <Typography variant="h5" textAlign="center">
                {t('reservation.promoCode')}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
                <TextField
                  name="code"
                  variant="standard"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  placeholder={t('reservation.code') ?? ''}
                  fullWidth
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Stack>
              <Button type="submit">Valider</Button>
            </Stack>
          </Box>
        </form>
      </Modal>
      <Box minHeight="calc(100vh - 180px)">
        <header>{appBar()}</header>
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            email: user?.email ?? ''
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required(t('formErrors.firstNameEmpty') ?? ''),
            lastName: Yup.string().required(t('formErrors.lastNameEmpty') ?? ''),
            email: Yup.string()
              .email(t('formErrors.mailNotValid') ?? '')
              .required(t('formErrors.mailNotValid') ?? '')
          })}
          onSubmit={async values => {
            try {
            } catch (error) {}
          }}
        >
          {userLoading ? (
            <Stack direction="row" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
              <CircularProgress></CircularProgress>
            </Stack>
          ) : (
            <Box m={2}>
              {isDesktop ? (
                <>
                  <Box display="flex" justifyContent="center">
                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                      <Stack direction="column">
                        {isAuth && <UserInfos user={user} userLoading={userLoading} />}
                        {/* {showPromo ? null : (
                          <Box mt={2}>
                            <Button fullWidth onClick={() => setShowPromoModal(true)} sx={{ height: 50 }}>
                              {t('reservation.IhavePromoCode')}
                            </Button>
                          </Box>
                        )} */}
                        {checkoutForm()}
                      </Stack>
                      <Stack direction="column">
                        <Box width="100%" sx={{ position: { md: 'sticky' }, top: { md: 0 } }}>
                          {subscription && <BasketCard subscription={subscription} />}
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                  {isAuth ? null : (
                    <Card sx={{ mb: 3, mt: 8 }}>
                      <Login
                        isReservation
                        redirectLink={`${
                          import.meta.env.VITE_APP_BASE_URL
                        }/subscription-reservation/${subscriptionsBasket}`}
                      />
                    </Card>
                  )}
                </>
              ) : (
                <>
                  {subscription && <OrganizationSubscriptionCard subscription={subscription} />}
                  {isAuth ? <UserInfos user={user} userLoading={userLoading} /> : null}
                  {/* {showPromo ? null : (
                    <Box mt={2}>
                      <Button fullWidth onClick={() => setShowPromoModal(true)} sx={{ height: 50 }}>
                        {t('reservation.IhavePromoCode')}
                      </Button>
                    </Box>
                  )} */}
                  {subscription && <BasketCard subscription={subscription} />}
                  {isAuth ? (
                    checkoutForm()
                  ) : (
                    <Card variant="contained" sx={{ mb: 3 }}>
                      <Login
                        isReservation
                        redirectLink={`${
                          import.meta.env.VITE_APP_BASE_URL
                        }/subscription-reservation/${subscriptionUuid}`}
                      />
                    </Card>
                  )}
                </>
              )}
            </Box>
          )}
        </Formik>
      </Box>
      <Footer />
    </Box>
  );
};

export default SubscriptionCheckout;
