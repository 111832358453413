import { Button, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from '../../../../config/chainConfig';
import { useWeb3Auth } from '../../../../services/web3/web3auth';

function BlockchainSettings() {
  // const { chain, getUserInfo, user, provider, getPrivateKey } = useWeb3Auth();
  const [privateKey, setPrivateKey] = useState('');

  const navigate = useNavigate();
  return (
    <>
      {/* <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{t('settings.settings')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="xl" fontWeight={600}>
          chain :{' '}
        </Typography>
        <Typography variant="h6">{chain}</Typography>
      </Stack>
      <Typography variant="h5">Chain config</Typography>
      {Object.entries(CHAIN_CONFIG[chain as CHAIN_CONFIG_TYPE]).map(([key, value]) => (
        <Stack direction="row" spacing={1} alignItems="center" key={key}>
          <Typography variant="xl" fontWeight={600}>
            {`${key} :`}
          </Typography>
          <Typography>{`  ${value}`}</Typography>
        </Stack>
      ))}

      <Typography variant="h5">Web3auth</Typography>
      {user != null &&
        Object.entries(user).map(([key, value]) => (
          <Stack direction="row" spacing={1} alignItems="center" key={key}>
            <Typography variant="xl" fontWeight={600}>
              {`${key} :`}
            </Typography>
            <Typography>{`  ${value}`}</Typography>
          </Stack>
        ))}

      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setPrivateKey((await getPrivateKey()) ?? '');
        }}
      >
        {t('settings.getPrivateKey')}
      </Button>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="xl" fontWeight={600}>
          {privateKey}
        </Typography>
      </Stack> */}
    </>
  );
}

export default BlockchainSettings;
