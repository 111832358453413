import { Modal, Button, Box, Typography, LinearProgress, CircularProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import QRCodeStyling, { gradientTypes } from 'qr-code-styling';
import ticketApi, { Ticket, Event } from '../../../services/api/ticket';
import tickieLogo from '../../../assets/images/logo/tickie.png';
import { TicketSignatureContent, useWeb3Auth } from '../../../services/web3/web3auth';
import { useEffect, useRef, useState } from 'react';
import userApi from '../../../services/api/user';
import paymentErrorImage from '../../../assets/images/errors/payment-error.png';
import paymentSuccessImage from '../../../assets/images/success/payment-success.png';
import palette from '../../../themes/default/colors/palette';
import greyScale from '../../../themes/default/colors/greyscale';
import { Subscription, UserSubscription } from '../../../services/api/subscription';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: palette.dark2,
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  width: { xs: '80%', sm: '50%', lg: '40%', xl: '30%' }
};

interface QrCodeModalProps {
  openQrCoderModal: boolean;
  handleCloseQrCodeModal: () => void;
  userSubsctiptions: UserSubscription[];
  userSubscription: UserSubscription;
  subscription: Subscription;
  // qrCode: string;
  // seatInfos: Seat[];
}

const QrCodeModal = (props: QrCodeModalProps) => {
  // params for qr code
  // duration of validity in seconds
  // const rotatingQrDuration = 1;
  // const expirationDate = new Date().getTime() + rotatingQrDuration * 1000;

  const [qr, setQR] = useState<QRCodeStyling>();
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string>();
  const { signTicket } = useWeb3Auth();

  const [addToWallet, { isLoading: isAddingToWallet }] = ticketApi.endpoints.addToWallet.useMutation();
  // const [seconds, setSeconds] = useState(rotatingQrDuration);
  // const [percentage, setPercentage] = useState(100);

  // useEffect(() => {
  //   if (seconds > 0) {
  //     setTimeout(() => {
  //       setSeconds(seconds - 1);
  //       setPercentage((100 * seconds - 1) / rotatingQrDuration);
  //     }, 1000);
  //   }
  // }, [seconds]);

  // useEffect(() => {
  //   if (seconds == 0) {
  //     setPercentage(100);
  //     setSeconds(rotatingQrDuration);
  //     updateQrCode();
  //   }
  // }, [seconds]);

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    sign();
  }, []);

  const nftIds = props.userSubsctiptions.map(ticket => ticket.nftId);
  const ticketIds = props.userSubsctiptions.map(ticket => ticket.id);

  function moveNumberToFirstPosition(list: number[], numberToMove: number): number[] {
    // Find the index of the number to move
    const index = list.indexOf(numberToMove);
    // If the number is already in the first position, we don't need to do anything
    if (index === 0) {
      return list;
    } else if (index > 0) {
      // If the number is in the list but not in the first position
      // Remove the number from its current position
      list.splice(index, 1);
      // Insert the number at the beginning of the list
      list.unshift(numberToMove);
      return list;
    } else {
      // If the number is not in the list
      throw new Error(`The number ${numberToMove} is not in the list.`);
    }
  }

  // const updateQrCode = async () => {
  //   const content: TicketSignatureContent = {
  //     nftIds: moveNumberToFirstPosition(nftIds, props.ticket.nftId),
  //     userAddress: user?.publicKey ?? '',
  //     ticketIds: moveNumberToFirstPosition(ticketIds, props.ticket.id)
  //     // expirationDate
  //   };
  //   const signature = await signTicket(content, props.event.web3Address, props.event.chain);

  //   const data = JSON.stringify({
  //     content: JSON.stringify(content),
  //     signature
  //   });
  //   qr?.update({ data });
  // };

  const sign = async () => {
    setErrorMessage(undefined);
    if (!props.subscription.web3Address) return;
    try {
      if (props.userSubscription.isScanned) {
        setInfoMessage(t('myTickets.isAlreadyScanned') ?? '');
      }

      const content: TicketSignatureContent = {
        nftIds: moveNumberToFirstPosition(nftIds, props.userSubscription.nftId),
        userAddress: user?.publicKey ?? '',
        ticketIds: moveNumberToFirstPosition(ticketIds, props.userSubscription.id)
      };
      const signature = await signTicket(content, props.subscription.web3Address, props.subscription.chain);
      const data = JSON.stringify({
        content: JSON.stringify(content),
        signature
      });

      const qrCode = new QRCodeStyling({
        width: 260,
        height: 260,
        type: 'svg',
        data,
        image: tickieLogo,
        dotsOptions: {
          color: 'black',
          type: 'classy'
        },
        backgroundOptions: {
          color: '#ffffff',
          round: 0.1
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20,
          imageSize: 0.5
        }
      });

      qrCode.append(containerRef.current);
      setQR(qrCode);
    } catch (error) {
      setErrorMessage(error as string);
      console.log(error);
      return;
    }
  };

  return (
    <Modal open={props.openQrCoderModal} onClose={props.handleCloseQrCodeModal}>
      <Box sx={style}>
        <div className="left"></div>
        <div className="right"></div>
        <Stack direction="column" justifyContent="center" spacing={3}>
          {errorMessage ? (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
              <Box display="flex" justifyContent="center" mt={2}>
                <img alt="payment-error" src={paymentErrorImage} />
              </Box>
              <Typography variant="md" fontWeight={600} color="red">
                {errorMessage}
              </Typography>
            </Stack>
          ) : infoMessage ? (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
              <Box display="flex" justifyContent="center" mt={2}>
                <img alt="payment-success" src={paymentSuccessImage} />
              </Box>
              <Typography variant="md" fontWeight={600}>
                {infoMessage}
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack direction="row" justifyContent="center">
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  {/* <CircularProgress variant="determinate" value={percentage} size={64} /> */}
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="xl">#{props.userSubscription.nftId}</Typography>
                  </Box>
                </Box>
              </Stack>
              <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
                {/* <Typography variant="h5">
                  {props.userSubscription.firstName}&nbsp;
                  {props.userSubscription.lastName}
                </Typography> */}
                <Stack direction="row" justifyContent="space-evenly" width="100%" pt={2}>
                  <Typography variant="lg">Admission régulière</Typography>
                  <Typography variant="lg">{props.subscription.tarifs[0]?.name}</Typography>
                </Stack>
                <Typography variant="h5" color={greyScale[500]} pt={2}>
                  Placement Libre
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Box height={48} width={48} bgcolor="transparent" borderRadius={100} ml={-6} />

                <div
                  style={{
                    border: 'none',
                    borderTop: '8px dotted black',

                    width: '100%'
                  }}
                />
                <Box height={48} width={48} bgcolor="transparent" borderRadius={100} mr={-6} />
              </Stack>

              <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
                <div ref={containerRef as any}></div>
              </Stack>
              {/* <Stack direction="row" justifyContent="center">
                <Box width="100%">
                  <img src="/google-wallet.png" width="90%" />
                </Box>
                <Box width="100%">
                  {isAddingToWallet ? (
                    <CircularProgress />
                  ) : (
                    <img
                      onClick={() => addToWallet({ ticketUuid: props.ticket.uuid })}
                      width="90%"
                      src="/apple-wallet.png"
                    />
                  )}
                </Box>
              </Stack> */}
            </>
          )}
          <Stack direction="column" spacing={1}>
            <Button variant="ContainedSecondary" onClick={props.handleCloseQrCodeModal}>
              {t('myTickets.goBack')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default QrCodeModal;
