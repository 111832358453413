import { Event } from '../../../../services/api/event';
import { atcb_action } from 'add-to-calendar-button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@mui/material';
import { t } from 'i18next';
import { Add } from '@mui/icons-material';

interface AddToCalendarButtonProps {
  title: string;
  beginAt: string;
  endAt: string;
}

const AddToCalendarButton = (props: AddToCalendarButtonProps) => {
  return (
    <Button
      variant="text"
      size="small"
      startIcon={<Add />}
      onClick={e => {
        e.preventDefault();
        atcb_action({
          name: props.title,
          startDate: new Date(props.beginAt).toISOString(),
          endDate: new Date(props.endAt).toISOString(),
          options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
          timeZone: 'Europe/Berlin',
          iCalFileName: props.title
        });
      }}
    >
      {t('event.addToCalendar')}
    </Button>
  );
};

export default AddToCalendarButton;
