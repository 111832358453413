import React from 'react';
import { Box, Typography, useMediaQuery, Theme } from '@mui/material';
import '/fonts/SpaceGrotesk-VariableFont_wght.ttf';
import cardBg1 from '/createImage.png';
import VerticalCard from '../UI/VerticalCard';
import HorizontalCard from '../UI/HorizantaleCard';

interface Steps {
  title: string;
  text1: string;
  text2: string;
  image: string;
  buttonText: string;
  link: string;
}

interface MainProps {
  steps: Steps[];
  onLogin: any;
  onClickMintButton: any;
}

const Main: React.FC<MainProps> = ({ steps, onLogin, onClickMintButton }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box
      className="custom-font-text"
      width={'100%'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        px: isDesktop ? '100px' : '10px'
      }}
    >
      <Typography
        className="custom-font-text"
        sx={{
          textAlign: !isDesktop ? 'center' : 'left',
          fontWeight: 'bold',
          fontSize: !isDesktop ? '35px' : '45px',
          color: '#fff',
          textTransform: 'uppercase',
          fontFamily: 'Space Grotesk',
          fontStyle: 'normal',
          marginTop: '120px'
        }}
      >
        Comment ça marche ?
      </Typography>
      <br />
      <br />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: !isDesktop ? 'column' : 'row',
            justifyContent: 'center',
            width: '100%',
            alignItems: 'stretch' // Add this line to make both cards the same height
          }}
        >
          <VerticalCard
            title={steps[0].title}
            text1={steps[0].text1}
            text2={steps[0].text2}
            image={steps[0].image}
            link={steps[0].link}
            buttonText={steps[0].buttonText}
            index={1}
            onClick={() => console.log('1')}
          />
          <VerticalCard
            title={steps[1].title}
            text1={steps[1].text1}
            text2={steps[1].text2}
            image={steps[1].image}
            buttonText={steps[1].buttonText}
            index={2}
            link={steps[1].link}
            onClick={onLogin}
          />
        </Box>

        {isDesktop ? (
          <HorizontalCard
            title={steps[2].title}
            text1={steps[2].text1}
            text2={steps[2].text2}
            image={steps[2].image}
            index={3}
            buttonText={steps[2].buttonText}
            link={steps[2].link}
          />
        ) : (
          <VerticalCard
            title={steps[2].title}
            text1={steps[2].text1}
            text2={steps[2].text2}
            image={steps[2].image}
            link={steps[2].link}
            index={3}
            buttonText={steps[2].buttonText}
            onClick={onClickMintButton}
          />
        )}
      </Box>
      <br />
      <br />
      <br />
    </Box>
  );
};

export default Main;
