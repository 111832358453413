const broderRadius = {
  notRounded: 0,

  roundedXs: 5,

  roundedS: 10,

  roundedM: 15,

  roundedL: 20,

  RoundedXl: 25
};

export default broderRadius;
