import { Web3Auth } from '@web3auth/single-factor-auth';
import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { CHAIN_CONFIG, CHAIN_CONFIG_TYPE } from '../../config/chainConfig';
import { WEB3AUTH_NETWORK_TYPE } from '../../config/web3AuthNetwork';
// import { getWalletProvider, IWalletProvider } from './walletProvider';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ethers } from 'ethers';
import eventABI from '../../abi/event.json';
import { decryptPrivateKey, encryptPrivateKey } from './crypto';
import userApi from '../api/user';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { TickieNFT__factory } from '../../types';
import rpcUrl from '../../utils/rpcUrl';

export interface TicketSignatureContent {
  nftIds: number[];
  ticketIds: number[];
  // expirationDate: number;
  userAddress: string;
}

export interface IWeb3AuthContext {
  address: string;
  balance: string;
  login: (idToken: string, secret: string) => Promise<void>;
  wallet: ethers.Wallet | undefined;
  logout: () => Promise<void>;
  signTicket: (
    arg0: TicketSignatureContent,
    eventAddress: string,
    eventChain: 'POLYGON' | 'CHILIZ'
  ) => Promise<string | undefined>;
  isTicketValid: (result: string, chain: 'POLYGON' | 'CHILIZ') => Promise<any>;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  address: '',
  balance: '',
  login: async (idToken: string, secret: string) => {},
  wallet: undefined,
  logout: async () => {},
  signTicket: async () => '',
  isTicketValid: async () => {}
});

export function useWeb3Auth(): IWeb3AuthContext {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthState {
  web3AuthNetwork: string;
  chain: CHAIN_CONFIG_TYPE;
  children?: React.ReactNode;
}
interface IWeb3AuthProps {
  children?: ReactNode;
  web3AuthNetwork: string;
  chain: CHAIN_CONFIG_TYPE;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({
  children,
  web3AuthNetwork,
  chain
}: IWeb3AuthProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [wallet, setWallet] = useState<ethers.Wallet>();
  const [provider, setProvider] = useState<ethers.providers.JsonRpcProvider>();
  const [address, setAddress] = useState<string>('');
  const [balance, setBalance] = useState<string>('');
  const [authSuccess, setAuthSuccess] = useState<boolean>(false);

  const { isAuth } = useAppSelector(state => state.authSlice);

  useEffect(() => {
    const initialize = async () => {
      if (isAuth) {
        try {
          const currentChainConfig = CHAIN_CONFIG[chain];
          // @ts-ignore
          const result = await dispatch(userApi.endpoints.getUser.initiate()).unwrap();
          const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
          const privateKey = decryptPrivateKey(encryptedPrivateKey, result.secret);
          const provider = new ethers.providers.JsonRpcProvider(currentChainConfig.rpcTarget);
          const signer = new ethers.Wallet(privateKey, provider);
          setWallet(signer);
          setProvider(provider);
          setAddress(await signer.getAddress());
          return;
          // TODO find out why this is not working
          // setBalance((await provider?.getBalance(address))?.toString() ?? '');
        } catch (error) {
          console.log('error', error);
        }
      }
    };

    initialize();
  }, [authSuccess, isAuth]);

  const parseToken = (token: any) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64 || ''));
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const logout = async () => {
    setAddress('');
    setBalance('');
    setWallet(undefined);
    setProvider(undefined);
  };

  const login = async (idToken: string, secret: string) => {
    try {
      const currentChainConfig = CHAIN_CONFIG[chain];
      const clientId = import.meta.env.VITE_WEB3AUTH_CLIENT_ID;
      setIsLoading(true);
      const web3authSfa = new Web3Auth({
        clientId,
        web3AuthNetwork: web3AuthNetwork as any,
        usePnPKey: true
      });

      const ethereumPrivateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig: currentChainConfig
        }
      });

      web3authSfa.init(ethereumPrivateKeyProvider);
      setIsLoading(true);
      // get sub value from firebase id token
      const { sub } = parseToken(idToken);
      const web3authSfaprovider = await web3authSfa.connect({
        verifier: import.meta.env.VITE_WEB3AUTH_VERIFIER,
        verifierId: sub,
        idToken
      });
      if (web3authSfaprovider) {
        const privateKey = (await web3authSfaprovider.request({
          method: 'eth_private_key'
        })) as string;

        const encryptedPrivateKey = encryptPrivateKey(privateKey, secret);
        localStorage.setItem('encryptedPrivateKey', encryptedPrivateKey);
      }
      setAuthSuccess(true);
      return;
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const signTicket = async (
    signatureContent: TicketSignatureContent,
    eventAddress: string,
    eventChain: 'POLYGON' | 'CHILIZ'
    // TODO make this cross chain
  ): Promise<string | undefined> => {
    const { nftIds } = signatureContent;
    console.log('nftIds', nftIds);
    console.log('eventAddress', eventAddress);
    console.log('chain', chain);
    try {
      const contract = new ethers.Contract(eventAddress, eventABI.abi, wallet);
      for (let i = 0; i < nftIds.length; i++) {
        if (typeof nftIds[i] == 'number') {
          {
            const nftId = nftIds[i];
            const provider = new ethers.providers.JsonRpcProvider(rpcUrl(eventChain));
            const tickieReadNft = TickieNFT__factory.connect(eventAddress, provider);
            const owner = await tickieReadNft.ownerOf(nftId);
            if (owner.toString().toLowerCase() != wallet?.address.toString().toLowerCase()) {
              throw new Error(t('walletErrors.notOwnerOfThisTicket') ?? '');
            }
          }
        }
      }
      const signature = await wallet?.signMessage(JSON.stringify(signatureContent));
      return signature;
    } catch (error) {
      console.log('error', error);
      throw new Error(error?.toString() ?? '');
    }
  };

  const isTicketValid = async (result: any, eventChain: 'POLYGON' | 'CHILIZ'): Promise<any> => {
    console.log('result', result);
    console.log('chain', chain);
    try {
      const data = JSON.parse(result);
      const signer = ethers.utils.verifyMessage(data.content, data.signature);

      if (signer?.toLocaleLowerCase() != data?.address?.toLowerCase()) {
        throw new Error('Invalid Ticket wrong signature');
      }
      const content = JSON.parse(data.content);

      const provider = new ethers.providers.JsonRpcProvider(rpcUrl(eventChain));
      const tickieReadNft = TickieNFT__factory.connect(content.address, provider);
      const owner = await tickieReadNft.ownerOf(content.id);

      if (owner != signer) {
        throw new Error('Invalid Ticket invalid owner');
      }

      return '';
    } catch (error) {
      console.log('error', error);
      uiConsole('error', error);
      throw error;
    }
  };

  const uiConsole = (...args: unknown[]): void => {
    const el = document.querySelector('#console>p');
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
  };

  const contextProvider = {
    chain,
    isLoading,
    setIsLoading,
    login,
    balance,
    address,
    wallet,
    signTicket,
    isTicketValid,
    logout
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
