import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Stack } from '@mui/material';
import greyScale from '../../../../themes/default/colors/greyscale';

function NextSlideButton(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      right={8}
      position="absolute"
      sx={{ zIndex: 300, transform: 'translateY(-50%)' }}
      className="mr-12 sm:top-[50%] top-[95%] "
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateNextIcon sx={{ color: '#000' }}></NavigateNextIcon>
      </IconButton>
    </Stack>
  );
}
export default NextSlideButton;
