/* eslint-disable camelcase */

import { PaymentIntent } from '@stripe/stripe-js';
import { HomeEvent } from './event';
import { TicketReservation } from './ticket';
import baseApi from './base';

export interface CreatePaymentIntentFrom {
  currency: string;
  amount: number;
  metadata: StripeReservationMetadata;
}

export enum PurchaseType {
  Ticket = 'ticket',
  Subscription = 'subscription'
}
export interface StripeReservationMetadata {
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  srcPrice: number;
  finalPrice: number;
  places: PlacesReservationDto[];
  userUuid: string;
  eventUuid: string;
  codePromoUuid: string | undefined;
  purchaseType: PurchaseType;
  subscription: { subscriptionUuid: string } | undefined;
}

export interface PlacesReservationDto {
  quantity: number;
  categoryUuid: string;
}

export interface CheckoutBasketTicket {
  id: string;
  rankName: string;
  rankId: string;
  categoryId: string;
  categoryName: string;
  numberOfTickets: number;
  anount: number;
  fees: number;
}

export interface CheckoutBasket {
  tickets: CheckoutBasketTicket[];
  event: HomeEvent;
  totalAmount: number;
  totalFees: number;
}

interface UpdatePaymentIntentForm {
  payementIntentId: string;
  userMetadata: userMetadata;
}

interface userMetadata {
  firstName: string;
  lastName: string;
  email: string;
  eventUuid: string;
}

const paymentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    createPaymentIntent: builder.mutation<PaymentIntent, CreatePaymentIntentFrom>({
      query: body => ({
        url: `/payment/stripe/payment-intent`,
        method: 'POST',
        body
      })
    }),
    updatePaymentIntent: builder.mutation<PaymentIntent, UpdatePaymentIntentForm>({
      query: body => ({
        url: `/payment/stripe/payment-intent/${body.payementIntentId}`,
        method: 'PATCH',
        body: body.userMetadata
      })
    }),
    createInvitReservation: builder.mutation<TicketReservation, StripeReservationMetadata>({
      query: body => ({
        url: `/reservations/invite`,
        method: 'POST',
        body
      })
    })
  })
});

export default paymentApi;
