import { Typography, Card, Stack, Divider, Box, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import greyScale from '../../../../themes/default/colors/greyscale';
import { Subscription } from '../../../../services/api/subscription';

interface BasketCardProps {
  subscription: Subscription;
}

const BasketCard = (props: BasketCardProps) => {
  const { subscriptionsBasket } = useAppSelector(app => app.shoppingCartSlice);
  const { subscription } = props;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }}>
      {isDesktop ? (
        <>
          <Stack direction="row" spacing={1}>
            <Box pb={0} height={100}>
              <img
                width="100%"
                height={100}
                src={subscription?.cover!}
                alt="event cover"
                style={{ borderRadius: 20, objectFit: 'contain' }}
              />
            </Box>
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="h4">{subscription.name}</Typography>
                {/* <Typography variant="xl" color="primary" fontWeight={600}>
                  {`${capitalize(format(new Date(subscription?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
                </Typography> */}
                {/* <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="end">
                    <LocationOnIcon sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography variant="xl" color={greyScale[700]}>
                      {event?.address?.name} {`, `} {event?.address?.city}
                    </Typography>
                  </Stack>
                </Stack> */}
              </Stack>
            </Box>
          </Stack>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </>
      ) : null}
      {subscriptionsBasket?.map(tarif => (
        <div key={tarif.tarifUuid}>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant="md" color={greyScale[500]}>{`${tarif.quantity} ${t('checkout.subscription', {
              count: tarif.quantity
            })}  ${tarif.tarifName == null ? ` ` : `${tarif.tarifName}`}
            `}</Typography>
            <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(tarif.price * tarif.quantity)}`}</Typography>
          </Stack>
        </div>
      ))}{' '}
      {/* TODO add fees */}
      {/* <Stack direction="row" justifyContent="space-between" mb={1}>
        <Typography variant="md" color={greyScale[700]}>{`${t('checkout.fees')}`}</Typography>
        <Typography variant="lg" color={greyScale[800]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(selectedTickets.reduce((a, b) => a + b.price * b.quantity * 0.1, 0))}`}</Typography>
      </Stack> */}
      <Divider />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="md" color={greyScale[500]}>
          {t('checkout.total')}
        </Typography>
        <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(subscriptionsBasket.reduce((a, b) => a + b.price * b.quantity, 0))}`}</Typography>
      </Stack>
    </Card>
  );
};

export default BasketCard;
