import { Typography, Stack, Box } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { Event } from '../../../../services/api/event';
import greyScale from '../../../../themes/default/colors/greyscale';
import NftRarityName from '../NftRarityName';

interface EventLotteryProps {
  event: Event;
}

const EventLottery = (props: EventLotteryProps) => {
  const { event } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);

  const lotHelper = (rarity: string | null) => {
    switch (rarity) {
      case 'UNIQUE':
        return { textColor: '#C0C0C0', color: 'linear-gradient(to bottom, #B9F2FF, #FFFFFF)' };
      case 'DIAMOND':
        return { textColor: '#B9F2FF', color: 'linear-gradient(to bottom, #B9F2FF, #FFFFFF)' };
      case 'GOLD':
        return { textColor: '#FFD700', color: 'linear-gradient(to bottom,#FFD700,#FFFFFF)' };
      case 'SILVER':
        return { textColor: '#C0C0C0', color: 'linear-gradient(to bottom,#C0C0C0,#FFFFFF)' };
      case 'BRONZE':
        return { textColor: '#CD7F32', color: 'linear-gradient(to bottom,#CD7F32,#FFFFFF)' };
      default:
        return { textColor: '#FFFFFF', color: 'linear-gradient(to bottom,#ededed,#FFFFFF)' };
    }
  };

  return (
    <Stack direction="column" spacing={2} pt={3}>
      <Typography variant="h5">Tickie Taka</Typography>
      <Typography variant="md" color={darkTheme ? greyScale[300] : greyScale[700]} fontWeight={700}>
        {t('event.tickieTakaInfo')}
      </Typography>
      {/* TODO add lotery */}
      {event?.nft
        .filter(e => e.name !== 'INVITE')
        .filter(e => e.name !== 'COMMON')
        .map(lot => (
          <Stack key={lot.name} direction="row" mb={2}>
            <Typography
              variant="md"
              fontWeight={600}
              display="inline"
              color={darkTheme ? greyScale[400] : greyScale[900]}
            >
              <Box color={lotHelper(lot.name).textColor} fontWeight={700} display="inline">{`x${lot.rarity} `}</Box>
              &nbsp;
              {/* {`${lot.reward}`} */}
            </Typography>
            <NftRarityName rarity={lot.name} />
          </Stack>
        ))}
    </Stack>
  );
};

export default EventLottery;
