import { Box, Button, ButtonBase, CircularProgress, Divider, IconButton, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import format from 'date-fns/format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import 'add-to-calendar-button/assets/css/atcb.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import palette from '../../themes/default/colors/palette';
import greyScale from '../../themes/default/colors/greyscale';
import primary from '../../themes/default/colors/primary';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import DescriptionIconDecoration from '../../components/DescriptionIconDecoration';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import StraightRoundedIcon from '@mui/icons-material/StraightRounded';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import ticketApi, { Event, Ticket } from '../../services/api/ticket';
import QrCodeModal from './QrCodeModal';
import RevealModal from './RevealModal';
import UnhandledError from '../../components/UnhandledError';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { capitalize } from '../../utils/strings';
import noReveal from '../../assets/images/card/no-reveal.png';
import { motion } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { authSlice } from '../../services/auth';
import subscriptionApi, { Subscription, UserSubscription } from '../../services/api/subscription';

interface NftCardProps {
  subscription: Subscription;
  userSubscription: UserSubscription;
  open: any;
  isActive: boolean;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack display="block" top="50%" right={8} position="absolute" sx={{ zIndex: 300, transform: 'translateY(-50%)' }}>
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateNextIcon sx={{ color: '#000' }}></NavigateNextIcon>
      </IconButton>
    </Stack>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      top="50%"
      position="absolute"
      left={8}
      sx={{ transform: 'translate(0, -50%)', zIndex: 300 }}
      onClick={onClick}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateBeforeIcon sx={{ color: '#000' }}></NavigateBeforeIcon>
      </IconButton>
    </Stack>
  );
}

const NftCard = (props: NftCardProps) => {
  const [nftImage, setNftImage] = useState<string>();
  const [nftMeataData, setNftMeataData] = useState<any>();

  const getNfts = async () => {
    const tokenUri = props.userSubscription.tokenUri;
    if (tokenUri) {
      const res = await fetch(tokenUri);
      const metadata = await res.json();
      setNftImage(metadata.image);
      setNftMeataData(metadata);
    }
  };

  useEffect(() => {
    getNfts();
  }, [props.userSubscription]);

  return (
    <div>
      {/* TODO chage */}
      {/* {props.userSubscription.isOpened ? ( */}
      {true ? (
        <>
          <Stack alignItems="center" justifyContent="center" height={330} m={3} borderRadius={8}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              // onClick={onClick}
              className="nft-image-card"
            >
              <motion.img
                src={nftImage}
                height={330}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 300, bounce: 0.35 }}
              />
            </motion.div>
          </Stack>
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center">
          <Stack
            alignItems="center"
            justifyContent="end"
            height={330}
            width={250}
            m={3}
            sx={{ backgroundImage: `url(${noReveal})`, backgroundSize: 'cover' }}
          >
            <Button variant="gradient" sx={{ borderRadius: 100, mb: 3, height: 46 }} onClick={props.open}>
              {t('myTickets.discoverTicket')}
            </Button>
          </Stack>
          <Typography></Typography>
        </Stack>
      )}
    </div>
  );
};

function SubscriptionPass() {
  const { subscriptionUuid } = useParams();
  const {
    data: subscription,
    isLoading,
    isError
  } = subscriptionApi.endpoints.getUserSubscriptionDetails.useQuery(subscriptionUuid ?? '');

  const [openQrCodeModal, setQrCodeModal] = useState(false);
  const [openRevealModal, setRevealModal] = useState(false);
  const mapRef = useRef<null | HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimationOpen, setAnimationOpen] = useState(false);
  const [openTicket] = ticketApi.endpoints.open.useMutation();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const scrollToMap = () => mapRef?.current?.scrollIntoView();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const revealNft = async (ticketUuid: string, eventUuid: string) => {
    await openTicket(ticketUuid).unwrap();
    setRevealModal(true);
    dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventUuid }]));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  const settings = {
    className: 'slick-center',
    dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: any) => (
      <Typography
        variant="h6"
        color={i == activeSlide ? (darkTheme ? 'white' : 'black') : darkTheme ? greyScale[800] : greyScale[300]}
      >
        ●
      </Typography>
    ),

    centerPadding: '60px',

    // beforeChange: (current: number, next: number) => setActiveSlide(next),
    afterChange: (current: number) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: subscription?.customers && subscription?.customers?.length > 2 ? 3 : 1,
          slidesToScroll: subscription?.customers && subscription?.customers?.length > 2 ? 3 : 1,
          infinite: true,
          dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true,
          centerMode: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: subscription?.customers && subscription?.customers?.length > 2 ? 3 : 1,
          slidesToScroll: subscription?.customers && subscription?.customers?.length > 2 ? 3 : 1,
          infinite: true,
          dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true
        }
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: subscription?.customers && subscription?.customers?.length > 10 ? false : true
        }
      }
    ]
  };

  function AppBar() {
    return (
      <Stack direction="row" zIndex="tooltip">
        <Link to="/my-tickets">
          <IconButton>
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Link>
      </Stack>
    );
  }

  // if (isAnimationOpen) {
  //   return (
  //     <>
  //       {subscription?.customers && (
  //         <RevealModal
  //           ticket={subscription?.customers[activeSlide]}
  //           event={subscription?.customers.event}
  //           openRevealModal={openRevealModal}
  //           handleCloseRevealModal={() => setAnimationOpen(false)}
  //         />
  //       )}
  //     </>
  //   );
  // }

  const logout = async () => {
    try {
      dispatch(authSlice.actions.removeCredentials());
      // dispatch(baseApi.util.resetApiState());
      localStorage.clear();
      await logout();
      navigate('/my-tickets');
    } catch (error) {}
  };

  return (
    <>
      {subscription?.customers ? (
        <Box>
          {AppBar()}
          <Stack direction="column" bgcolor={palette.dark1} pt={6} mt={-5} width="100%">
            <Stack direction="row" justifyContent="center" alignItems="end">
              <Typography variant="h4" color={greyScale[50]} textAlign="center">
                {subscription?.name}
              </Typography>
              <Typography variant="xl" color={greyScale[50]} textAlign="center">
                &nbsp; {`#${subscription?.customers[activeSlide].nftId}`}
              </Typography>
            </Stack>

            <Typography variant="lg" color="primary" textAlign="center" mt={0.5}>
              {subscription?.description}
            </Typography>

            <Box width="100%" margin="auto">
              <Slider {...settings}>
                {subscription?.customers.map((t, i) => (
                  <NftCard
                    key={t.uuid}
                    open={() => {
                      setAnimationOpen(true);
                      revealNft(subscription?.customers[activeSlide].uuid, subscription?.uuid);
                    }}
                    isActive={activeSlide == i}
                    userSubscription={t}
                    subscription={subscription}
                  />
                ))}
              </Slider>
            </Box>
          </Stack>

          <Stack direction="column" justifyContent="center" alignItems="center" pb={8} p={2}>
            <Stack
              direction="column"
              m={3}
              width="100%"
              sx={{ maxWidth: { md: 900 }, bgcolor: 'rgba(255,255,255,0.06)', p: 2, borderRadius: 6 }}
            >
              <Typography variant="h5" textAlign="center">
                {subscription.name}{' '}
                {/* {subscription?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
                {subscription?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />} */}
              </Typography>

              <Stack direction="row" mt={5}>
                <DescriptionIconDecoration>
                  <CalendarMonthIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="md" color="primary" fontWeight={600}>
                    {`${capitalize(format(new Date(subscription?.startDate!), 'dd MMMM - '))}`}
                    {`${capitalize(format(new Date(subscription?.startDate!), 'dd MMMM  YYY'))}`}
                  </Typography>
                </Stack>
              </Stack>

              {/* <Stack direction="row">
                <DescriptionIconDecoration>
                  <RoomIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography
                    variant="xl"
                    fontWeight={700}>{`${tickets.event.address?.name}, ${tickets.event?.address?.city}`}</Typography>
                  <Typography variant="md">{`${tickets.event?.address?.street}, ${tickets.event?.address?.zipCode}, ${tickets.event?.address?.city}`}</Typography>
                  <Button variant="text" size="small" sx={{ justifyContent: 'flex-start' }} onClick={scrollToMap}>
                    {t('event.seeOnMap')}
                  </Button>
                </Stack>
              </Stack> */}

              <Box m={2} mb={3}>
                <Divider />
              </Box>

              <Stack direction="row">
                <DescriptionIconDecoration>
                  <HelpCenterIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="xl" fontWeight={700}>
                    {t('myTickets.needHelp')}
                  </Typography>
                  <Typography variant="md">{t('myTickets.needHelpSentence')}</Typography>
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      // startIcon={<HelpCenterIcon />}
                      href="https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a"
                    >
                      {t('myTickets.heplCenter')}
                    </Button>
                  </Box>
                </Stack>
              </Stack>

              {/* <Box m={2}>
                <Divider />
              </Box> */}

              {/* <Stack direction="row">
                <DescriptionIconDecoration>
                  <AnalyticsIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="xl" fontWeight={700}>
                    {t('myTickets.activity')}
                  </Typography>
                  <Typography variant="md">{t('myTickets.activitySentence')}</Typography>
                  <Box>
                    <Button variant="contained" size="small" startIcon={<AnalyticsIcon />} onClick={scrollToMap}>
                      {t('myTickets.activity')}
                    </Button>
                  </Box>
                </Stack>
              </Stack> */}

              <Box height={24} />
            </Stack>
          </Stack>

          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Stack direction="row" spacing={3} justifyContent="center">
              <ButtonBase
                onClick={async () => {
                  const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
                  if (encryptedPrivateKey !== '') {
                    setQrCodeModal(true);
                  } else {
                    await logout();
                  }
                }}
                sx={{ pt: 1, pb: 2 }}
              >
                <Stack direction="column" justifyContent="center" alignItems="center" minWidth={100}>
                  <QrCodeScannerRoundedIcon sx={{ color: primary[300], width: 32, height: 32 }} />
                  <Typography variant="md" fontWeight={700} color={primary[300]}>
                    {' '}
                    {t('myTickets.scan')}
                  </Typography>
                </Stack>
              </ButtonBase>
            </Stack>
          </Paper>
          {openQrCodeModal ? (
            <QrCodeModal
              subscription={subscription}
              userSubscription={subscription?.customers[activeSlide]}
              userSubsctiptions={subscription?.customers}
              // qrCode={eventTickets?.tickets[activeSlide].qrCode}
              // seatInfos={eventTickets?.tickets[activeSlide].seat}
              openQrCoderModal={openQrCodeModal}
              handleCloseQrCodeModal={() => setQrCodeModal(false)}
            />
          ) : null}
        </Box>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default SubscriptionPass;
