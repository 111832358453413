import { Box, Button, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
import shoppingCartSlice from '../../../../services/shoppingCart';
import { t } from 'i18next';

interface IhavePromoCodeButtonProps {
  setShowPromoModal: (show: boolean) => void;
}

const IhavePromoCodeButton = (props: IhavePromoCodeButtonProps) => {
  const { promo } = useAppSelector(state => state.shoppingCartSlice);
  const [urlParams, setUrlParams] = useSearchParams();
  const dispatch = useAppDispatch();

  if (promo) {
    return (
      <Stack direction="row" justifyContent="space-between" mt={2} spacing={3} alignItems="center">
        <Typography variant="h6" textAlign="center" mt={2}>
          {t('reservation.promoCode')} : {promo.promoCode}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(shoppingCartSlice.actions.removePromo());
            urlParams.delete('promoCode');
            setUrlParams(urlParams);
          }}
        >
          {t('reservation.removePromoCode')}
        </Button>
      </Stack>
    );
  } else {
    return (
      <Box mt={2}>
        <Button fullWidth onClick={() => props.setShowPromoModal(true)} sx={{ height: 50 }}>
          {t('reservation.IhavePromoCode')}
        </Button>
      </Box>
    );
  }
};

export default IhavePromoCodeButton;
