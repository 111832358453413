import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';

export const CHAIN_CONFIG = {
  mainnet: {
    displayName: 'Ethereum Mainnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    rpcTarget: `https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c`,
    blockExplorer: 'https://etherscan.io/',
    ticker: 'ETH',
    tickerName: 'Ethereum'
  } as CustomChainConfig,
  solana: {
    chainNamespace: CHAIN_NAMESPACES.SOLANA,
    rpcTarget: 'https://api.mainnet-beta.solana.com',
    blockExplorer: 'https://explorer.solana.com/',
    chainId: '0x1',
    displayName: 'Solana Mainnet',
    ticker: 'SOL',
    tickerName: 'Solana'
  } as CustomChainConfig,
  polygonTestnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://polygon-mumbai.g.alchemy.com/v2/4LRSETVvygh2k5Zmn8jD0EHcG6nRAZk8',
    blockExplorer: 'https://mumbai.polygonscan.com/',
    chainId: '0x13881',
    displayName: 'Polygon Testnet',
    ticker: 'matic',
    tickerName: 'Matic'
  },
  polygonMainnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://polygon-mainnet.g.alchemy.com/v2/otGMos0FmUHWcuOYYiafUrGW9aWI-PYl',
    blockExplorer: 'https://polygonscan.com',
    chainId: '0x89', // hex of 137, polygon mainnet
    displayName: 'Polygon Mainnet',
    ticker: 'matic',
    tickerName: 'Matic'
  } as CustomChainConfig,
  chilizMainnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://rpc.ankr.com/chiliz',
    blockExplorer: 'https://scan.chiliz.com/',
    chainId: '0x13881',
    displayName: 'CHILIZ Mainnet',
    ticker: 'CHZ',
    tickerName: 'CHZ'
  },
  chilizTestnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    rpcTarget: 'https://spicy-rpc.chiliz.com/',
    blockExplorer: 'http://spicy-explorer.chiliz.com/',
    chainId: '0x15F8A',
    displayName: 'CHILIZ Testnet',
    ticker: 'CHZ',
    tickerName: 'CHZ'
  } as CustomChainConfig
} as const;

export type CHAIN_CONFIG_TYPE = keyof typeof CHAIN_CONFIG;
