import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
// import { i18nextPlugin } from 'translation-check'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: {
      home: {
        hello: 'Bonjour👋',
        onDisplay: "À l'affiche 🚀",
        seeAll: 'Voir tout',
        whatEventAreYouLookinFor: 'Rechercher un évènement',
        soon: 'Bientôt',
        darkMode: 'Mode sombre',
        lightMode: 'Mode claire',
        noEvent: 'Aucun Événement'
      },
      profile: {
        profile: 'Profil',
        myProfile: 'Mon profil',
        balance: 'Solde du portefeuille',
        address: 'ID du portefeuille',
        credit: 'Créditer',
        creditSentence:
          'Recharge ton portefeuille en toute sécurité pour transférer de l’argent ou payer plus facilement et rapidement',
        recieve: 'Recevoir',
        recieveSentence: 'Reçois des fonds depuis un autre portefeuille, en euro ou en cryptomonnaie',
        send: 'Envoyer',
        sendSentence:
          'Transfère des euros ou de la cryptomonnaie vers un autre portefeuille ou vers ton compte bancaire',
        collection: 'Collection',
        rewards: 'Récompenses',
        copyText: "Copier l'adresse",
        copied: 'Copié ✅'
      },
      navigationBar: {
        home: 'Home',
        marketplace: 'Marketplace',
        tickets: 'Billets',
        messages: 'Messages',
        profile: 'Profil',
        signIn: 'Connexion',
        signUp: 'Inscription'
      },
      event: {
        addToCalendar: 'Ajouter à mon calendrier',
        seeOnMap: "Voir l'emplacement sur la carte",
        priceOfTicketDepends: 'Le prix du billet dépend du forfait',
        eventIsFinished: 'Cet événement est terminé',
        description: 'Description',
        tickieTakaInfo:
          'Achetez et découvrez votre billet sur Tickie pour avoir la chance de gagner des récompenses exclusives 🤩',
        place: 'Emplacement',
        order: 'Réserver',
        share: 'Partager'
      },
      subscription: {
        addToCalendar: 'Ajouter à mon calendrier',
        seeOnMap: "Voir l'emplacement sur la carte",
        priceOfSubscriptionDepends: "Le prix de l'abonnement dépend du forfait",
        subscriptionNotAvailible: "Cet abonnement n'est pas disponible",
        description: 'Description',
        place: 'Emplacement',
        subscribe: "S'abonner",
        share: 'Partager'
      },
      reservation: {
        reserveAplace: 'Réserver une place',
        left_one: 'restant',
        left_other: 'restants',
        notAvailable: 'Épuisé',
        lookOnSecondaryMarket: 'Chercher sur le marché secondaire',
        pleaseSelectATicket: 'Veuillez sélectionner vos places avant de continuer',
        noTicketsAvailable: 'Il ne reste plus de billet',
        IhavePromoCode: "J'ai un code promo",
        removePromoCode: 'Retirer le code promo',
        promoCode: 'Code promo',
        promoCodeError: "Le code entré n'est pas valide",
        continue: 'Continuer'
      },
      organizationDetails: {
        events: 'événements',
        eventsC: 'Événements',
        continue: 'Continuer',
        about: 'À propos',
        organization: 'Organisateur'
      },
      checkout: {
        recap: 'Récapitulatif',
        name: 'Nom',
        firstName: 'Prénom',
        lastName: 'Nom',
        phone: 'Téléphone',
        mail: 'Email',
        seat_one: 'Place',
        seat_other: 'Places',
        subscription_one: 'Abonnement',
        subscription_other: 'Abonnements',
        fees: 'Frais',
        total: 'Total',
        pay: 'Payer',
        retry: 'Réessayer',
        changeCard: 'Changer de carte',
        cancel: 'Annuler',
        ouchFailed: 'Oops, échoué !',
        acceptTerms: 'J’accepte la <0>Politique de Confidentialité</0> ainsi que <1>les Termes et Conditions</1>',
        getMyInvit: 'Récupérer mon invitation',
        getMyTicket: 'Récupérer mon billet',
        getMySubscription: 'Récupérer mon abonnement'
      },
      paymentSuccess: {
        congratulations: 'Félicitations !',
        congratulationsMessage: 'Vous avez passé avec succès une commande pour <0>{{eventName}}</0>.',
        ticketSentence1: 'Vos billets NFT sont stockés en toute sécurité sur votre portefeuille numérique Tickie',
        ticketSentence2: '👉 Vous ne les recevrez donc pas par mail',
        discoverTicket: 'Découvrir mes billets'
      },
      myTickets: {
        myTickets: 'Mes billets',
        continue: 'Continuer',
        toCome: 'À venir',
        toSell: 'En vente',
        discoverTicket: 'Découvrir mon billet',
        discoverTicketSentence: 'Découvrez vite votre billet pour voir la récompense qui se cache derrère !',
        needHelp: 'Besoin d’aide ?',
        needHelpSentence: 'Consultez notre FAQ ou contactez nous',
        heplCenter: 'Centre d’aide',
        activity: 'Activité',
        activitySentence: 'Consultez l’historique de votre billet',
        sell: 'Vendre',
        scan: 'Scanner',
        transfer: 'Transférer',
        goBack: 'Retour',
        ticketPending: 'Votre billet est en cours de traitement',
        isAlreadyScanned: 'Ce billet a déjà été scanné.',
        congratulations: 'Félicitations !',
        congratulationsSentence: 'Vous avez obtenu un billet <0>{{rarity}}</0>',
        congratulationsRewardSentence: 'Vos récompenses :',
        lostTickieTaka: 'Pas de chance !',
        lostTickieTakaSentence:
          "Dommage vous n'êtes pas tombé sur un billet gagnant. Retentez votre chance la prochaine fois",
        lostTickieTakaZ5Sentence:
          "Dommage vous n'êtes pas tombé sur billet rare. Retentez votre chance la prochaine fois",
        scanned: 'Scanné',
        noTicketFound: 'Aucun billet trouvé'
      },
      changeLanguageModal: {
        chooseLanguage: 'Choisissez une langue'
      },
      login: {
        welcom: 'Bienvenue',
        continueWithFacebook: 'Continuer avec Facebook',
        continueWithGoogle: 'Continuer avec Google',
        continueWithApple: 'Continuer avec Apple',
        continueWithEmail: 'Continuer avec un email',
        or: 'ou',
        continueWithPhoneNumber: 'Continuer avec un numéro de téléphone',
        connectOrRegisterToReserve: 'Connectez-vous ou inscrivez-vous pour réserver',
        continue: 'Continuer',
        rememberMe: 'Se souvenir de moi',
        conexion: 'Connexion',
        phoneNumber: 'Numéro de téléphone',
        conexionOrRegister: 'Connexion ou inscription',
        orContinueWith: 'ou continuer avec',
        enterMail: 'Entrez votre email',
        enterTheCodeSentTo: 'Entrez le code envoyé à <0>{{phoneNumber}}</0>',
        enterTheCodeSentToMail: 'Entrez le code envoyé à <0>{{email}}</0>',
        checkYourPhoneNumber: 'Vérification de votre numéro',
        checkYourEmail: 'Vérification de votre email',
        check: 'Vérifier',
        search: 'Rechercher',
        youAlreadyHaveAnAccount:
          'Vous avez déjà créé un compte en utilisant <0>{{provider}}</0>. Veuillez vous connecter.'
      },
      completeProfile: {
        completeProfile: 'Complétez votre profil',
        firstName: 'Prénom',
        lastName: 'Nom',
        birthdate: 'Date de naissance',
        skip: 'Passer',
        continue: 'Continuer'
      },
      editProfile: {
        title: 'Éditer mon profil',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Mail',
        phone: 'Téléphone',
        birthdate: 'Date de naissance',
        save: 'Sauvegarder'
      },
      notifications: {
        noMessages: 'Aucun message',
        noMessagesText: "Vous n'avez pas de messages pour le moment, revenez plus tard."
      },
      marketplace: {
        marketplace: 'Marketplace',
        marketPlaceCommingSoon: 'Bientôt disponible',
        marketPlaceCommingSoonText: 'La marketplace arrive bientôt.'
      },
      firebaseAuthErrors: {
        unexpected: 'une erreur inattendue est survenue. Veuillez rafraîchir la page.',
        'auth/invalid-phone-number': "Le numéro de téléphone que vous avez fourni n'est pas valide.",
        'auth/invalid-verification-code': 'Le code de vérification est incorrecte.',
        'auth/popup-closed-by-user': 'Vous avez fermé la pop-up de connexion.',
        'auth/account-exists-with-different-credential':
          'Vous avez déja un compte enregistré avec cette adresse mail. Veuillez utiliser la même méthode de connexion que vous aviez utilisé lors de votre inscription.',
        'auth/too-many-requests': 'Nombre de tentatives excédés, veuillez réessayer dans quelques minutes.'
      },
      settings: {
        settings: 'Paramètres',
        profile: 'Profil',
        notifications: 'Notifications',
        paymentMethods: 'Moyens de paiement',
        linkedAccounts: 'Comptes liés',
        ticketIssue: 'Problèmes de billets',
        security: 'Sécurité',
        language: 'Langue',
        darkMode: 'Mode sombre',
        helpCenter: "Centre d'aide",
        inviteFriends: 'inviter des amis',
        rateUs: 'Notez-nous',
        scan: 'Scanner un ticket',
        blockchain: 'Blockchain',
        getPrivateKey: 'Obtenir ma clé privée',
        logout: 'Déconnexion'
      },
      rewards: {
        rewards: 'Récompenses',
        level: 'Niveau {{level}}',
        xpRemainSentence: '<0>{{xpRemain}}</0> Xp pour atteindre le niveau suivant',
        rankigSentence: '{{ranking}}ème du leaderboard',
        lastRewards: 'Dernières récompenses',
        claim: 'Réclamer'
      },
      scan: {
        scanAticket: 'Scanner un Ticket',
        scan: 'Scanner'
      },
      walletErrors: {
        checkIfConnectedToWallet: 'Vérifiez que vous êtes bien connecté à votre portefeuille',
        notOwnerOfThisTicket: "Vous n'êtes pas le propriérte de ce billet"
      },
      formErrors: {
        mailNotValid: 'Veuillez entrer une adresse mail valide 🖖',
        firstNameEmpty: 'Veuillez entrer votre nom 🙏',
        lastNameEmpty: 'Veuillez entrer votre prénom 🙏'
      },
      footer: {
        privacyPolicy: 'Politique de confidentialité',
        saleConditions: 'Conditions générales de vente',
        contactUs: 'Nous contacter',
        faq: 'FAQ'
      },
      unhandledErrors: {
        reload: 'Rafraîchir la page',
        returnHome: "Retourner à l'accueil"
      }
    }
  },
  en: {
    translation: {
      home: {
        hello: 'Hello👋',
        onDisplay: 'Popular 🚀',
        seeAll: 'See All',
        whatEventAreYouLookinFor: 'Search for an event',
        soon: 'Coming Soon',
        darkMode: 'Dark Mode',
        lightMode: 'Light Mode',
        noEvent: 'No events'
      },
      profile: {
        profile: 'Profile',
        myProfile: 'My Profile',
        balance: 'Wallet Balance',
        address: 'Wallet ID',
        credit: 'Credit',
        creditSentence: 'Safely recharge your wallet to transfer money or make payments more easily and quickly',
        recieve: 'Receive',
        recieveSentence: 'Receive funds from another wallet, in euros or cryptocurrency',
        send: 'Send',
        sendSentence: 'Transfer euros or cryptocurrency to another wallet or to your bank account',
        collection: 'Collection',
        rewards: 'Rewards',
        copyText: 'Copy Address',
        copied: 'Copied ✅'
      },
      navigationBar: {
        home: 'Home',
        marketplace: 'Marketplace',
        tickets: 'Tickets',
        messages: 'Messages',
        profile: 'Profile',
        signIn: 'Sign In',
        signUp: 'Sign Up'
      },
      event: {
        addToCalendar: 'Add to Calendar',
        seeOnMap: 'See Location on Map',
        priceOfTicketDepends: 'Ticket price depends on the package',
        eventIsFinished: 'This event has ended',
        description: 'Description',
        tickieTakaInfo: 'Buy and discover your ticket on Tickie to have a chance to win exclusive rewards 🤩',
        place: 'Location',
        order: 'Buy ticket',
        share: 'Share'
      },
      reservation: {
        reserveAplace: 'Choose a ticket',
        left_one: 'left',
        left_other: 'left',
        notAvailable: 'Sold Out',
        lookOnSecondaryMarket: 'Search on the secondary market',
        pleaseSelectATicket: 'Please select your seats before proceeding',
        noTicketsAvailable: 'No tickets left',
        IhavePromoCode: 'I have a promo code',
        promoCode: 'Promo Code',
        promoCodeError: 'The entered code is not valid',
        continue: 'Continue'
      },
      organizationDetails: {
        events: 'events',
        eventsC: 'Events',
        continue: 'Continue',
        about: 'About',
        organization: 'Organizer'
      },
      checkout: {
        recap: 'Summary',
        name: 'Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        phone: 'Phone',
        mail: 'Email',
        seat_one: 'Seat',
        seat_other: 'Seats',
        fees: 'Fees',
        total: 'Total',
        pay: 'Pay',
        retry: 'Retry',
        changeCard: 'Change Card',
        cancel: 'Cancel',
        ouchFailed: 'Oops, Failed!',
        acceptTerms: 'I accept the <0>Privacy Policy</0> and <1>Terms and Conditions</1>',
        getMyInvit: 'Get My Invitation'
      },
      paymentSuccess: {
        congratulations: 'Congratulations!',
        congratulationsMessage: 'You have successfully placed an order for <0>{{eventName}}</0>.',
        ticketSentence1: 'Your NFT tickets are securely stored in your Tickie digital wallet',
        ticketSentence2: '👉 You will not receive them by email',
        discoverTicket: 'Discover My Tickets'
      },
      myTickets: {
        myTickets: 'My Tickets',
        continue: 'Continue',
        toCome: 'Upcoming',
        toSell: 'For Sale',
        discoverTicket: 'Discover My Ticket',
        discoverTicketSentence: 'Quickly discover your ticket to see the hidden reward!',
        needHelp: 'Need Help?',
        needHelpSentence: 'Check out our FAQ or contact us',
        heplCenter: 'Help Center',
        activity: 'Activity',
        activitySentence: 'View the history of your ticket',
        sell: 'Sell',
        scan: 'Scan',
        transfer: 'Transfer',
        goBack: 'Go Back',
        ticketPending: 'Your ticket is being processed',
        isAlreadyScanned: 'This ticket has already been scanned.',
        congratulations: 'Congratulations!',
        congratulationsSentence: 'You have obtained a <0>{{rarity}}</0> ticket',
        congratulationsRewardSentence: 'Your rewards:',
        lostTickieTaka: 'No Luck!',
        lostTickieTakaSentence: 'Too bad, you did not get a winning ticket. Try again next time.',
        lostTickieTakaZ5Sentence: 'Too bad, you did not get a rare ticket. Try again next time.'
      },
      changeLanguageModal: {
        chooseLanguage: 'Choose a Language'
      },
      login: {
        welcom: 'Welcome',
        continueWithFacebook: 'Continue with Facebook',
        continueWithGoogle: 'Continue with Google',
        continueWithApple: 'Continue with Apple',
        continueWithEmail: 'Continue with Email',
        or: 'or',
        continueWithPhoneNumber: 'Continue with Phone Number',
        connectOrRegisterToReserve: 'Connect or register to buy tickets',
        continue: 'Continue',
        rememberMe: 'Remember Me',
        conexion: 'Log In',
        phoneNumber: 'Phone Number',
        conexionOrRegister: 'Log In or Register',
        orContinueWith: 'or continue with',
        enterMail: 'Enter your email',
        enterTheCodeSentTo: 'Enter the code sent to <0>{{phoneNumber}}</0>',
        enterTheCodeSentToMail: 'Enter the code sent to <0>{{email}}</0>',
        checkYourPhoneNumber: 'Verifying your phone number',
        checkYourEmail: 'Verifying your email',
        check: 'Verify',
        search: 'Search',
        youAlreadyHaveAnAccount:
          'You already have an account using <0>{{provider}}</0>. Please log in through this provider.'
      },
      completeProfile: {
        completeProfile: 'Complete Your Profile',
        firstName: 'First Name',
        lastName: 'Last Name',
        birthdate: 'Birthdate',
        skip: 'Skip',
        continue: 'Continue'
      },
      editProfile: {
        title: 'Edit My Profile',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
        birthdate: 'Birthdate',
        save: 'Save'
      },
      notifications: {
        noMessages: 'No Messages',
        noMessagesText: "You don't have any messages at the moment, come back later."
      },
      marketplace: {
        marketplace: 'Marketplace',
        marketPlaceCommingSoon: 'Coming Soon',
        marketPlaceCommingSoonText: 'The marketplace is coming soon.'
      },
      firebaseAuthErrors: {
        unexpected: 'An unexpected error occurred. Please refresh the page.',
        'auth/invalid-phone-number': 'The phone number you provided is not valid.',
        'auth/invalid-verification-code': 'The verification code is incorrect.',
        'auth/popup-closed-by-user': 'You closed the login popup.',
        'auth/account-exists-with-different-credential':
          'You already have an account registered with this email address. Please use the same login method you used during registration.',
        'auth/too-many-requests': 'Too many attempts. Please try again in a few minutes.'
      },
      settings: {
        settings: 'Settings',
        profile: 'Profile',
        notifications: 'Notifications',
        paymentMethods: 'Payment Methods',
        linkedAccounts: 'Linked Accounts',
        ticketIssue: 'Ticket Issues',
        security: 'Security',
        language: 'Language',
        darkMode: 'Dark Mode',
        helpCenter: 'Help Center',
        inviteFriends: 'Invite Friends',
        rateUs: 'Rate Us',
        scan: 'Scan Ticket',
        blockchain: 'Blockchain',
        getPrivateKey: 'Get My Private Key',
        logout: 'Logout'
      },
      scan: {
        scanAticket: 'Scan a Ticket',
        scan: 'Scan'
      },
      walletErrors: {
        checkIfConnectedToWallet: 'Please check if you are connected to your wallet',
        notOwnerOfThisTicket: 'You are not the owner of this ticket'
      },
      formErrors: {
        mailNotValid: 'Please enter a valid email address 🖖',
        firstNameEmpty: 'Please enter your first name 🙏',
        lastNameEmpty: 'Please enter your last name 🙏'
      },
      footer: {
        privacyPolicy: 'Privacy Policy',
        saleConditions: 'Terms of Sale',
        contactUs: 'Contact Us',
        faq: 'FAQ'
      },
      unhandledErrors: {
        reload: 'Reload Page',
        returnHome: 'Return to Home'
      }
    }
  }
};

export const languageList = [
  { language: 'Français', country: 'France', code: 'fr' },
  { language: 'English', country: 'Anglais', code: 'en' }
];

i18n
  .use(detector)
  // .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
