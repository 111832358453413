import { IconButton, Stack } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import greyScale from '../../../../themes/default/colors/greyscale';

function PreviousSlideButton(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      position="absolute"
      left={8}
      sx={{ transform: 'translate(0, -50%)', zIndex: 300 }}
      className="ml-12 sm:top-[50%] top-[95%] "
      onClick={onClick}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateBeforeIcon sx={{ color: '#000' }}></NavigateBeforeIcon>
      </IconButton>
    </Stack>
  );
}

export default PreviousSlideButton;
