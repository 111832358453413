const secondary = {
  100: '#584C',

  200: '#584C',

  300: '#584C',

  400: '#584C',

  500: '#584C'
};

export default secondary;
