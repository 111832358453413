import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignInResponse } from './api/base';

type AuthState = {
  isAuth: boolean;
  accessToken: string | null;
};

export interface JwtUser {
  userId: string;
  displayName: string;
  activated: boolean;
}

const initialState: AuthState = {
  isAuth: localStorage.getItem('user') == null ? false : true,
  accessToken: JSON.parse(localStorage.getItem('user') ?? '{"accessToken":null}').accessToken
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    saveCredentials: (state, { payload: { signInResponse } }: PayloadAction<{ signInResponse: SignInResponse }>) => {
      localStorage.setItem('user', JSON.stringify(signInResponse));
      state.accessToken = signInResponse.accessToken;
      state.isAuth = true;
    },
    removeCredentials: state => {
      state.accessToken = null;
      state.isAuth = false;
      localStorage.removeItem('user');
    }
  }
});

export default authSlice;
