import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import vector from '/btnIcon.svg';
import defaultImg from '/default.png';
import { Subscription } from '../../services/api/subscription';

interface OnDispayEventCardProps {
  subscription: Subscription;
}

const SubscriptionCard = (props: OnDispayEventCardProps) => {
  const { subscription } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();

  return (
    <div
      className="max-w-[420px] flex flex-col p-4 mx-5 rounded-md "
      onClick={() => navigate(`/subscription/${subscription.uuid}`)}
    >
      <div className="w-[300px] h-[405px] rounded-md  border border-white z-10 flex flex-col  items-center shadow-sm shadow-white">
        <img src={subscription?.cover ?? defaultImg} alt="defaultImg" className="w-full rounded-t-md " />
        <div className="font-bold text-white mt-4 text-xl text-center"> {subscription?.name}</div>
        <div className="font-bold text-red-500 text-sm text-center ">{subscription?.description}</div>

        {/* <div>{event?.price}</div> */}
        <div></div>
        <div
          // onClick={() => navigate(`/event/${nearestEvent?.uuid}`)}
          className=" w-[230px] h-[54px] flex justify-center rounded-md items-center bg-red-600 font-bold cursor-pointer z-10 mb-10"
        >
          S'abonner
          <img src={vector} alt="vector" className="ml-5 w-[17px]" />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
