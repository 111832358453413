import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import { Stack } from '@mui/system';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from 'react-router-dom';
import { HomeEvent } from '../../services/api/event';
import format from 'date-fns/format';
import greyScale from '../../themes/default/colors/greyscale';
import { useAppSelector } from '../../redux/hooks';
import { capitalize } from '../../utils/strings';

interface OnDispayEventCardProps {
  event: HomeEvent;
  isEvent?: boolean;
}

const OnDispayEventCard = (props: OnDispayEventCardProps) => {
  const { event } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();
  const defaultImg =
    'https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/px1331795-image-kwvw0m03.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=b9b9885327b681c7c6eb3a0b77fd09ab';

  return (
    <Card
      sx={{ maxWidth: 420 }}
      onClick={() => navigate(props.isEvent ? `/event/${event.uuid}` : `/claim/${event.uuid}`)}
    >
      <CardActionArea>
        <Stack direction="column" height="100%">
          <Box p={1} pb={0}>
            <img
              width="100%"
              src={event.cover || defaultImg}
              alt="event cover"
              style={{ borderRadius: 8, objectFit: 'cover', aspectRatio: '5/3' }}
            />
          </Box>
          <Box pb={0} height="70%">
            <CardContent>
              <Stack direction="column" spacing={1}>
                <Typography variant="h5" noWrap>
                  {event.title}
                </Typography>
                <Typography variant="xl" color="primary" fontWeight={600}>
                  {`${capitalize(format(new Date(event?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
                </Typography>
                {event.address == null ? (
                  <Box height={8} />
                ) : (
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center">
                      <LocationOnIcon sx={{ width: 20, height: 20, mr: 1 }} />
                      <Typography
                        variant="sm"
                        color={greyScale[darkTheme ? 300 : 700]}
                        noWrap
                        width="95%"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {event.address.name} {`, `} {event.address.city}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default OnDispayEventCard;
