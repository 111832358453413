import { colors } from '@mui/material';

const rarityTextStyle = (rarity: string) => {
  switch (rarity) {
    case 'COMMON':
      return { value: 'Regular', component: <span style={{ color: colors.grey[200] }} />, color: colors.grey[200] };
    case 'SILVER':
      return { value: 'Argent', component: <span style={{ color: colors.grey[600] }} />, color: colors.grey[600] };
    case 'GOLD':
      return { value: 'Or', component: <span style={{ color: colors.yellow[600] }} />, color: colors.yellow[600] };
    case 'BRONZ':
      return {
        value: 'Bronze',
        component: <span style={{ color: colors.orange[600] }} />,
        color: colors.orange[600]
      };
    case 'DIAMOND':
      return { value: 'Diamant', component: <span style={{ color: colors.blue[600] }} />, color: colors.blue[600] };
    default:
      return { value: rarity, component: <span style={{ color: colors.blue[600] }} />, color: colors.blue[600] };
  }
};

export default rarityTextStyle;
