import { Box, Button, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import i18next, { t } from 'i18next';
import userApi from '../../services/api/user';
import greyScale from '../../themes/default/colors/greyscale';
import SettingsIcon from '@mui/icons-material/Settings';
import DesktopDrawer from '../../components/DesktopDrawer';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import { languageList } from '../../i18n';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { authSlice } from '../../services/auth';
import { MouseEventHandler, useState } from 'react';
import { useWeb3Auth } from '../../services/web3/web3auth';
import ChangeLanguageModal from '../../components/ChangeLanguageModal';
interface SettingRowProps {
  icon: any;
  title: string;
  customNext?: any;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const SettingRow = (props: SettingRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" onClick={props.onClick}>
      <Stack direction="row" spacing={2.5}>
        {props.icon}
        <Typography variant="xl" fontWeight={600}>
          {props.title}
        </Typography>
      </Stack>
      {props.customNext ? props.customNext : <NavigateNextIcon />}
    </Stack>
  );
};

function Settings() {
  const { data: user, isLoading, isError } = userApi.endpoints.getUser.useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = useWeb3Auth();
  const language = languageList.find(l => l.code === i18next.resolvedLanguage);
  const [changeLanguageModalOpen, setChangeLanguageModalOpen] = useState(false);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh - 200px)`}>
        <CircularProgress />
      </Box>
    );
  }

  const settingsList = [
    { icon: <PersonOutlineRoundedIcon />, title: t('settings.profile'), onClick: () => navigate('/edit-profile') },
    { icon: <NotificationsNoneRoundedIcon />, title: t('settings.notifications') },
    { icon: <AccountBalanceWalletOutlinedIcon />, title: t('settings.paymentMethods') },
    { icon: <SwapVertRoundedIcon />, title: t('settings.linkedAccounts') },
    {
      icon: <ConfirmationNumberOutlinedIcon />,
      title: t('settings.ticketIssue'),
      onClick: () => window.open('https://tally.so/r/3X5gvO')
    },
    {
      icon: <LanguageOutlinedIcon />,

      title: t('settings.language'),
      onClick: () => setChangeLanguageModalOpen(true),
      customNext: (
        <Stack direction="row" alignItems="center">
          <Typography variant="lg" fontWeight={700} p={1}>
            {`${language?.language} (${language?.code.toUpperCase()})`}
          </Typography>
          <NavigateNextIcon />
        </Stack>
      )
    },
    {
      icon: <RemoveRedEyeOutlinedIcon />,
      title: t('settings.darkMode'),
      customNext: <DarkModeSwitch></DarkModeSwitch>
    },
    {
      icon: <HelpCenterOutlinedIcon />,
      title: t('settings.helpCenter'),
      onClick: () => window.open('https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a')
    },
    { icon: <GroupsOutlinedIcon />, title: t('settings.inviteFriends') },
    { icon: <StarBorderOutlinedIcon />, title: t('settings.rateUs') }
  ];

  const logoutButton = () => {
    return (
      <Stack direction="row" alignItems="center" mb={6}>
        <LogoutOutlinedIcon sx={{ color: greyScale[600] }} />
        <Button
          sx={{ color: greyScale[600] }}
          onClick={async () => {
            try {
              dispatch(authSlice.actions.removeCredentials());
              // dispatch(baseApi.util.resetApiState());
              localStorage.clear();
              await logout();
              navigate('/profile');
            } catch (error) {
              navigate('/profile');
            }
          }}
        >
          {t('settings.logout')}
        </Button>
      </Stack>
    );
  };

  return (
    <>
      <ChangeLanguageModal
        changeLanguageModalOpen={changeLanguageModalOpen}
        handleCloseChangeLanguageModal={() => setChangeLanguageModalOpen(false)}
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{t('settings.settings')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
          <DesktopDrawer />
        </Stack>
      </Stack>
      <Box sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 }, mt: 1 }}>
        <Stack direction="column" spacing={3.5} mt={6}>
          {settingsList.map(setting => (
            <SettingRow
              key={setting.title}
              icon={setting.icon}
              title={setting.title}
              customNext={setting.customNext}
              onClick={setting.onClick}
            />
          ))}
        </Stack>
        <Box mt={3} mb={3}>
          <Divider />
        </Box>
        {logoutButton()}
      </Box>
    </>
  );
}

export default Settings;
