const greyScale = {
  50: '#FAFAFA',

  100: '#F5F5F5',

  200: '#EEEEEE',

  300: '#E0E0E0',

  400: '#BDBDBD',

  500: '#9E9E9E',

  600: '#757575',

  700: '#616161',

  800: '#424242',

  900: '#212121'
};

export default greyScale;
