import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { Box, ButtonBase, FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';
import userApi from '../../services/api/user';
import greyScale from '../../themes/default/colors/greyscale';
import SearchBar from '../SearchBar';
import DesktopDrawer from '../DesktopDrawer';
import { useAppSelector } from '../../redux/hooks';
import LogoButton from '../LogoButton';
import logoTextWhite from '../../assets/images/logo/logo-white-big.png';
import logoTextDark from '../../assets/images/logo/logo-dark-big.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import hamburger from '/hamburger.svg';
import x from '/x.png';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { getClubLogoFromHostname, getLinksFromHostname } from '../../pages/whiteLabel/themeFactory';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2)
}));

const CustomAppBar = () => {
  const [showMenuBar, setShowMenuBar] = useState(false);
  const { data: user, isLoading } = userApi.endpoints.getUser.useQuery();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const { isAuth } = useAppSelector(state => state.authSlice);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const theme = useTheme(); // Access the theme object

  const blagnacLogo = getClubLogoFromHostname();

  const renderKeyValuePairs = () => {
    const keyValuePairs = [];
    const data = getLinksFromHostname(); // Specify the type
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        keyValuePairs.push(
          <Link key={key} to={'data[key]'} className=" text-white cursor-pointer font-extrabold hover:text-blue-500">
            {key}
          </Link>
        );
      }
    }

    return keyValuePairs;
  };
  return (
    <>
      <CustomSideAppBar showMenuBar={showMenuBar} />
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.primary.main,
          height: isDesktop ? 130 : 100
        }}
      >
        {!isDesktop && (
          <img
            src={!showMenuBar ? hamburger : x}
            onClick={() => {
              setShowMenuBar(!showMenuBar);
            }}
            alt="hamburger"
            className="absolute z-50 right-10 top-10 w-[25px]"
            style={{ width: showMenuBar ? '29px' : '25px', position: showMenuBar ? 'fixed' : 'absolute' }}
          />
        )}{' '}
        <AppBar position="static" color="transparent" elevation={0}>
          <StyledToolbar>
            <Box display="flex" justifyContent={'center'} width={!isDesktop ? '100%' : 'unset'}>
              {!user || isLoading ? (
                <Stack direction="row">
                  {isAuth ? (
                    <ButtonBase onClick={() => navigate('/home')}>
                      <div className="w-full flex items-center justify-center ">
                        <img
                          src={blagnacLogo}
                          width={isDesktop ? 80 : 60}
                          className="flex justify-center items-center"
                        />
                      </div>
                    </ButtonBase>
                  ) : (
                    <div className="w-full flex items-center justify-center ">
                      <img src={blagnacLogo} width={isDesktop ? 80 : 60} className="flex justify-center items-center" />
                    </div>
                  )}
                </Stack>
              ) : (
                <>
                  <div className="w-full flex items-center justify-center ">
                    <img src={blagnacLogo} width={isDesktop ? 80 : 60} className="flex justify-center items-center" />
                  </div>
                </>
              )}
            </Box>
            {isDesktop && (
              <Box display="flex" alignItems="center" justifyContent="space-between" width="40%">
                {/* <Link
                  to="https://www.blagnac-rugby.fr"
                  className="cursor-pointer text-white font-extrabold hover:text-blue-500 ">
                  Site Officiel
                </Link>
                <Link
                  to="https://billetterie-blagnac.tickie.io/home"
                  className=" text-white decoration-none cursor-pointer font-extrabold hover:text-blue-500">
                  Billetterie
                  to="/my-tickets"
                  className=" text-white decoration-none cursor-pointer font-extrabold hover:text-blue-500"
                >
                  Mes billets
                </Link>
                <Link
                  to="https://www.blagnac-rugby.fr/boutique"
                  className=" text-white cursor-pointer font-extrabold hover:text-blue-500">
                  Boutique
                </Link>
                <Link
                  to="https://www.blagnac-rugby.fr/blagnac-rugby-event"
                  className=" text-white cursor-pointer font-extrabold hover:text-blue-500">
                  Buisness
                </Link> */}
                {renderKeyValuePairs()}
              </Box>
            )}
            <Box display="flex" alignItems="center" justifyContent="center">
              <Stack direction="row" spacing={2}>
                <DesktopDrawer />
              </Stack>
            </Box>
          </StyledToolbar>
          {/* {!isDesktop && (
            <Box sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 } }}>
              <SearchBar showFilterIcon />
            </Box>
          )} */}
        </AppBar>
      </Box>
    </>
  );
};

interface CustomSideAppBarProps {
  showMenuBar: boolean;
}
const CustomSideAppBar: React.FC<CustomSideAppBarProps> = ({ showMenuBar }) => {
  return (
    <div
      className={`w-full z-40 h-full'
      }  fixed top-0 right-0 left-0 bottom-0 bg-black`}
      style={{ transition: '.6s ease-in-out', transform: showMenuBar ? 'translateX(0%)' : 'translateX(100%)' }}
    >
      <div className="flex flex-col w-full justify-center items-center h-full -mt-20">
        <Link
          to="https://www.blagnac-rugby.fr"
          className="cursor-pointer text-white font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
        >
          Site Officiel
        </Link>
        <Link
          to="https://billetterie-blagnac.tickie.io/home"
          className=" text-white decoration-none cursor-pointer font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
        >
          Billetterie
        </Link>
        <Link
          to="https://www.blagnac-rugby.fr/boutique"
          className=" text-white cursor-pointer font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
        >
          Boutique
        </Link>
        <Link
          to="https://www.blagnac-rugby.fr/blagnac-rugby-event"
          className=" text-white cursor-pointer font-semibold hover:text-blue-500 text-2xl border-b border-gray-300 py-5"
        >
          Buisness
        </Link>
      </div>
    </div>
  );
};
export default CustomAppBar;
