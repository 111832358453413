import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import DesktopDrawer from '../../../../components/DesktopDrawer';

interface HeaderProps {
  logo: string;
}

const Footer: React.FC<HeaderProps> = ({ logo }) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <>
      <Box
        width={'100%'}
        bgcolor={'black'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          px: isDesktop ? ' 100px' : '8px'
        }}
      >
        <br />
        <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isDesktop ? 'row' : 'column'
          }}
          height={'100%'}
          width={'100%'}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img height={'50px'} src={logo} alt="logo" />
            <img height={'50%'} src={'/poweredBy.png'} alt="poweredBy" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              my: '20px'
            }}
          >
            <Box
              onClick={() => window.open('https://www.facebook.com/lesdetermines')}
              width={40}
              height={40}
              sx={{
                mx: '8px !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(173, 173, 173, 0.50)',
                borderRadius: '50%',
                padding: '2px'
              }}
            >
              <img src="/socialMedias/facebook.png" alt="facebook" />
            </Box>
            <Box
              onClick={() => window.open('https://www.instagram.com/lesdetermines/')}
              width={40}
              height={40}
              sx={{
                mx: '8px !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(173, 173, 173, 0.50)',
                borderRadius: '50%',
                padding: '2px'
              }}
            >
              <img src="/socialMedias/instagram.png" alt="instagram" />
            </Box>
            <Box
              onClick={() => window.open('https://twitter.com/lesdetermines')}
              width={40}
              height={40}
              sx={{
                mx: '8px !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(173, 173, 173, 0.50)',
                borderRadius: '50%',
                padding: '2px'
              }}
            >
              <img src="/socialMedias/twitter.png" alt="twitter" />
            </Box>
            <Box
              onClick={() => window.open('https://www.youtube.com/channel/UCUqHVuKlO_I7HPKMobeZ-LQ')}
              width={40}
              height={40}
              sx={{
                mx: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(173, 173, 173, 0.50)',
                borderRadius: '50%',
                padding: '2px'
              }}
            >
              <img src="/socialMedias/youtube.png" alt="youtube" />
            </Box>
            <Box
              width={40}
              height={40}
              sx={{
                mx: '8px !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'rgba(173, 173, 173, 0.50)',
                borderRadius: '50%',
                padding: '2px'
              }}
            >
              <img src="/socialMedias/tiktok.png" alt="tiktok" />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: !isDesktop ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${'gray'}`,
            color: 'rgba(173, 173, 173, 0.85)'
          }}
          height={'100%'}
          width={'100%'}
        >
          <Box sx={{ cursor: 'pointer', my: '30px' }}>Tickie © 2023</Box>
          <Box sx={{ cursor: 'pointer', mb: !isDesktop ? '28px' : 'unset' }}>Français (FR)</Box>
          <Box
            sx={{
              display: 'flex'
            }}
          >
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}>Terms Of Service</Box>
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}> Privacy Policy</Box>
            <Box sx={{ mx: isDesktop ? 5 : 1, cursor: 'pointer', textAlign: 'center' }}>Cookie Policy</Box>
          </Box>
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
