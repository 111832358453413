import { Box, ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo/logo-picto.png';
import { getClubLogoFromHostname } from '../../pages/whiteLabel/themeFactory';

const LogoButton = () => {
  const navigate = useNavigate();
  const blagnacLogo = getClubLogoFromHostname();
  return (
    <ButtonBase onClick={() => navigate('/home')}>
      <Box p={2}>
        <img src={blagnacLogo} width={55} />
      </Box>
    </ButtonBase>
  );
};

export default LogoButton;
