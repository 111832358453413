import { t } from 'i18next';
import {
  Button,
  Stack,
  Box,
  Typography,
  Alert,
  Snackbar,
  IconButton,
  useMediaQuery,
  CircularProgress,
  TextField,
  Modal
} from '@mui/material';
import { useState } from 'react';

import logo from '../../../../assets/images/logo/logo-picto.png';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  getIdToken,
  signInWithCustomToken,
  signInWithPhoneNumber
} from 'firebase/auth';
import { PinInput } from 'react-input-pin-code';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Trans } from 'react-i18next';
import 'react-phone-number-input/style.css';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import authApi, { SignInType } from '../../../../services/api/auth';
import { useWeb3Auth } from '../../../../services/web3/web3auth';
import { auth } from '../../../../services/firebase';
import greyScale from '../../../../themes/default/colors/greyscale';
import shadows from '../../../../themes/default/shadows';
import CompleteProfileModal from '../../../Claim/components/CompleteProfileModal';
import { FirebaseError } from 'firebase/app';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import palette from '../../../../themes/default/colors/palette';
import CustomPhoneNumber from '../../../Login/components/CustomPhoneNumber';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  backgroundColor: '#101417',
  width: { xs: '90%', sm: '60%', lg: '40%', xl: '30%' }
};

interface LoginModalProps {
  openLoginModal: boolean;
  handleCloseLoginModal: () => void;
}

enum CurrentPage {
  enterPhone,
  enterPhoneOtp,
  completeProfile
}

function LoginModalPhone(props: LoginModalProps) {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  ///
  const [email, setEmail] = useState<string>('');
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [pinCode, setPinCode] = useState(['', '', '', '', '', '']);
  const [currentPage, setCurrentPage] = useState<CurrentPage>(CurrentPage.enterPhone);
  const [isCheckingAuthCredentials, setCheckingAuthCredentials] = useState(false);
  const [isCheckingPinCode, setCheckingPinCode] = useState(false);
  const [isCheckingWeb3, setIsCheckingWeb3] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [authRequest] = authApi.endpoints.signIn.useMutation();
  const [mailAuthRequest] = authApi.endpoints.signInWithEmail.useMutation();
  const [verifyMailOtpRequest] = authApi.endpoints.verifyMailOtp.useMutation();

  const { login } = useWeb3Auth();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const redirectTo = (needToCompleteProfile: boolean) => {
    if (eventId) {
      return setCurrentPage(CurrentPage.completeProfile);
    } else if (needToCompleteProfile) {
      return setCurrentPage(CurrentPage.completeProfile);
    } else {
      return setCurrentPage(CurrentPage.completeProfile);
    }
  };

  const cancelOtpVerification = (currentPage: CurrentPage) => {
    setPinCode(['', '', '', '', '', '']);
    setCheckingPinCode(false);
    setCurrentPage(currentPage);
    setErrorSnackbar(false);
  };

  const generateRecaptcha = () => {
    if (window) {
      return ((window as any).recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {}
        },
        auth
      ));
    }
  };

  const sendSmsCode = async () => {
    if (phoneNumber === '' || phoneNumber.length < 10) return;
    generateRecaptcha();
    const appVerifier = (window as any).recaptchaVerifier;
    try {
      setCheckingAuthCredentials(true);
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      (window as any).confirmationResult = confirmationResult;
      setCurrentPage(CurrentPage.enterPhoneOtp);
      setCheckingAuthCredentials(false);
    } catch (error) {
      setCheckingAuthCredentials(false);
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/invalid-phone-number') {
          setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-phone-number') ?? ''} (${phoneNumber})`);
          setErrorSnackbar(true);
          console.log(error);
        } else if (error.code === 'auth/too-many-requests') {
          setErrorMessage(`${t('firebaseAuthErrors.auth/too-many-requests') ?? ''}`);
          setErrorSnackbar(true);
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          console.log(error);
        }
      } else {
        setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const verifyMailOtp = async (otp: string) => {
    if (otp.length === 6 && !isCheckingPinCode) {
      try {
        setCheckingPinCode(true);
        const { customIdToken } = await verifyMailOtpRequest({ email: email?.toLowerCase(), otp }).unwrap();
        // isReservation ? null : dispatch(baseApi.util.resetApiState());
        const { user } = await signInWithCustomToken(auth, customIdToken);
        const idToken = await getIdToken(user, true);
        const { needToCompleteProfile, secret } = await authRequest({
          idToken,
          type: SignInType.EMAIL
        }).unwrap();
        setIsCheckingWeb3(true);
        setCheckingPinCode(false);
        await login(await getIdToken(user, true), secret);
        redirectTo(needToCompleteProfile);
        setCheckingPinCode(false);
        setIsCheckingWeb3(false);
      } catch (error) {
        setCheckingPinCode(false);
        setIsCheckingWeb3(false);
        setCheckingAuthCredentials(false);
        setErrorMessage((error as any)?.toString());
        setErrorSnackbar(true);
        console.log(error);
      }
    }
  };

  const verifyPhoneOtp = async (otp: string) => {
    if (otp.length === 6 && !isCheckingPinCode) {
      try {
        setCheckingPinCode(true);
        const confirmationResult = (window as any).confirmationResult;
        const confirm = await confirmationResult.confirm(otp);
        const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
        const { currentUser } = auth;
        if (currentUser) {
          // isReservation ? null : dispatch(baseApi.util.resetApiState());
          const idToken = await getIdToken(currentUser, true);
          const { needToCompleteProfile, secret } = await authRequest({
            idToken,
            type: SignInType.PHONE
          }).unwrap();
          setCheckingPinCode(false);

          await login(await getIdToken(currentUser, true), secret);
          redirectTo(needToCompleteProfile);
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          setCheckingPinCode(false);
        }
        setCheckingPinCode(true);
      } catch (error) {
        setCheckingPinCode(false);
        if (error instanceof FirebaseError) {
          if (error.code === 'auth/invalid-phone-number') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-phone-number') ?? ''} (${phoneNumber})`);
            setErrorSnackbar(true);
            console.log(error);
          } else if (error.code === 'auth/invalid-verification-code') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/invalid-verification-code') ?? ''}`);
            setErrorSnackbar(true);
          } else if (error.code === 'auth/too-many-requests') {
            setErrorMessage(`${t('firebaseAuthErrors.auth/too-many-requests') ?? ''}`);
            setErrorSnackbar(true);
          } else {
            setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
            setErrorSnackbar(true);
            console.log(error);
          }
        } else {
          setErrorMessage(t('firebaseAuthErrors.unexpected') ?? '');
          setErrorSnackbar(true);
          console.log(error);
        }
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  if (currentPage == CurrentPage.enterPhone) {
    {
      return (
        <>
          <Modal open={props.openLoginModal} onClose={props.handleCloseLoginModal}>
            <Box sx={style}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Snackbar
                  open={openErrorSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
                  >
                    {errorMessage}
                  </Alert>
                </Snackbar>

                <Stack direction="column" alignItems="center" spacing={6} width="100%" maxWidth={350}>
                  <Box width={150} height={150} pl={3} sx={{ display: 'none' }}>
                    <img src={logo} width="100%" height="100%" />
                  </Box>

                  <Typography variant="h5" textAlign="center">
                    {t('login.continueWithPhoneNumber')}
                  </Typography>

                  <Stack direction="column" spacing={3} width="100%">
                    <PhoneInput
                      defaultCountry="FR"
                      inputComponent={CustomPhoneNumber}
                      value={phoneNumber}
                      onChange={setPhoneNumber as any}
                      autoComplete="tel"
                      style={{
                        border: `1px solid ${darkTheme ? palette.dark2 : greyScale[200]}`,
                        paddingLeft: 16,
                        height: 62,
                        width: '100%',
                        backgroundColor: darkTheme ? palette.dark2 : greyScale[50],
                        borderRadius: 16,
                        fontFamily: 'Urbanist',
                        fontSize: '1.115rem',
                        fontWeight: 600,
                        marginBottom: 0
                      }}
                    />
                    {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5} display={'flex'}>
                      <Checkbox checked={checked} onChange={handleChange} />
                      <Typography variant="xl" color={greyScale[700]} fontWeight={600}>
                        {t('login.rememberMe')}
                      </Typography>
                    </Stack> */}
                    <Button fullWidth variant="contained" onClick={sendSmsCode}>
                      {isCheckingAuthCredentials ? <CircularProgress sx={{ color: '#FFFFFF' }} /> : t('login.continue')}
                    </Button>
                  </Stack>
                </Stack>

                <div id="recaptcha-container"></div>
              </Box>
            </Box>
          </Modal>
        </>
      );
    }
  }

  if (currentPage == CurrentPage.enterPhoneOtp) {
    return (
      <>
        <Modal open={props.openLoginModal}>
          <Box sx={style}>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
            <Stack direction="row" alignItems="center" justifyContent={'start'} spacing={1} p={1}>
              <IconButton onClick={() => cancelOtpVerification(CurrentPage.enterPhone)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h4">{t('login.checkYourEmail')}</Typography>
            </Stack>
            <Box height={64} />
            <Stack direction="column" alignItems="center" m={3} spacing={4}>
              <Typography variant="xl" textAlign="center">
                <Trans i18nKey="login.enterTheCodeSentToMail" values={{ email }} components={[<b />]} />
              </Typography>
              <PinInput
                values={pinCode}
                autoFocus
                onChange={(value, index, values) => {
                  setPinCode(values);
                  if (currentPage == CurrentPage.enterPhoneOtp) {
                    verifyPhoneOtp(values.join(''));
                  }
                }}
                placeholder=""
                type="number"
                autoComplete="one-time-code"
                inputStyle={{
                  height: isDesktop ? 64 : 50,
                  width: isDesktop ? 64 : 50,
                  marginRight: 8,
                  borderColor: 'transparent',
                  backgroundColor: darkTheme ? greyScale[900] : greyScale[200],
                  color: darkTheme ? 'white' : greyScale[900]
                }}
                showState={false}
              />
              <Box p={3} mb={6} zIndex={1000} width="100%">
                <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (currentPage == CurrentPage.enterPhoneOtp) {
                        verifyPhoneOtp(pinCode.join(''));
                      }
                    }}
                    sx={{ boxShadow: shadows.primaryButton, width: { xs: '100%', md: 200 } }}
                  >
                    {isCheckingPinCode || isCheckingWeb3 ? (
                      <CircularProgress sx={{ color: '#FFFFFF' }} />
                    ) : (
                      t('reservation.continue')
                    )}
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  }

  if (currentPage == CurrentPage.completeProfile) {
    return (
      <>
        <Modal open={props.openLoginModal}>
          <Box sx={style}>
            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
            <CompleteProfileModal
              onClose={() => {
                props.handleCloseLoginModal();
              }}
            />
          </Box>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
}

export default LoginModalPhone;
