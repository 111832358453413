import CryptoJS from 'crypto-js';

export const encryptPrivateKey = (privateKey: string, password: string) => {
  const ciphertext = CryptoJS.AES.encrypt(privateKey, password).toString();

  return ciphertext;
};

export const decryptPrivateKey = (ciphertext: string, password: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, password);
  const decryptedPrivateKey = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedPrivateKey;
};
