const transparent = {
  purple: 'rgba(88, 76, 244, 0.08)',

  blue: 'rgba(36, 107, 253, 0.08)',

  orange: 'rgba(255, 152, 0, 0.08)',

  yellow: 'rgba(250, 204, 21, 0.08)',

  red: 'rgba(247, 85, 85, 0.08)',

  green: 'rgba(76, 175, 80, 0.08)',

  cyan: 'rgba(0, 188, 212, 0.08)'
};

export default transparent;
