import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import promoCodeApi from '../../../../services/api/promo-code';
import { useAppDispatch } from '../../../../redux/hooks';
import shoppingCartSlice from '../../../../services/shoppingCart';

interface PromoCodeModalProps {
  showPromoModal: boolean;
  setShowPromoModal: (show: boolean) => void;
}

function PromoCodeModal(props: PromoCodeModalProps) {
  const { showPromoModal, setShowPromoModal } = props;
  const { eventId } = useParams();
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCodeFromUrl = urlParams.get('promoCode');
  const dispatch = useAppDispatch();

  const [canUsePromoCode] = promoCodeApi.endpoints.canUsePromoCode.useLazyQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promoCode: promoCodeFromUrl ?? ''
    },
    onSubmit: async values => {
      try {
        const data = await canUsePromoCode({ eventUuid: eventId!, code: values.promoCode }).unwrap();
        setShowPromoModal(false);
        setUrlParams({ promoCode: values.promoCode });
        // compute again final price and change promo code
        dispatch(shoppingCartSlice.actions.changePromo({ promo: data }));
      } catch (error) {
        formik.setErrors({ promoCode: t('reservation.promoCodeError') ?? '' });
      }
    }
  });

  return (
    <>
      <Dialog open={showPromoModal} onClose={() => setShowPromoModal(false)}>
        <form onSubmit={formik.handleSubmit}>
          <Box p={3}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mb={3}>
              <Typography variant="h5" textAlign="center">
                {t('reservation.promoCode')}
              </Typography>
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} spacing={3}>
                <TextField
                  name="promoCode"
                  variant="outlined"
                  value={formik.values.promoCode}
                  onChange={formik.handleChange}
                  placeholder={t('reservation.promoCode') ?? ''}
                  fullWidth
                  error={formik.touched.promoCode && Boolean(formik.errors.promoCode)}
                  helperText={formik.touched.promoCode && formik.errors.promoCode}
                />
              </Stack>
              <Button type="submit" variant="contained" fullWidth>
                Appliquer
              </Button>
            </Stack>
          </Box>
        </form>
      </Dialog>
    </>
  );
}

export default PromoCodeModal;
