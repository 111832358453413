import { Stack, Typography, IconButton, Chip, Divider, Box, Button } from '@mui/material';
import { t } from 'i18next';
import DesktopDrawer from '../../components/DesktopDrawer';
import LogoButton from '../../components/LogoButton';
import { CheckBox, Star } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import greyScale from '../../themes/default/colors/greyscale';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import primary from '../../themes/default/colors/primary';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const CircleProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div
      style={{
        background: `radial-gradient(black 50%, transparent 51%),
      conic-gradient(transparent 0deg ${progress}deg, ${greyScale[700]} ${progress}deg 360deg),
      conic-gradient(#5B50F4 0deg, #5B50F4 90deg, #31D6FF 180deg, #31D6FF)`,
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        aspectRatio: '1/1'
      }}
    ></div>
  );
};

const RewardsPage = () => {
  const navigate = useNavigate();
  const appBar = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} p={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>{' '}
          <Typography variant="h5">{t('rewards.rewards')}</Typography>
          <Chip label="Blagnac" variant="outlined" />
        </Stack>
      </Stack>
    );
  };

  const level = '2';
  const xpRemain = '543';
  const ranking = '226';
  return (
    <Box height="100%">
      <div className="reward-page-bg">
        {appBar()}
        <Stack direction="row" alignItems="center" spacing={2} p={3}>
          <CircleProgressBar progress={280}></CircleProgressBar>
          <Stack direction="column">
            <Stack direction="row" alignItems="center" spacing={1}>
              <Star />
              <Typography variant="h5">
                <Trans i18nKey="rewards.level" values={{ level }} />
              </Typography>
            </Stack>
            <Typography variant="sm">
              <Trans i18nKey="rewards.xpRemainSentence" values={{ xpRemain }} />
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2} mt={1}>
              <Button variant="contained" sx={{ height: 40, fontSize: 12 }}>
                <Trans i18nKey="rewards.rankigSentence" values={{ ranking }} />
              </Button>
              <Stack
                border={`1px solid ${greyScale[800]}`}
                borderRadius={100}
                height={40}
                width={40}
                alignItems="center"
                justifyContent="center"
              >
                <EmojiEventsIcon sx={{ color: '#27D4FF' }} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Box p={3} pt={0}>
          <Divider />
        </Box>
        <Box mx={3}>
          <Box width="100%" bgcolor="rgba(255,255,255,0.20)" borderRadius={100} pl={5} pr={3} pt={1} pb={2}>
            <Typography variant="sm">Achète 5 billets pour obtenir 20% sur ton prochain achat</Typography>
            <Stack direction="column">
              <Stack direction="row">
                <CheckCircleIcon color="primary" />
                <CheckCircleIcon color="primary" />
                <RadioButtonUncheckedIcon color="primary" />
                <RadioButtonUncheckedIcon color="primary" />
                <RadioButtonUncheckedIcon color="primary" />
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Typography variant="h5" px={3} py={5}>
          {t('rewards.lastRewards')}
        </Typography>
        {[
          {
            icon: <Star sx={{ width: 28, height: 28, color: primary[500] }} />,
            title: 'Niveau 2 atteint 🏅',
            xp: '+30xp',
            isClaimed: false
          },
          {
            icon: <ConfirmationNumberOutlinedIcon sx={{ width: 28, height: 28, color: primary[500] }} />,
            title: '1 billet acheté',
            xp: '+30xp',
            isClaimed: false
          },
          {
            icon: <ConfirmationNumberOutlinedIcon sx={{ width: 28, height: 28, color: primary[500] }} />,
            title: '1 billet acheté',
            xp: '+30xp',
            isClaimed: false
          },
          {
            icon: <ConfirmationNumberOutlinedIcon sx={{ width: 28, height: 28, color: primary[500] }} />,
            title: '1 billet acheté',
            xp: '+30xp',
            isClaimed: true
          },
          {
            icon: <ConfirmationNumberOutlinedIcon sx={{ width: 28, height: 28, color: primary[500] }} />,
            title: '1 billet acheté',
            xp: '+30xp',
            isClaimed: true
          }
        ].map(action => (
          <Box px={3} py={1} sx={{ opacity: action.isClaimed ? 0.3 : 1 }}>
            <Stack direction="row" width="100%" bgcolor="#212121" px={2} py={1} borderRadius={8}>
              <Box
                borderRadius={4}
                height={64}
                width={64}
                display="flex"
                bgcolor={'rgba(88,76,244, 0.2)'}
                alignItems="center"
                justifyContent="center"
              >
                {action.icon}
              </Box>
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack direction="column" pl={1} justifyContent="center">
                  <Typography variant="lg" fontWeight={700}>
                    {action.title}
                  </Typography>
                  <Typography variant="lg" color={primary[500]}>
                    {' '}
                    {action.xp}
                  </Typography>
                </Stack>
                <Box
                  borderRadius={200}
                  height={64}
                  width={64}
                  display="flex"
                  bgcolor={action.isClaimed ? greyScale[500] : '#27D4FF'}
                  color={'#000'}
                  alignItems="center"
                  justifyContent="center"
                >
                  {action.isClaimed ? 'Claimed' : 'Claim'}
                </Box>
              </Stack>
            </Stack>
          </Box>
        ))}
      </div>
    </Box>
  );
};

export default RewardsPage;
