/* eslint-disable camelcase */

import baseApi from './base';

export interface TicketCategory {
  uuid: string;
  name: string;
  maxPerPerson: number;
  minPerPerson: number;
  description: string;
  quantityAvailable: number;
  price: number;
  subTicketCategory: TicketCategory[];
}

export type PromoCodeParams = {
  eventUuid: string;
  code: string;
  ticketCategoriesUuids?: string[];
};

export enum PromoCodeType {
  PERCENTAGE_OFF,
  FIXED_AMOUNT_OFF
}

export type PromoCodeResponse = {
  id: number;
  uuid: string;
  promoCode: string;
  start: Date;
  end: Date;
  discountAmount: number;
  discountType: 'PERCENTAGE_OFF' | 'FIXED_AMOUNT_OFF';
  maxUsage: number;
  quantityUsed: number;
  description: string;
  expired: boolean;
  emailReference: string;
  currencyCode: string;
  updatedAt: Date;
  createdAt: Date;
  deletedAt: Date | null;
  eventId: number;
  organizationId: number;
  ticketCategories: { ticketCategoryId: number }[];
};

const promoCodeApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    canUsePromoCode: builder.query<PromoCodeResponse, PromoCodeParams>({
      query: params => ({
        method: 'GET',
        url: `/promo-code-discount/is-valid`,
        params
      })
    })
  })
});

export default promoCodeApi;
