import { CircularProgress, Typography, Box, Stack } from '@mui/material';
import OnDispayEventCard from '../../../../components/OnDispayEventCard';
import eventApi, { HomeEvent } from '../../../../services/api/event';
import Grid from '@mui/material/Grid';
import UnhandledError from '../../../../components/UnhandledError';
import { useSearchParams } from 'react-router-dom';
import noEvent from '../../../../assets/images/errors/no-event.png';
import { t } from 'i18next';
import CustomOnDispayEventCard from '../../../../components/CustomOnDispayEventCard';

interface HomeEventListProps {
  isEvent?: boolean;
}

const HomeEventList = (props: HomeEventListProps) => {
  let [urlParams, setUrlParams] = useSearchParams();

  const { data: events, isLoading, isError } = eventApi.endpoints.getEvents.useQuery(urlParams.get('tagId') ?? '');
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh - 200px)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  if ((props.isEvent ? events?.filter(e => e.type == 'EVENT') : events)?.length == 0) {
    return (
      <Box minHeight="80vh">
        <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center" height="60vh">
          <Stack direction="column" alignItems="center" spacing={3}>
            <img src={noEvent} width={300} />
            <Typography variant="h5" fontWeight={600}>
              {t('home.noEvent')}
            </Typography>
          </Stack>
        </Grid>
      </Box>
    );
  }
  return events ? (
    <Box minHeight="80vh">
      <Grid container>
        {(props.isEvent
          ? events?.filter(e => {
              if (e.type == 'EVENT' && ((e as any)?.orgaId === 1 || (e as any)?.orgaId === 3)) {
              }
              return e.type == 'EVENT' && ((e as any)?.orgaId === 1 || (e as any)?.orgaId === 3);
            })
          : events?.filter(e => e.type !== 'EVENT')
        )?.map((event: HomeEvent) => (
          <>
            <div className="w-full flex justify-center md:w-[340px] ">
              <CustomOnDispayEventCard isEvent={props.isEvent} key={event.id} event={event} />
            </div>
          </>
        ))}
      </Grid>
    </Box>
  ) : (
    <div className="text-2xl font-bold md:text-4xl z-20 text-center mt-5">AUCUN ÉVÉNEMENT 😢</div>
  );
};

export default HomeEventList;
