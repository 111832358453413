/* eslint-disable camelcase */

import { string } from 'yup/lib/locale';
import baseApi from './base';
import { Organization } from './event';

export interface Subscription {
  // TODO missing fields
  cover: string;
  isAvailible: boolean;
  minPrice: number;
  maxPrice: number;

  id: number;
  uuid: string;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  status: string;
  numberOfOfferedSubscription: number;
  numberOfRemainingSubscription: number;
  categoryUuid: string | null;
  chartKey: string | null;
  organizationUuid: string;
  generalAdmission: boolean;
  tarifs: Tarif[];
  organization: Organization;
  customers: UserSubscription[];

  web3Address: string;
  web3Id: number;
  web3txHash: string;
  chain: 'POLYGON' | 'CHILIZ';
  provenanceHash: string;
  // update
  deployerAddress: string;
  factoryAddress: string;
}

export interface UserSubscription {
  id: number;
  uuid: string;
  userId: number;
  subscriptionId: number;
  tarifId: number;
  startDate: Date;
  endDate: Date;
  seatLabel: string | null;
  seatCategoryLabel: string | null;
  seatSection: string | null;
  qrCode: string | null;
  nftId: number;
  txHash: string;
  tokenUri: string;
  isScanned: boolean;
}

export interface Tarif {
  id: number;

  isAvailable: boolean;

  uuid: string;
  name: string;
  amount: number;
  description: string | null;
  subscriptionUuid: string | null;
}

export interface claimSubscriptionForm {
  subscriptionInvite: string;
}

const subscriptionApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllSubscriptions: builder.query<Subscription[], void>({
      query: () => ({
        url: `/subscriptions`,
        method: 'GET'
      }),
      providesTags: ['subscriptions']
    }),
    getOrganizationSubscriptions: builder.query<Subscription[], string>({
      query: orgaUuid => ({
        url: `/subscriptions/${orgaUuid}`,
        method: 'GET'
      }),
      providesTags: ['subscriptions']
    }),
    getSubscriptionDetails: builder.query<Subscription, string>({
      query: subscriptionUuid => ({
        method: 'GET',
        url: `/subscriptions/${subscriptionUuid}`
      })
    }),
    getUserSubscriptions: builder.query<Subscription[], void>({
      query: () => ({
        method: 'GET',
        url: `/subscriptions/user-subscriptions`
      }),
      providesTags: ['user-subscriptions']
    }),
    getUserSubscriptionDetails: builder.query<Subscription, string>({
      query: subscriptionUuid => ({
        method: 'GET',
        url: `/subscriptions/user-subscriptions-details/${subscriptionUuid}`
      })
    }),

    subscriptionByInvite: builder.query<Subscription & { quantity: number }, string>({
      query: inviteUuid => ({
        method: 'GET',
        url: `/subscriptions/subscription-invite/${inviteUuid}`
      })
    }),
    claimSubscription: builder.mutation<void, claimSubscriptionForm>({
      query: body => ({
        method: 'PATCH',
        url: `/subscriptions/claim-invite`,
        body
      })
    })
  })
});

export default subscriptionApi;
