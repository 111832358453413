import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import format from 'date-fns/format';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import 'add-to-calendar-button/assets/css/atcb.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RoomIcon from '@mui/icons-material/Room';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AddToCalendarButton from '../EventDetails/components/AddToCalendarButton';
import palette from '../../themes/default/colors/palette';
import greyScale from '../../themes/default/colors/greyscale';
import primary from '../../themes/default/colors/primary';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import DescriptionIconDecoration from '../../components/DescriptionIconDecoration';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import StraightRoundedIcon from '@mui/icons-material/StraightRounded';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import ticketApi, { Event, Ticket } from '../../services/api/ticket';
import RevealModal from './RevealModal';
import UnhandledError from '../../components/UnhandledError';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { capitalize } from '../../utils/strings';
import noReveal from '../../assets/images/card/no-reveal.png';
import { motion } from 'framer-motion';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import rarityTextStyle from '../../utils/rarityTextColor';
import { authSlice } from '../../services/auth';
import eventApi from '../../services/api/event';
import NftRarityName from '../EventDetails/components/NftRarityName';

interface NftCardProps {
  ticket: Ticket;
  event: Event;
  open: any;
  isActive: boolean;
}

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack display="block" top="50%" right={8} position="absolute" sx={{ zIndex: 300, transform: 'translateY(-50%)' }}>
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateNextIcon sx={{ color: '#000' }}></NavigateNextIcon>
      </IconButton>
    </Stack>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <Stack
      display="block"
      top="50%"
      position="absolute"
      left={8}
      sx={{ transform: 'translate(0, -50%)', zIndex: 300 }}
      onClick={onClick}
    >
      <IconButton onClick={onClick} sx={{ backgroundColor: greyScale[100] }}>
        <NavigateBeforeIcon sx={{ color: '#000' }}></NavigateBeforeIcon>
      </IconButton>
    </Stack>
  );
}

const NftCard = (props: NftCardProps) => {
  const [nftImage, setNftImage] = useState<string>();
  const [nftMeataData, setNftMeataData] = useState<any>();

  const getNfts = async () => {
    const tokenUri = props.ticket.tokenUri;
    if (tokenUri) {
      const res = await fetch(tokenUri);
      const metadata = await res.json();
      setNftImage(metadata.image);
      setNftMeataData(metadata);
    }
  };

  useEffect(() => {
    if (props.ticket.isOpened) {
      getNfts();
    }
  }, [props.ticket]);

  return (
    <div>
      {props.ticket.isOpened ? (
        <>
          <Stack alignItems="center" justifyContent="center" height={330} m={3} borderRadius={8}>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              // onClick={onClick}
              className="nft-image-card"
            >
              <motion.img
                src={nftImage}
                height={330}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: 'spring', stiffness: 300, bounce: 0.35 }}
              />
            </motion.div>
          </Stack>
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center">
          <Stack
            alignItems="center"
            justifyContent="end"
            height={330}
            width={250}
            m={3}
            sx={{ backgroundImage: `url(${noReveal})`, backgroundSize: 'cover' }}
          >
            {props.ticket.nftId !== null ? (
              <Button variant="gradient" sx={{ borderRadius: 100, mb: 3, height: 46 }} onClick={props.open}>
                {t('myTickets.discoverTicket')}
              </Button>
            ) : (
              <Button disabled variant="gradient" sx={{ borderRadius: 100, mb: 3, height: 46 }} onClick={props.open}>
                {t('myTickets.discoverTicket')}
                <CircularProgress sx={{ color: '#FFF', ml: 1 }} size={24} />
              </Button>
            )}
          </Stack>
          <Typography></Typography>
        </Stack>
      )}
    </div>
  );
};

interface RewardSectionProps {
  ticket: Ticket;
  openTicket: () => void;
}

const RewardSection = (props: RewardSectionProps) => {
  const { openTicket, ticket } = props;

  const [nftImage, setNftImage] = useState<string>();
  const [nftMeataData, setNftMeataData] = useState<any>();
  const [reward, setReward] = useState<string>();

  const getNfts = async () => {
    const tokenUri = props.ticket.tokenUri;
    if (tokenUri) {
      const res = await fetch(tokenUri);
      const metadata = await res.json();
      setNftImage(metadata.image);
      setNftMeataData(metadata);
      const rarity = metadata?.attributes?.find((e: any) => e.trait_type === 'rarity')?.value.toString() ?? '';
      const reward = metadata?.attributes?.find((e: any) => e.trait_type === 'rewards')?.value.toString() ?? '';
      setReward(reward);
    }
  };

  useEffect(() => {
    if (props.ticket.isOpened) {
      getNfts();
    }
  }, [props.ticket]);

  return (
    <Stack direction="row">
      <DescriptionIconDecoration>
        <ConfirmationNumberIcon />
      </DescriptionIconDecoration>
      <Stack direction="column" pl={2} spacing={0.5}>
        <Typography variant="xl" fontWeight={700}>
          Tickie Taka
        </Typography>

        {ticket.isOpened ? (
          <>
            <NftRarityName rarity={ticket.rarity} />
            {ticket.rarity !== 'COMMON' && (
              <Typography variant="md" color={rarityTextStyle(ticket.rarity)?.color}>
                {reward}
              </Typography>
            )}
          </>
        ) : (
          // <Typography variant="md">Votre récompense : </Typography>
          <>
            <Typography variant="md">{t('myTickets.discoverTicketSentence')}</Typography>
            <Box>
              <Button
                variant="gradient"
                size="small"
                startIcon={<ConfirmationNumberIcon />}
                onClick={() => {
                  props.openTicket();
                  // setAnimationOpen(true);
                  // revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                }}
              >
                {t('myTickets.discoverTicket')}
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  );
};

function TicketDetails() {
  const { eventId } = useParams();
  const { data: event } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const [openRevealModal, setRevealModal] = useState(false);
  const mapRef = useRef<null | HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimationOpen, setAnimationOpen] = useState(false);
  const [openTicket] = ticketApi.endpoints.open.useMutation();
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const scrollToMap = () => mapRef?.current?.scrollIntoView();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef<Slider>(null);
  const [isPolling, setIsPolling] = useState(false);
  const {
    data: tickets,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (tickets && tickets.tickets?.find(t => t.status === 'TO_BE_MINTED')) {
        dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventId! }]));
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [dispatch, tickets]);

  const revealNft = async (ticketUuid: string, eventUuid: string) => {
    await openTicket(ticketUuid).unwrap();
    setRevealModal(true);
    dispatch(ticketApi.util.invalidateTags([{ type: 'ticket-details', id: eventUuid }]));
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  const settings = {
    className: 'slick-center',
    dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: (i: any) => (
      <Typography
        variant="h6"
        color={i == activeSlide ? (darkTheme ? 'white' : 'black') : darkTheme ? greyScale[800] : greyScale[300]}
      >
        ●
      </Typography>
    ),

    centerPadding: '60px',

    // beforeChange: (current: number, next: number) => setActiveSlide(next),
    afterChange: (current: number) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true,
          centerMode: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: tickets?.tickets && tickets?.tickets?.length > 2 ? 3 : 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      },

      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: tickets?.tickets && tickets?.tickets?.length > 10 ? false : true
        }
      }
    ]
  };

  function AppBar() {
    const theme = useTheme();
    console.log(theme.palette.primary);
    return (
      <Stack direction="row" zIndex="tooltip" position="absolute" left={0} top={0} m={1}>
        <Link to="/my-tickets">
          <IconButton>
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Link>
      </Stack>
    );
  }

  if (isAnimationOpen) {
    return (
      <>
        {tickets && (
          <RevealModal
            ticket={tickets.tickets[activeSlide]}
            event={tickets.event}
            openRevealModal={openRevealModal}
            handleCloseRevealModal={() => setAnimationOpen(false)}
          />
        )}
      </>
    );
  }

  const logout = async () => {
    try {
      dispatch(authSlice.actions.removeCredentials());
      // dispatch(baseApi.util.resetApiState());
      localStorage.clear();
      await logout();
      navigate('/my-tickets');
    } catch (error) {}
  };

  const findFirstTicketNotOpened = (tickets: Ticket[]) => {
    const ticket = tickets.find(ticket => !ticket.isOpened);
    if (ticket) {
      return ticket.uuid;
    }
  };
  const theme = useTheme();
  console.log(theme);
  return (
    <>
      {tickets && tickets?.tickets?.length > 0 ? (
        <Box>
          {AppBar()}
          <Stack direction="column" bgcolor={palette.dark1} pt={6} mt={-5} width="100%">
            <Stack direction="row" justifyContent="center" alignItems="end">
              <Typography variant="h4" color={greyScale[50]} textAlign="center">
                {tickets?.event?.title}
              </Typography>
              <Typography variant="xl" color={greyScale[50]} textAlign="center">
                &nbsp;{' '}
                {tickets.tickets[activeSlide].nftId === null ? (
                  <span
                    style={{
                      backgroundColor: 'orange',
                      borderRadius: 100,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingBottom: 2,
                      fontSize: 13
                    }}
                  >
                    mint
                  </span>
                ) : (
                  `#${tickets.tickets[activeSlide].nftId}`
                )}
              </Typography>
            </Stack>

            <Typography variant="lg" color="primary" textAlign="center" mt={0.5}>
              {tickets.event.subTitle}
            </Typography>

            <Box width="100%" margin="auto">
              <Slider {...settings} ref={sliderRef}>
                {tickets.tickets.map((t, i) => (
                  <NftCard
                    key={t.uuid}
                    open={() => {
                      // sliderRef?.current?.slickGoTo(0);
                      // setActiveSlide(2);
                      setAnimationOpen(true);
                      revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                    }}
                    isActive={activeSlide == i}
                    ticket={t}
                    event={tickets.event}
                  />
                ))}
              </Slider>
            </Box>
          </Stack>

          <Stack direction="column" justifyContent="center" alignItems="center" pb={8} p={2}>
            <Stack
              direction="column"
              m={3}
              width="100%"
              sx={{ maxWidth: { md: 900 }, bgcolor: 'rgba(255,255,255,0.06)', p: 2, borderRadius: 6 }}
            >
              <Typography variant="h5" textAlign="center">
                {tickets.tickets[activeSlide]?.ticketCategory.name ?? 'ticketCategory'}{' '}
                {event?.chain === 'CHILIZ' && <img alt="matic" src="/images/chains/chiliz.png" height={16} />}
                {event?.chain === 'POLYGON' && <img alt="matic" src="/images/chains/polygon.png" height={16} />}
              </Typography>
              <Box m={2}>
                <Divider />
              </Box>
              <Stack direction="row">
                <DescriptionIconDecoration>
                  <CalendarMonthIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="xl" fontWeight={700}>{`${capitalize(
                    format(new Date(tickets.event.beginAt), 'eeee dd MMMM yyyy')
                  )}`}</Typography>
                  <Typography variant="md">{`${format(new Date(tickets.event.beginAt), "HH'h'mm")} - ${format(
                    new Date(tickets.event.endAt),
                    "HH'h'mm (OOOO)"
                  )}`}</Typography>
                  <AddToCalendarButton
                    title={tickets.event.title}
                    beginAt={tickets.event.beginAt}
                    endAt={tickets.event.endAt}
                  />
                </Stack>
              </Stack>

              <Box m={2}>
                <Divider />
              </Box>

              <Stack direction="row">
                <DescriptionIconDecoration>
                  <RoomIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography
                    variant="xl"
                    fontWeight={700}
                  >{`${tickets.event.address?.name}, ${tickets.event?.address?.city}`}</Typography>
                  <Typography variant="md">{`${tickets.event?.address?.street}, ${tickets.event?.address?.zipCode}, ${tickets.event?.address?.city}`}</Typography>
                  <Button variant="text" size="small" sx={{ justifyContent: 'flex-start' }} onClick={scrollToMap}>
                    {t('event.seeOnMap')}
                  </Button>
                </Stack>
              </Stack>

              <Box m={2}>
                <Divider />
              </Box>

              <RewardSection
                ticket={tickets.tickets[activeSlide]}
                openTicket={() => {
                  setAnimationOpen(true);
                  revealNft(tickets.tickets[activeSlide].uuid, tickets.event.uuid);
                }}
              />

              <Box m={2}>
                <Divider />
              </Box>

              <Stack direction="row">
                <DescriptionIconDecoration>
                  <HelpCenterIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="xl" fontWeight={700}>
                    {t('myTickets.needHelp')}
                  </Typography>
                  <Typography variant="md">{t('myTickets.needHelpSentence')}</Typography>
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      // startIcon={<HelpCenterIcon />}
                      href="https://tickieapp.notion.site/FAQ-3a1ebb1e6b47402a9b5dc9751191b17a"
                    >
                      {t('myTickets.heplCenter')}
                    </Button>
                  </Box>
                </Stack>
              </Stack>

              {/* <Box m={2}>
                <Divider />
              </Box> */}

              {/* <Stack direction="row">
                <DescriptionIconDecoration>
                  <AnalyticsIcon />
                </DescriptionIconDecoration>
                <Stack direction="column" pl={2} spacing={0.5}>
                  <Typography variant="xl" fontWeight={700}>
                    {t('myTickets.activity')}
                  </Typography>
                  <Typography variant="md">{t('myTickets.activitySentence')}</Typography>
                  <Box>
                    <Button variant="contained" size="small" startIcon={<AnalyticsIcon />} onClick={scrollToMap}>
                      {t('myTickets.activity')}
                    </Button>
                  </Box>
                </Stack>
              </Stack> */}

              <Box height={24} />
            </Stack>
          </Stack>

          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Stack direction="row" spacing={3} justifyContent="center">
              <ButtonBase sx={{ pt: 1, pb: 2 }}>
                <Stack direction="column" justifyContent="center" alignItems="center" minWidth={100}>
                  <StraightRoundedIcon color="primary" />
                  <Typography variant="sm" fontWeight={700} color="primary">
                    {t('myTickets.sell')}
                  </Typography>
                </Stack>
              </ButtonBase>
              <ButtonBase
                onClick={async () => {
                  const encryptedPrivateKey = localStorage.getItem('encryptedPrivateKey') ?? '';
                  if (encryptedPrivateKey !== '') {
                    navigate(`/scan/ticket/${tickets.event.uuid}`);
                  } else {
                    await logout();
                  }
                }}
                sx={{ pt: 1, pb: 2 }}
              >
                <Stack direction="column" justifyContent="center" alignItems="center" minWidth={100}>
                  <QrCodeScannerRoundedIcon sx={{ color: primary[300], width: 32, height: 32 }} />
                  <Typography variant="md" fontWeight={700} color={primary[300]}>
                    {' '}
                    {t('myTickets.scan')}
                  </Typography>
                </Stack>
              </ButtonBase>
              <ButtonBase sx={{ pt: 1, pb: 2 }}>
                <Stack direction="column" justifyContent="center" alignItems="center" minWidth={100}>
                  <SwapHorizRoundedIcon color="primary" />
                  <Typography variant="sm" fontWeight={700} color="primary">
                    {' '}
                    {t('myTickets.transfer')}
                  </Typography>
                </Stack>
              </ButtonBase>
            </Stack>
          </Paper>
        </Box>
      ) : (
        <>
          <Typography variant="h6" textAlign="center" color={palette.error} mt={12}>
            {' '}
            {t('myTickets.noTicketFound')}
          </Typography>
          <UnhandledError />
        </>
      )}
    </>
  );
}

export default TicketDetails;
