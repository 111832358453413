import { IconButton, Typography, Divider, Grid, Box, alpha } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share';
import SendIcon from '@mui/icons-material/Send';
import Sheet from 'react-modal-sheet';
import greyScale from '../../../../themes/default/colors/greyscale';

interface ShareModalProps {
  url: string;
  isDesktop?: boolean;
}

const ShareModal = (props: ShareModalProps) => {
  const [shareModalopen, setShareModalOpen] = useState(false);
  const handleOpen = () => setShareModalOpen(true);
  const handleClose = () => setShareModalOpen(false);

  const buttonStyle = props.isDesktop ? { mr: 1 } : { bgcolor: alpha(greyScale[900], 0.25), m: 1 };
  const iconStyle = props.isDesktop ? {} : { color: 'white' };
  return (
    <>
      <IconButton onClick={handleOpen} sx={buttonStyle}>
        <SendIcon sx={iconStyle}></SendIcon>
      </IconButton>
      {shareModalopen ? (
        <Sheet isOpen={shareModalopen} onClose={handleClose} detent="content-height">
          <Sheet.Container>
            <Sheet.Header>
              <Typography m={3} textAlign="center">
                {t('event.share')}
              </Typography>
              <Box ml={2} mr={2}>
                <Divider />
              </Box>
            </Sheet.Header>
            <Sheet.Content>
              <Grid container spacing={2} mt={1} mb={3} justifyContent="center">
                <Grid item>
                  <WhatsappShareButton url={props.url}>
                    <WhatsappIcon round />
                  </WhatsappShareButton>
                  <Typography>WhatsApp</Typography>
                </Grid>
                <Grid item>
                  <TwitterShareButton url={props.url}>
                    <TwitterIcon round />
                  </TwitterShareButton>
                  <Typography>Twitter</Typography>
                </Grid>
                <Grid item>
                  <FacebookShareButton url={props.url}>
                    <FacebookIcon round />
                  </FacebookShareButton>
                  <Typography>Facebook</Typography>
                </Grid>
                <Grid item>
                  <LinkedinShareButton url={props.url}>
                    <LinkedinIcon round />
                  </LinkedinShareButton>
                  <Typography>LinkedIn</Typography>
                </Grid>
              </Grid>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      ) : null}
    </>
  );
};

export default ShareModal;
