/* eslint-disable camelcase */

import { authSlice } from '../auth';
import baseApi, { SignInResponse } from './base';

export enum SignInType {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL'
}

export interface SignInForm {
  idToken: string;
  type: SignInType;
}

export interface SignInWithEmailForm {
  email: string;
}
export interface VerifyMailOtpForm {
  email: string;
  otp: string;
}

export interface VerifyMailOtpResponse {
  customIdToken: string;
}

const authApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    signIn: builder.mutation<SignInResponse, SignInForm>({
      query: body => ({
        url: `/auth/sign-in`,
        method: 'POST',
        body
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(authSlice.actions.saveCredentials({ signInResponse: response.data }));
        } catch (err) {
          console.log(err);
        }
      }
    }),
    signInWithEmail: builder.mutation<any, SignInWithEmailForm>({
      query: body => ({
        url: '/auth/sign-in-with-mail',
        method: 'POST',
        body
      })
    }),
    verifyMailOtp: builder.mutation<VerifyMailOtpResponse, VerifyMailOtpForm>({
      query: body => ({
        url: '/auth/verify-mail-otp',
        method: 'POST',
        body
      })
    })
  })
});

export default authApi;
