import { Modal, Typography, Button, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import paymentErrorImage from '../../../../assets/images/errors/payment-error.png';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  width: { xs: '66%', sm: '50%', lg: '40%', xl: '30%' }
};

interface PaymentErrorModalProps {
  openErrorModal: boolean;
  handleCloseErrorModal: () => void;
  retry: (e: any) => Promise<void>;
  message: string | null;
}

const PaymentErrorModal = (props: PaymentErrorModalProps) => {
  const retryPayment = (e: any) => {
    e.preventDefault();
    props.handleCloseErrorModal();
    props.retry(e);
  };

  return (
    <Modal open={props.openErrorModal} onClose={props.handleCloseErrorModal}>
      <Box sx={style}>
        <Stack direction="column" justifyContent="center" spacing={3}>
          <Box display="flex" justifyContent="center" mt={2}>
            <img alt="payment-error" src={paymentErrorImage} />
          </Box>
          <Typography variant="h4" color="error" textAlign="center">
            {t('checkout.ouchFailed')}
          </Typography>
          <Typography variant="xl" textAlign="center" fontWeight={600}>
            {props.message}
          </Typography>
          <Stack direction="column" spacing={1}>
            <Button variant="contained" onClick={retryPayment}>
              {t('checkout.retry')}
            </Button>
            <Button variant="ContainedSecondary" onClick={props.handleCloseErrorModal}>
              {t('checkout.changeCard')}
            </Button>
            <Button variant="ContainedSecondary" onClick={props.handleCloseErrorModal}>
              {t('checkout.cancel')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PaymentErrorModal;
