import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import UpcomingEventsCard from '../../components/UpcomingEventsCard';
import ticketApi from '../../services/api/ticket';
import DesktopDrawer from '../../components/DesktopDrawer';
import TabPanel from '../../components/TabPanel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Login from '../Login';
import LogoButton from '../../components/LogoButton';
import UnhandledError from '../../components/UnhandledError';
import PendingEventReservationCard from '../../components/PendingEventReservationCard';
import noTicketsImage from '/Frame.svg';
import subscriptionApi from '../../services/api/subscription';
import SubscriptionCard from '../../components/SubscriptionCard';
import UserSubscriptionCard from '../../components/UserSubscriptionCard';

const MyTickets = () => {
  const {
    data: ticketsEvent,
    isLoading,
    isError
  } = ticketApi.endpoints.getTicketsEvent.useQuery(undefined, { refetchOnMountOrArgChange: true });

  // const {
  //   data: userSubscriptions,
  //   isLoading: userSubscriptionsIsLoading,
  //   isError: userSubscriptionsIsError
  // } = subscriptionApi.endpoints.getUserSubscriptions.useQuery(undefined, { refetchOnMountOrArgChange: true });

  const [triggerFindPendingReservation, { data: pendingReservation }] =
    ticketApi.endpoints.findPendingReservation.useLazyQuery(undefined);
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(state => state.authSlice);
  const [value, setValue] = useState(0);
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(() => {
        triggerFindPendingReservation();
        dispatch(ticketApi.util.invalidateTags(['tickets']));
        // dispatch(subscriptionApi.util.invalidateTags(['user-subscriptions']));
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [isPolling, dispatch]);

  useEffect(() => {
    setIsPolling(true);

    setTimeout(() => {
      setIsPolling(false);
    }, 20000);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!isAuth) {
    return <Login />;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`100vh`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  const appBar = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <LogoButton />
          <Typography variant="h5">{t('myTickets.myTickets')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <SearchIcon />
          <MoreHorizIcon />
          <DesktopDrawer />
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      {appBar()}
      <TabPanel value={value} index={0}>
        {pendingReservation || ticketsEvent ? (
          <Box minHeight="100vh" m={3}>
            <Grid container spacing={2}>
              {/* {pendingReservation?.map(reservation => (
                <Grid key={reservation.uuid} item xs={12} sm={6} md={6} xl={6}>
                  <PendingEventReservationCard event={reservation.event} />
                </Grid>
              ))} */}
              {ticketsEvent?.map(event => (
                <Grid
                  key={event.uuid}
                  item
                  xs={50}
                  sm={7}
                  md={5}
                  xl={3}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: { xl: '0', md: 'auto' }
                  }}
                >
                  <UpcomingEventsCard event={event} />
                </Grid>
              ))}
              {/* {userSubscriptions?.map(sub => (
                <Grid
                  key={sub.uuid}
                  item
                  xs={50}
                  sm={7}
                  md={5}
                  xl={3}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: { xl: '0', md: 'auto' }
                  }}
                >
                  <UserSubscriptionCard subscription={sub} />
                </Grid>
              ))} */}
            </Grid>
          </Box>
        ) : (
          <NoTickets />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={2} pl={1} pr={1} minHeight="100vh"></Grid>
      </TabPanel>
    </>
  );
};

const NoTickets = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <img alt="no-tickets" src={noTicketsImage} />
      <Typography variant="h3" fontWeight={700} mt={2}>
        Aucun billet
      </Typography>
      <br />
      <Typography variant="lg" fontWeight={500} mt={2}>
        Il semble que vous n'ayez pas encore de billet.{' '}
      </Typography>
      <br />
      <Typography variant="lg" fontWeight={500} mt={2} textAlign={'center'}>
        Commencez à rechercher des événements <br /> en cliquant sur le bouton ci-dessous 👇{' '}
      </Typography>
      <br />
      <br />
      <br />
    </Box>
  );
};

export default MyTickets;
