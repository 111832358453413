import setDefaultOptions from 'date-fns/setDefaultOptions';
import AppRouter from './AppRouter';
import './i18n';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import theme from './themes/default-initial/theme';
import { useAppSelector } from './redux/hooks';
import { useWeb3Auth, Web3AuthProvider } from './services/web3/web3auth';
import { fr } from 'date-fns/esm/locale';
import { CHAIN_CONFIG_TYPE } from './config/chainConfig';
import {
  getClubNameFromHostname,
  getClubThemeFromHostname,
  getFaviconFromHostname
} from './pages/whiteLabel/themeFactory';
import { useEffect } from 'react';
setDefaultOptions({ locale: fr });

function App() {
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const clubTheme = getClubThemeFromHostname(darkTheme); // Get the theme based on hostname
  const {} = useWeb3Auth();

  const chain: CHAIN_CONFIG_TYPE = import.meta.env.VITE_NODE_ENV === 'production' ? 'polygonMainnet' : 'polygonTestnet';
  const web3AuthNetwork = import.meta.env.VITE_NODE_ENV === 'production' ? 'cyan' : 'testnet';

  const change_page_title = (title: string) => {
    document.title = title;
  };

  const change_favicon = (favicon: string) => {
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement | null;
    if (linkElement) {
      linkElement.type = 'image/x-icon';
      linkElement.rel = 'shortcut icon';
      linkElement.href = favicon;
    } else {
      const newLinkElement = document.createElement('link');
      newLinkElement.type = 'image/x-icon';
      newLinkElement.rel = 'shortcut icon';
      newLinkElement.href = favicon;
      document.getElementsByTagName('head')[0].appendChild(newLinkElement);
    }
  };

  useEffect(() => {
    // Change the page title dynamically
    change_page_title(`${getClubNameFromHostname()}`);
    // Change the favicon dynamically
    change_favicon(getFaviconFromHostname());
  }, []);

  return (
    <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={clubTheme}>
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </Web3AuthProvider>
  );
}

export default App;
