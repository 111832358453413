import { Stack, IconButton, InputBase, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import greyScale from '../../themes/default/colors/greyscale';
import { t } from 'i18next';
import { useAppSelector } from '../../redux/hooks';
import palette from '../../themes/default/colors/palette';

const CustomSearchInput = styled(InputBase)<{
  component?: React.ElementType;
}>({
  root: {
    zindex: 10000
  },
  input: {
    '&::placeholder': {}
  }
});

interface SearchBarProps {
  showFilterIcon?: boolean;
  isInAppBar?: boolean;
}

const SearchBar = (props: SearchBarProps) => {
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  return (
    <Stack
      direction="row"
      alignContent="center"
      alignItems="center"
      sx={{
        bgcolor: darkTheme
          ? props.isInAppBar
            ? 'rgba(255,255,255,0.04)'
            : palette.dark2
          : props.isInAppBar
          ? 'rgba(0,0,0,0.04)'
          : greyScale[100],
        height: 56,
        borderRadius: 2,
        pl: 3,
        pr: 2,
        width: '100%'
      }}
    >
      <SearchIcon sx={{ color: darkTheme ? greyScale[500] : greyScale[900] }} />
      <CustomSearchInput
        sx={{
          flex: 1,
          pl: 1
        }}
        placeholder={t('home.whatEventAreYouLookinFor') ?? ''}
      />
      {props.showFilterIcon ? (
        <IconButton sx={{ color: 'primary.main' }}>
          <TuneIcon />
        </IconButton>
      ) : null}
    </Stack>
  );
};

export default SearchBar;
