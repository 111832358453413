import { Modal, Typography, Button, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import paymentErrorImage from '../../../../assets/images/errors/payment-error.png';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 8,
  p: 3,
  width: { xs: '66%', sm: '50%', lg: '40%', xl: '30%' }
};

interface AccountExistModalProps {
  isAccountExistModalOpen: boolean;
  handleAccountExistModal: () => void;
  children: React.ReactNode;
  provider: string;
}

const AccountExistModal = (props: AccountExistModalProps) => {
  return (
    <Modal open={props.isAccountExistModalOpen} onClose={props.handleAccountExistModal}>
      <Box sx={style}>
        <Stack direction="column" spacing={3} pt={3} pb={3}>
          <Typography variant="lg">
            <Trans i18nKey="login.youAlreadyHaveAnAccount" values={{ provider: props.provider }} components={[<b />]} />
          </Typography>
          <Stack direction="column" justifyContent="center" spacing={3} pt={3}>
            {props.children}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AccountExistModal;
