import React from 'react';
import { Box, Typography, useMediaQuery, Theme, CircularProgress } from '@mui/material';
import bg from '/marque-blanche/hero.png';
import Countdown from 'react-countdown';
import '/fonts/SpaceGrotesk-VariableFont_wght.ttf';
import { useNavigate } from 'react-router-dom';

interface HeroProps {
  title: string;
  subtitle: string;
  image: string;
  showMintButton: boolean;
  onClickMintButton: any;
  mintLoading: boolean;
}

const Hero: React.FC<HeroProps> = ({ title, subtitle, image, onClickMintButton, showMintButton, mintLoading }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const renderer = ({ hours, days, minutes, seconds, completed }: any) => {
    return (
      <div
        className="z-10 py-9 h-[55px] sm:w-[360px] w-[270px] mb-4 flex justify-evenly mt-5 items-start font-sans"
        style={{
          fontFamily: 'Helvetica'
        }}
      >
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10 flex">
            {days <= 9 ? '0' : ''}
            {days}{' '}
          </div>
          <div className="font-bold text-[#EC5A4D] sm:text-sm text-xs z-10">JOURS</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {hours <= 9 ? '0' : ''}
            {hours}
          </div>
          <div className="font-bold text-[#EC5A4D] sm:text-sm  text-xs z-10">HEURES</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px] ">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {minutes <= 9 ? '0' : ''}
            {minutes}
          </div>
          <div className="font-bold text-[#EC5A4D] z-10 sm:text-sm text-xs">MINUTES</div>
        </div>
        <div className="text-2xl font-bold">:</div>
        <div className="flex flex-col justify-center items-center w-[4px]">
          <div className="font-bold sm:text-3xl text-2xl z-10">
            {' '}
            {seconds <= 9 ? '0' : ''}
            {seconds}
          </div>
          <div className="font-bold text-[#EC5A4D] z-10 sm:text-sm text-xs">SECONDES</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Box
        width={'100%'}
        sx={{
          height: isDesktop ? '600px' : 'unset',
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            px: isDesktop ? '100px' : '10px',
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column'
          }}
        >
          <Box
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box>
              <Countdown date={new Date('Thu Sep 28 2023 21:30:00')} renderer={renderer} />
              <br />

              <Typography
                className="custom-font-text"
                sx={{
                  fontWeight: 'bold',
                  color: '#fff',
                  fontSize: isDesktop ? '50px' : '25px',
                  textTransform: 'uppercase',
                  fontFamily: 'Space Grotesk',
                  fontStyle: 'normal'
                }}
              >
                {title}{' '}
              </Typography>
              <br />
              <Typography
                sx={{
                  fontSize: 16,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontFamily: 'Helvetica ! important'
                }}
              >
                {subtitle}
              </Typography>
              <br />
              <br />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !isSmall ? 'center' : ''
                }}
              >
                {showMintButton && (
                  <Box
                    className="custom-font-text"
                    height={50}
                    width={!isDesktop ? '90%' : 210}
                    onClick={onClickMintButton}
                    sx={{
                      backgroundColor: '#EC5A4D',
                      color: '#fff',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: isDesktop ? '20px' : '0px',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      fontSize: isSmall ? 17 : 16
                    }}
                  >
                    {mintLoading ? <CircularProgress sx={{ color: '#FFF' }} /> : 'OBTENIR MAINTENANT'}
                  </Box>
                )}
                {isDesktop && (
                  <Box
                    className="custom-font-text"
                    height={50}
                    width={!isSmall ? 140 : 210}
                    onClick={() => navigate('/my-tickets')}
                    sx={{
                      backgroundColor: 'transparent',
                      border: '1px solid #EC5A4D',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      fontSize: isSmall ? 16 : 12
                    }}
                  >
                    VOIR MA COLLECTION
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            width={'100%'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: isDesktop ? 'unset' : '60px'
            }}
          >
            <img src={image} alt="LeftImage" className="w-full" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
