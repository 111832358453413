import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './redux/store';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // TODO check why I have to disable strict mode for google maps
  // <React.StrictMode>
  //
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
