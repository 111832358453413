import { CircularProgress, Typography, Box, Stack } from '@mui/material';
import OnDispayEventCard from '../../../../components/OnDispayEventCard';
import eventApi, { HomeEvent } from '../../../../services/api/event';
import Grid from '@mui/material/Grid';
import UnhandledError from '../../../../components/UnhandledError';
import { useSearchParams } from 'react-router-dom';
import noEvent from '../../../../assets/images/errors/no-event.png';
import { t } from 'i18next';

interface HomeEventListProps {
  isEvent?: boolean;
}

const HomeEventList = (props: HomeEventListProps) => {
  let [urlParams, setUrlParams] = useSearchParams();

  const { data: events, isLoading, isError } = eventApi.endpoints.getEvents.useQuery(urlParams.get('tagId') ?? '');

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh - 200px)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  if ((props.isEvent ? events?.filter(e => e.type == 'EVENT') : events)?.length == 0) {
    return (
      <Box minHeight="80vh">
        <Grid container spacing={2} mt={2} justifyContent="center" alignItems="center" height="60vh">
          <Stack direction="column" alignItems="center" spacing={3}>
            <img src={noEvent} width={300} />
            <Typography variant="h5" fontWeight={600}>
              {t('home.noEvent')}
            </Typography>
          </Stack>
        </Grid>
      </Box>
    );
  }
  return (
    <Box minHeight="80vh">
      <Grid container spacing={2} mt={2}>
        {(props.isEvent ? events?.filter(e => e.type == 'EVENT') : events?.filter(e => e.type !== 'EVENT'))?.map(
          (event: HomeEvent) => (
            <Grid key={event.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
              <OnDispayEventCard isEvent={props.isEvent} key={event.id} event={event} />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default HomeEventList;
