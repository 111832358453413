import { Typography, Card, Stack, Divider, Box, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { HomeEvent } from '../../../../services/api/event';
import greyScale from '../../../../themes/default/colors/greyscale';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { capitalize } from '../../../../utils/strings';
import PromoChipTag from '../../../Reservation/components/PromoChipTag';

interface BasketCardProps {
  event: HomeEvent;
  showEventCard: boolean;
}

const BasketCard = (props: BasketCardProps) => {
  const { tickets: selectedTickets, finalPrice, srcPrice } = useAppSelector(app => app.shoppingCartSlice);
  const { event, showEventCard } = props;
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Card variant="contained" sx={{ p: 3, mb: 3 }}>
      {isDesktop && showEventCard ? (
        <>
          <Stack direction="row" spacing={1}>
            <Box pb={0} height={100}>
              <img
                width="100%"
                height={100}
                src={event.cover!}
                alt="event cover"
                style={{ borderRadius: 20, objectFit: 'contain' }}
              />
            </Box>
            <Box>
              <Stack direction="column" spacing={1}>
                <Typography variant="h4">{event.title}</Typography>
                <Typography variant="xl" color="primary" fontWeight={600}>
                  {`${capitalize(format(new Date(event?.beginAt!), "eee dd MMMM - HH'h'mm"))}`}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="end">
                    <LocationOnIcon sx={{ width: 20, height: 20, mr: 1 }} />
                    <Typography variant="xl" color={greyScale[700]}>
                      {event?.address?.name} {`, `} {event?.address?.city}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </>
      ) : null}
      {selectedTickets?.map((ticket, index) => (
        <div key={ticket.categoryUuid + index}>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant="md" color={greyScale[500]}>{`${ticket.quantity} ${t('checkout.seat', {
              count: ticket.quantity
            })} ${ticket.categoryName == null ? '' : `${ticket.categoryName}`} 
            `}</Typography>
            <Typography
              variant="lg"
              color={ticket.promo ? greyScale[800] : greyScale[600]}
              fontWeight={600}
              sx={{ textDecoration: ticket.promo ? 'line-through' : 'none' }}
            >{`${Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR'
            }).format(ticket.srcPrice * ticket.quantity)}`}</Typography>
          </Stack>
          {ticket.promo && (
            <Stack direction="row" justifyContent="space-between" mb={1}>
              <PromoChipTag promo={ticket.promo} quantity={ticket.quantity} srcPrice={ticket.srcPrice} />
              <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR'
              }).format(ticket.finalPrice * ticket.quantity)}`}</Typography>
            </Stack>
          )}
        </div>
      ))}
      <Divider />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Typography variant="md" color={greyScale[500]}>
          {t('checkout.total')}
        </Typography>
        <Typography
          variant="lg"
          color={greyScale[600]}
          fontWeight={600}
          sx={{ textDecoration: selectedTickets.find(e => e.promo) ? 'line-through' : 'none' }}
        >{`${Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR'
        }).format(srcPrice)}`}</Typography>
      </Stack>
      {selectedTickets.find(e => e.promo) && (
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography variant="md" color={greyScale[500]}></Typography>
          <Typography variant="lg" color={greyScale[600]} fontWeight={600}>{`${Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          }).format(finalPrice)}`}</Typography>
        </Stack>
      )}
    </Card>
  );
};

export default BasketCard;
