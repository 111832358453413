import { t, use } from 'i18next';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import eventApi from '../../services/api/event';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import paymentSuccessImage from '../../assets/images/success/payment-success.png';
import UnhandledError from '../../components/UnhandledError';
import { useAppSelector } from '../../redux/hooks';
import { InfoOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

function PaymentSuccess() {
  const { eventId } = useParams();
  const { data: event, isLoading, isError } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const navigate = useNavigate();
  const { darkTheme } = useAppSelector(state => state.themeSlice);

  const [showContinue, setShowContinue] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContinue(true);
    }, 2500);
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" height={`100vh`}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" justifyContent="center" mt={2}>
          <img alt="payment-success" src={paymentSuccessImage} />
        </Box>
      </Box>
      <Typography variant="h4" color="primary" textAlign="center" mt={4} mb={2}>
        {t('paymentSuccess.congratulations')}
      </Typography>
      <Box width={300}>
        <Typography variant="lg">
          <Trans
            i18nKey="paymentSuccess.congratulationsMessage"
            components={[<b />]}
            values={{ eventName: event?.title }}
          />
        </Typography>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={darkTheme ? 'rgba(255,255,255,0.075)' : 'rgba(0,0,0,0.075)'}
          borderRadius={2}
          mt={3}
          p={1}
          pb={3}
          spacing={2}
        >
          <InfoOutlined />
          <Typography fontSize="0.85rem" textAlign="center">
            {t('paymentSuccess.ticketSentence1')}
          </Typography>
          <Typography fontSize="0.85rem" textAlign="center">
            {t('paymentSuccess.ticketSentence2')}
          </Typography>
        </Stack>
      </Box>

      <Stack direction="column" spacing={2} mt={4}>
        <Button
          variant="contained"
          disabled={!showContinue}
          sx={{ width: 276 }}
          onClick={() => navigate(`/ticket-details/${eventId}`)}
        >
          {showContinue ? t('paymentSuccess.discoverTicket') : <CircularProgress sx={{ color: '#FFF' }} />}
        </Button>
      </Stack>
    </Stack>
  );
}

export default PaymentSuccess;
