import {
  Card,
  Stack,
  Checkbox,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  Link,
  Snackbar,
  Alert
} from '@mui/material';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { IFormData } from '../..';
import { useAppSelector } from '../../../../redux/hooks';
import paymentApi, { PurchaseType } from '../../../../services/api/payment';
import shadows from '../../../../themes/default/shadows';
import userApi from '../../../../services/api/user';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authSlice } from '../../../../services/auth';
import { useDispatch } from 'react-redux';
import ticketCategoriesApi from '../../../../services/api/ticket-category';
import subscriptionApi from '../../../../services/api/subscription';

interface CheckoutFormProps {
  subscriptionUuid: string;
}

const InvitForm = (props: CheckoutFormProps) => {
  const { subscriptionUuid } = props;
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { tickets: selectedTickets } = useAppSelector(app => app.shoppingCartSlice);
  const formik = useFormikContext<IFormData>();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [checkboxError, setCheckboxError] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const { data: user } = userApi.endpoints.getUser.useQueryState();
  const [createInvitReservation, { data: paymentIntent }] = paymentApi.endpoints.createInvitReservation.useMutation();
  const [claimSubscription] = subscriptionApi.endpoints.claimSubscription.useMutation();
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [canBuyTickets] = ticketCategoriesApi.endpoints.canBuyTickets.useMutation();
  let [urlParams, setUrlParams] = useSearchParams();
  const invit = urlParams.get('invit');
  const subscriptionInvite = urlParams.get('subscriptionInvite');

  const payButtonStyle = isDesktop ? { zindex: 200 } : { position: 'fixed', bottom: 0, left: 0, right: 0, zindex: 200 };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const logout = async () => {
    try {
      dispatch(authSlice.actions.removeCredentials());
      // dispatch(baseApi.util.resetApiState());
      localStorage.clear();
      await logout();
    } catch (error) {}
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const handleSubmit = async (e: any) => {
    if (!checked) {
      setCheckboxError(true);
      return;
    }
    if (!user?.uuid) {
      return;
    }
    if (user?.publicKey === null || user?.publicKey === undefined || user?.publicKey === '') {
      await logout();
      return;
    }
    e.preventDefault();
    formik.submitForm();
    if (!formik.isValid) {
      return;
    }
    if (!subscriptionInvite) {
      setErrorMessage("Il semble que n'utiliser pas le bon lien d'invitation.");
      setErrorSnackbar(true);
      return;
    }

    try {
      setIsLoading(true);

      // await canBuyTickets({
      //   eventUuid: subscriptionUuid ?? '',
      //   categories: selectedTickets.map(e => {
      //     return { categoryUuid: e.categoryUuid, quantity: e.quantity };
      //   })
      // }).unwrap();

      // await createInvitReservation({
      //   firstName: formik.values.firstName,
      //   lastName: formik.values.lastName,
      //   email: formik.values.email,
      //   eventUuid: props.subscriptionUuid,
      //   codePromoUuid: invit ?? '',
      //   finalPrice: 0,
      //   srcPrice: 0,
      //   purchaseType: PurchaseType.Ticket,
      //   userUuid: user?.uuid,
      //   places: selectedTickets.map(cat => {
      //     return { categoryUuid: cat.categoryUuid, quantity: cat.quantity };
      //   }),
      //   status: 'COMPLETE',
      //   subscription: { subscriptionUuid }
      // }).unwrap();
      await claimSubscription({ subscriptionInvite }).unwrap();
      setIsLoading(false);
      navigate(`/subscription-payment-success/${subscriptionUuid}`);
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error.data.message);
      setErrorSnackbar(true);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {AcceptTerms()}
      <Box sx={{ height: { xs: 64, md: 0 } }} />
      {PayButton()}
    </>
  );

  function AcceptTerms() {
    return (
      <>
        <Stack direction="row" alignItems="center" mb={3}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ color: checkboxError ? 'red' : undefined }}
          />
          <Typography variant="lg" fontWeight={600} color="GrayText">
            <Trans
              i18nKey="checkout.acceptTerms"
              components={[
                <Link
                  href="https://tickieapp.notion.site/Politique-de-confidentialit-d08f2ac3727b45d896a07510518035fb"
                  target="_blank"
                />,
                <Link
                  href="https://tickieapp.notion.site/Conditions-g-n-rales-de-vente-c2dcaf87635c441182bab9982d20e426"
                  target="_blank"
                />
              ]}
            />
          </Typography>
        </Stack>
      </>
    );
  }

  function PayButton() {
    return (
      <Box sx={payButtonStyle} zIndex={200}>
        <Box m={3}>
          <Button
            variant="gradient"
            fullWidth
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{ boxShadow: shadows.primaryButton, height: 58 }}
          >
            {isLoading ? <CircularProgress /> : t('checkout.getMySubscription')}
          </Button>
        </Box>
      </Box>
    );
  }
};

export default InvitForm;
