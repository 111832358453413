const rpcUrl = (chain: 'CHILIZ' | 'POLYGON') => {
  if (chain === 'CHILIZ') {
    if (import.meta.env.VITE_NODE_ENV === 'production') {
      return 'https://rpc.ankr.com/chiliz';
    } else {
      return 'https://spicy-rpc.chiliz.com/';
    }
  } else if (chain === 'POLYGON') {
    if (import.meta.env.VITE_NODE_ENV === 'production') {
      return 'https://polygon-mainnet.g.alchemy.com/v2/otGMos0FmUHWcuOYYiafUrGW9aWI-PYl';
    } else {
      return 'https://polygon-mumbai.g.alchemy.com/v2/4LRSETVvygh2k5Zmn8jD0EHcG6nRAZk8';
    }
  }
  return 'https://rpc.ankr.com/chiliz';
};

export default rpcUrl;
