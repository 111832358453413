import { FavoriteBorderOutlined } from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import format from 'date-fns/format';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import eventApi from '../../services/api/event';
import 'add-to-calendar-button/assets/css/atcb.css';
import ShareModal from './components/ShareModal';
import EventMap from './components/Map';
import EventLottery from './components/Lottery';
import EventDescription from './components/Description';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RoomIcon from '@mui/icons-material/Room';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AddToCalendarButton from './components/AddToCalendarButton';
import shadows from '../../themes/default/shadows';
import DescriptionIconDecoration from '../../components/DescriptionIconDecoration';
import greyScale from '../../themes/default/colors/greyscale';
import DesktopAppBar from './components/DesktopAppBar';
import primary from '../../themes/default/colors/primary';
import { useAppSelector } from '../../redux/hooks';
import palette from '../../themes/default/colors/palette';
import { capitalize } from '../../utils/strings';
import UnhandledError from '../../components/UnhandledError';
import { isAfter } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import CustomFooter from '../../components/CustomFooter ';
import defaultCover from '/default-stadium.jpg';
function EventDetails() {
  const { eventId } = useParams();
  const { data: event, isLoading, isError } = eventApi.endpoints.getEventDetails.useQuery(eventId ?? '');
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const navigate = useNavigate();
  const mapRef = useRef<null | HTMLDivElement>(null);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const scrollToMap = () => mapRef?.current?.scrollIntoView();
  const subtitleColor = darkTheme ? greyScale[300] : greyScale[900];
  let [urlParams, setUrlParams] = useSearchParams();
  const promoCode = urlParams.get('promoCode');

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  function AppBar() {
    return (
      <Stack direction="row" justifyContent="space-between" zIndex="toolpit" sx={{ display: { md: 'none' } }}>
        <Link to="/home">
          <IconButton sx={{ bgcolor: alpha(greyScale[900], 0.25), m: 1 }}>
            <ArrowBackIcon sx={{ color: 'white' }} />
          </IconButton>
        </Link>
        <Box display="flex">
          <ShareModal url={`${import.meta.env.VITE_APP_BASE_URL}/event/${eventId}`} />
        </Box>
      </Stack>
    );
  }

  const cover = () => {
    return (
      <Box display="flex" my={3}>
        <img
          src={event?.cover!}
          width="100%"
          className="bg-contain"
          style={{
            borderRadius: 4
          }}
        />
      </Box>
    );
  };

  const mobileReservationButton = () => {
    return (
      <Box sx={{ display: isDesktop ? 'none' : '', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 200 }}>
        <Box m={3}>
          <Button
            variant={isAfter(new Date(), new Date(event!.endAt)) ? 'contained' : 'gradient'}
            fullWidth
            disabled={isAfter(new Date(), new Date(event!.endAt))}
            onClick={() => {
              navigate(`/reservation/${event?.uuid}${promoCode ? `?promoCode=${promoCode}` : ''}`);
            }}
            sx={{ boxShadow: shadows.primaryButton, height: 58, fontSize: 18 }}
          >
            {t('event.order')}
          </Button>
        </Box>
      </Box>
    );
  };

  const desktopReservationCard = () => {
    return (
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Card
          sx={{
            minWidth: 200,
            border: `1px solid ${darkTheme ? '#000000' : greyScale[300]}`,
            bgcolor: darkTheme ? palette.dark2 : alpha(primary[100], 0.25),
            boxShadow: shadows.primaryButton,
            position: 'sticky',
            borderRadius: 4,
            top: 24
          }}
        >
          <Stack direction="row" m={2}>
            <DescriptionIconDecoration>
              <ConfirmationNumberIcon />
            </DescriptionIconDecoration>
            <Stack direction="column" pl={2} spacing={0.5}>
              {isBefore(new Date(), new Date(event!.endAt)) ? (
                <Typography variant="xl" fontWeight={700}>{`${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(event!.minPrice)} - ${Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(event!.maxPrice)}`}</Typography>
              ) : (
                <Stack direction="column" justifyContent="center" height="100%">
                  <Typography variant="xl" fontWeight={700} alignItems="center" justifyContent="end">
                    {t('event.eventIsFinished')}
                  </Typography>
                </Stack>
              )}
              {isBefore(new Date(), new Date(event!.endAt)) && (
                <Typography variant="md">{t('event.priceOfTicketDepends')}</Typography>
              )}
            </Stack>
          </Stack>
          <Box m={3}>
            <Button
              variant={isAfter(new Date(), new Date(event!.endAt)) ? 'contained' : 'gradient'}
              fullWidth
              disabled={isAfter(new Date(), new Date(event!.endAt))}
              onClick={() => {
                navigate(`/reservation/${event?.uuid}${promoCode ? `?promoCode=${promoCode}` : ''}`);
              }}
              sx={{
                boxShadow: shadows.primaryButton,
                height: 58,
                fontSize: 18,
                borderRadius: 2
              }}
            >
              {t('event.order')}
            </Button>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <>
      {event ? (
        <div>
          <div
            className="-z-20 top-0 left-0 absolute w-full h-full max-h-[800px] bg-contain md:bg-cover backdrop-blur-5xl bg-no-repeat"
            style={{
              backgroundImage: `url(${event.cover})`,
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="-z-20 top-0 left-0 absolute w-full h-full backdrop-blur-3xl bg-gradient-to-t  from-dark1/[1] from-0% via-black/30 to-blue-900 bg-no-repeat ">
              <div className="-z-10 bottom-0 left-0 absolute w-full h-96  bg-cover bg-gradient-to-t  from-dark1/[1]  to-black/1 bg-no-repeat " />
            </div>
          </div>

          <header>
            <DesktopAppBar eventId={eventId ?? ''} />
            {AppBar()}
          </header>

          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack direction="column" m={3} mt={0} sx={{ width: { lg: 1000 }, mt: { xs: -3 } }}>
              {cover()}
              <Stack direction={isDesktop ? 'row' : 'column'} justifyContent="space-between">
                <Box width="100%" sx={{ pr: { md: 3 } }}>
                  <Typography variant="h3" fontWeight={700} mb={2}>
                    {event?.title}
                  </Typography>
                  <Stack direction="row">
                    {event?.tags.map(tag => (
                      <Chip key={tag} label={tag} variant="square" sx={{ mr: 1 }} />
                    ))}
                  </Stack>
                  <Box mt={2} mb={2} mr={2}>
                    <Divider sx={{ display: { md: 'none' } }} />
                  </Box>
                  <Stack
                    direction={isDesktop ? 'row' : 'column'}
                    divider={isDesktop ? <Divider orientation="vertical" /> : null}
                    spacing={isDesktop ? 4 : 0}
                  >
                    <Stack direction={'row'} mb={2}>
                      <DescriptionIconDecoration>
                        <CalendarMonthIcon />
                      </DescriptionIconDecoration>
                      <Stack direction="column" pl={2} spacing={0.5}>
                        <Typography variant="xl" fontWeight={700}>{`${capitalize(
                          format(new Date(event?.beginAt!), 'EEEE dd MMMM yyyy')
                        )}`}</Typography>
                        <Typography variant="md" color={subtitleColor}>{`${format(
                          new Date(event?.beginAt!),
                          "HH'h'mm"
                        )} - ${format(new Date(event?.endAt!), "HH'h'mm (OOOO)")}`}</Typography>
                        <AddToCalendarButton beginAt={event.beginAt} endAt={event.endAt} title={event.title} />
                      </Stack>
                    </Stack>
                    {event.address == null ? null : (
                      <Stack direction="row" mb={2} onClick={scrollToMap}>
                        <DescriptionIconDecoration>
                          <RoomIcon />
                        </DescriptionIconDecoration>
                        <Stack direction="column" pl={2} spacing={0.5}>
                          <Typography
                            variant="xl"
                            fontWeight={700}
                          >{`${event?.address.name}, ${event?.address.city}`}</Typography>
                          <Typography
                            variant="md"
                            color={subtitleColor}
                          >{`${event?.address.street}, ${event?.address.zipCode}, ${event?.address.city}`}</Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                  <Stack direction="row" mb={2} sx={{ display: { md: 'none' } }}>
                    <DescriptionIconDecoration>
                      <ConfirmationNumberIcon />
                    </DescriptionIconDecoration>
                    <Stack direction="column" pl={2} spacing={0.5}>
                      {isBefore(new Date(), new Date(event.endAt)) ? (
                        <>
                          <Typography variant="xl" fontWeight={700}>{`${Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(event?.minPrice)} - ${Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(event?.maxPrice)}`}</Typography>
                          <Typography variant="md" color={subtitleColor}>
                            {t('event.priceOfTicketDepends')}
                          </Typography>
                        </>
                      ) : (
                        <Stack direction="column" justifyContent="center" height="100%">
                          <Typography variant="xl" fontWeight={700} alignItems="center" justifyContent="end">
                            {t('event.eventIsFinished')}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="start">
                    <Box display="flex" onClick={() => navigate(`/organization/${event?.organization.uuid}`)}>
                      <Avatar
                        src={event?.organization.logo}
                        sx={{ width: 64, height: 64 }}
                        imgProps={{ sx: { objectFit: 'contain' } }}
                      />
                      <Stack direction="column" pl={2}>
                        <Typography variant="h6" fontWeight={700}>
                          {event?.organization.name}
                        </Typography>
                        <Typography variant="md" color={subtitleColor}>
                          {event?.organization.type}
                        </Typography>
                      </Stack>
                    </Box>
                    <IconButton>
                      <FavoriteBorderOutlined />
                    </IconButton>
                  </Stack>
                  <Box height={24} />
                  <EventDescription event={event} />
                  <Box mt={3} mb={3}>
                    <Divider />
                  </Box>
                  <EventLottery event={event} />
                  <Box mt={3} mb={3}>
                    <Divider />
                  </Box>
                  {event.address == null ? null : <EventMap event={event} ref={mapRef} />}
                </Box>
                {desktopReservationCard()}
              </Stack>
            </Stack>
          </Stack>
          <Box height={64} />
          {mobileReservationButton()}
          <CustomFooter />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default EventDetails;
