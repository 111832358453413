import { Avatar, Box, CircularProgress, Divider, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useState } from 'react';
import organizationApi from '../../services/api/organization';
import Footer from '../../components/Footer';
import TabPanel from '../../components/TabPanel';
import OrganizationEventsCard from '../../components/OrganizationEventsCard';
import UnhandledError from '../../components/UnhandledError';

const OrganizationDetails = () => {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const {
    data: organization,
    isLoading,
    isError
  } = organizationApi.endpoints.getOrganizationDetails.useQuery(organizationId ?? '');

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={`calc(100vh)`}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <UnhandledError />;
  }

  const appBar = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} m={1}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{t('organizationDetails.organization')}</Typography>
      </Stack>
    );
  };

  return (
    <>
      {appBar()}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Avatar src={organization?.logo} sx={{ width: 120, height: 120 }} imgProps={{ sx: { objectFit: 'contain' } }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Typography variant="h3">{organization?.name}</Typography>
      </Box>
      <Box sx={{ m: 2 }}>
        <Divider />
      </Box>
      <Typography variant="h4" fontWeight={500} textAlign="center">{`${organization?.events.length} ${t(
        'organizationDetails.events'
      )}`}</Typography>
      <Box sx={{ m: 2 }}>
        <Divider />
      </Box>

      <Box alignItems="center" justifyContent="center" width="100%" display="flex">
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('organizationDetails.eventsC')} style={{ minWidth: '45vw' }} />
          <Tab label={t('organizationDetails.about')} style={{ minWidth: '45vw' }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box m={3} minHeight="80vh">
          {organization?.events.map(event => (
            <OrganizationEventsCard key={event.id} event={event} />
          ))}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box minHeight="80vh"></Box>
      </TabPanel>
      <Footer />
    </>
  );
};

export default OrganizationDetails;
