import { Typography, Box } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { Event } from '../../../../services/api/event';
import greyScale from '../../../../themes/default/colors/greyscale';

interface EventDescriptionProps {
  event: Event;
}

const EventDescription = (props: EventDescriptionProps) => {
  const { event } = props;
  const { darkTheme } = useAppSelector(state => state.themeSlice);
  const subtitleColor = darkTheme ? greyScale[300] : greyScale[900];

  return (
    <>
      <Typography variant="h5">{t('event.description')}</Typography>
      <Box height={16} />
      <Typography variant="lg" color={subtitleColor}>
        <div
          dangerouslySetInnerHTML={{
            __html: event?.description ?? ''
          }}
        />
      </Typography>
    </>
  );
};

export default EventDescription;
