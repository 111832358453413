import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import ticketApi, { Ticket } from '../../services/api/ticket';
import { useState } from 'react';
import userApi from '../../services/api/user';
import Slider from 'react-slick';
import { useNavigate, useParams } from 'react-router-dom';
import { Tilt } from 'react-tilt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { useAppSelector } from '../../redux/hooks';
import QrCodeComponent from './components/QrCodeComponent';
import AddToWalletButton from './components/AddToWalletButton';
import { isIOS, isMacOs } from 'react-device-detect';
import NextSlideButton from './components/NextSlideButton';
import PreviousSlideButton from './components/PreviousSlideButton';
import TicketInformations from './components/TicketInformations';
import subscriptionApi from '../../services/api/subscription';

const Scan = ({ scanType }: { scanType: 'SUBSCRIPTION' | 'TICKET' }) => {
  const { eventId } = useParams();
  const { data: user } = userApi.endpoints.getUser.useQuery();
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();
  useAppSelector(state => state.themeSlice);
  //TODO handle not found event
  const { data: event } = ticketApi.endpoints.getTicketDetails.useQuery(eventId!, { skip: !eventId });
  const {
    data: subsriptions,
    isLoading: userSubscriptionsIsLoading,
    isError: userSubscriptionsIsError
  } = subscriptionApi.endpoints.getUserSubscriptions.useQuery(undefined, { refetchOnMountOrArgChange: true });

  const style = {
    boxShadow: 24,
    borderRadius: 8,
    padding: '32px',
    maxWidth: 370,
    width: { xs: '100%', sm: 370 },
    height: '650px'
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextSlideButton />,
    prevArrow: <PreviousSlideButton />,
    afterChange: (current: number) => setActiveSlide(current),
    customPaging: (i: any) => {
      if (i == activeSlide) {
        return <span className="flex justify-center item-center w-4 h-4 bg-white rounded-full -mt-6 "></span>;
      } else {
        return <span className="flex w-4 h-4  bg-gray-300 rounded-full opacity-50 -mt-6"></span>;
      }
    }
  };

  const appBar = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={1} m={2} position="absolute" zIndex={100}>
        <IconButton
          onClick={() =>
            scanType == 'SUBSCRIPTION' ? navigate('/my-tickets') : navigate(`/ticket-details/${eventId}`)
          }
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{t('myTickets.myTickets')}</Typography>
      </Stack>
    );
  };

  const ticketSeperator = (isScanned: boolean) => {
    if (isScanned) {
      return (
        <>
          <div
            style={{
              border: 'none',
              borderTop: '6px dashed black',
              width: '85%',
              position: 'absolute',
              top: '41.4%'
            }}
          />
          <div
            style={{
              border: 'none',
              borderTop: '6px dashed black',
              width: '85%',
              position: 'absolute',
              top: '44.7%'
            }}
          />
          <div
            style={{
              backgroundColor: 'black',
              height: 24,
              width: '100%',
              position: 'absolute',
              top: '41.8%'
            }}
          />
        </>
      );
    } else {
      return (
        <div
          style={{
            border: 'none',
            borderTop: '6px dashed black',
            width: '85%',
            position: 'absolute',
            top: '43%'
          }}
        />
      );
    }
  };

  return (
    <>
      {appBar()}
      <Slider {...settings}>
        {/* TODO orderby logic shoud be in backend */}
        {scanType == 'TICKET'
          ? event?.tickets.map(ticket => (
              <ReponsiveTilt>
                <div
                  className={`flex !important justify-center items-center w-[full] h-[100vh]  bg-[url('${event?.event.cover}')] px-10 pt-16 pb-32 overflow-auto`}
                >
                  <Box sx={style} className={`sm:h-[720px] relative m-auto sel `}>
                    <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[40%] -left-6`}></div>
                    <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[40%] -right-6`}></div>
                    <Stack direction="column" justifyContent="center" spacing={3}>
                      <TicketInformations ticket={ticket} type={'TICKET'} />
                      <Stack direction="row" justifyContent="center" alignItems="center">
                        {ticketSeperator(ticket.isScanned)}
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center" pt={6}>
                        {event?.event && <QrCodeComponent ticketQrCode={ticket.qrCode} event={event?.event} />}
                        {ticket.isScanned && (
                          <div className="rounded-full bg-red-500  px-2 mt-1 -mb-4">
                            <Typography variant="sm" fontWeight={700}>
                              {t('myTickets.scanned')}
                            </Typography>
                          </div>
                        )}
                      </Stack>
                      {(isIOS || isMacOs) && (
                        <div className="flex justify-center  space-x-2 -mx-4">
                          <AddToWalletButton type="apple" ticket={ticket} />
                          {/* <AddToWalletButton type="google" ticket={ticket} /> */}
                        </div>
                      )}
                    </Stack>
                    {/* background images effects */}
                    <div
                      style={{ backgroundImage: `url(${event && event?.event?.cover})` }}
                      className={`-z-20 rounded-3xl top-0 left-0 absolute w-full h-full bg-cover backdrop-blur-2xl`}
                    ></div>
                    <div className="-z-20  rounded-3xl top-0 left-0 absolute w-full h-full backdrop-blur-xl bg-gradient-to-t  from-black/[.06] to-blue-500"></div>
                  </Box>
                </div>
              </ReponsiveTilt>
            ))
          : subsriptions?.map(subsription => (
              <ReponsiveTilt>
                <div
                  className={`flex !important justify-center items-center w-[full] h-[100vh]  bg-[url('${subsription.cover}')] px-10 pt-16 pb-32 overflow-auto`}
                >
                  <Box sx={style} className={`sm:h-[720px] relative m-auto sel `}>
                    <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[40%] -left-6`}></div>
                    <div className={`w-[50px] h-[50px] bg-[#0b0c0f] rounded-full absolute top-[40%] -right-6`}></div>
                    <Stack direction="column" justifyContent="center" spacing={3}>
                      <TicketInformations subscription={subsription.customers[0]} type={'SUBSCRIPTION'} />
                      <Stack direction="row" justifyContent="center" alignItems="center">
                        {ticketSeperator(subsription.customers[0].isScanned)}
                      </Stack>
                      <Stack direction="column" alignItems="center" justifyContent="center" pt={6}>
                        {event?.event && (
                          <QrCodeComponent ticketQrCode={subsription.customers[0].qrCode ?? ''} event={event?.event} />
                        )}
                        {subsription.customers[0].isScanned && (
                          <div className="rounded-full bg-red-500  px-2 mt-1 -mb-4">
                            <Typography variant="sm" fontWeight={700}>
                              {t('myTickets.scanned')}
                            </Typography>
                          </div>
                        )}
                      </Stack>
                      {(isIOS || isMacOs) && (
                        <div className="flex justify-center  space-x-2 -mx-4">
                          {/* <AddToWalletButton type="apple" ticket={ticket} /> */}
                          {/* <AddToWalletButton type="google" ticket={ticket} /> */}
                        </div>
                      )}
                    </Stack>
                    {/* background images effects */}
                    <div
                      style={{ backgroundImage: `url(${event && event?.event?.cover})` }}
                      className={`-z-20 rounded-3xl top-0 left-0 absolute w-full h-full bg-cover backdrop-blur-2xl`}
                    ></div>
                    <div className="-z-20  rounded-3xl top-0 left-0 absolute w-full h-full backdrop-blur-xl bg-gradient-to-t  from-black/[.06] to-blue-500"></div>
                  </Box>
                </div>
              </ReponsiveTilt>
            ))}
      </Slider>
    </>
  );
};

export default Scan;

interface ReponsiveTiltProps {
  children?: React.ReactNode;
}

const ReponsiveTilt = (props: ReponsiveTiltProps) => {
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  if (isDesktop) {
    return <Tilt options={{ scale: 1, perspective: 1800, max: 12 }}>{props.children}</Tilt>;
  } else {
    return <>{props.children}</>;
  }
};
