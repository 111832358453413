import { Navigate, useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Hero from '../components/Hero';
import Rewards from '../components/Rewards';
import Main from '../components/Main';
import Faq from '../components/Faq';
import { Box, useMediaQuery } from '@mui/material';
import Contact from '../components/Contact';
import data from './nice.json';
import Footer from '../components/Footer';
function NiceHome() {
  return (
    <Box>
      <Header logo={data.logo} />
      {/* <Hero title={data.title} subtitle={data.subtitle} image={data.image} /> */}
      {/* <Rewards rewards={data.rewards} legend={data.legend} /> */}
      {/* <Main steps={data.steps} /> */}
      {/* <Events /> */}
      <Contact />
      <Faq faq={data.faq} />
      <Footer logo={data.logo} />
    </Box>
  );
}

export default NiceHome;
