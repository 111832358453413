import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    borderTop: `1px solid ${'gray'}`,
    backgroundColor: 'transparent',
    fontFamily: 'Helvetica !important',
    color: 'white',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: 'Helvetica ! important',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function Faq({ faq }: { faq: any }) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <>
      <Typography
        className="custom-font-text"
        sx={{
          fontWeight: 'bold',
          fontSize: '50px !important',
          color: '#fff',
          textTransform: 'uppercase',
          textAlign: 'center',
          mt: '150px',
          px: isDesktop ? ' 100px' : '8px'
        }}
      >
        FAQ
      </Typography>
      <Typography sx={{ textAlign: 'center', marginX: '10px', fontFamily: 'Helvetica ' }}>
        Pour en savoir plus, rendez-vous sur notre Centre d’Aide
      </Typography>
      <br />
      <br />
      <Box
        sx={{
          px: isDesktop ? ' 100px' : '8px'
        }}
      >
        {faq.map((item: any, index: number) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              fontFamily: 'Helvetica '
            }}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography
                sx={{
                  fontFamily: 'Helvetica '
                }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontFamily: 'Helvetica ',
                  whiteSpace: 'pre-line'
                }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <br />
      <br />
      <br />
    </>
  );
}
