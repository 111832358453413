import {
  Card,
  Stack,
  Checkbox,
  Typography,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  Link,
  Snackbar,
  Alert
} from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { PaymentIntent } from '@stripe/stripe-js';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { IFormData } from '../..';
import { useAppSelector } from '../../../../redux/hooks';
import paymentApi from '../../../../services/api/payment';
import shadows from '../../../../themes/default/shadows';
import PaymentErrorModal from '../PaymentErrorModal';
import { useDispatch } from 'react-redux';
import { authSlice } from '../../../../services/auth';
import userApi from '../../../../services/api/user';
import ticketCategoriesApi from '../../../../services/api/ticket-category';

interface CheckoutFormProps {
  paymentIntent: PaymentIntent;
  eventId: string;
}

const CheckoutForm = (props: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormikContext<IFormData>();
  const dispatch = useDispatch();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const [checked, setChecked] = useState(true);
  const [checkboxError, setCheckboxError] = useState(false);
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const { data: user } = userApi.endpoints.getUser.useQueryState();
  const [updatePaymentIntent] = paymentApi.endpoints.updatePaymentIntent.useMutation();
  const [canBuyTickets] = ticketCategoriesApi.endpoints.canBuyTickets.useMutation();
  const [openErrorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { finalPrice, tickets: selectedTickets, promo } = useAppSelector(app => app.shoppingCartSlice);

  const payButtonStyle = isDesktop ? { zindex: 200 } : { position: 'fixed', bottom: 0, left: 0, right: 0, zindex: 200 };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const logout = async () => {
    try {
      dispatch(authSlice.actions.removeCredentials());
      // dispatch(baseApi.util.resetApiState());
      localStorage.clear();
      await logout();
    } catch (error) {}
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    if (!checked) {
      setCheckboxError(true);
      return;
    }
    e.preventDefault();
    formik.submitForm();
    if (!formik.isValid) {
      return;
    }

    if (user?.publicKey === null || user?.publicKey === undefined || user?.publicKey === '') {
      await logout();
      return;
    }

    try {
      await canBuyTickets({
        eventUuid: props.eventId ?? '',
        categories: selectedTickets.map(e => {
          return { categoryUuid: e.categoryUuid, quantity: e.quantity };
        })
      }).unwrap();
    } catch (error: any) {
      setIsLoading(false);
      setErrorMessage(error?.data?.message);
      setErrorSnackbar(true);
      return;
    }

    await updatePaymentIntent({
      payementIntentId: props.paymentIntent.id,
      userMetadata: {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        eventUuid: props.eventId
      }
    }).unwrap();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${import.meta.env.VITE_APP_BASE_URL}/payment-success/${props.eventId}`,
        receipt_email: formik.values.email
      }
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error?.message ?? '');
      handleOpenErrorModal();
    } else {
      setMessage('An unexpected error occurred.');
      handleOpenErrorModal();
    }
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', minHeight: 60, justifyContent: 'center', alignItems: 'center' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <PaymentErrorModal
        message={message}
        handleCloseErrorModal={handleCloseErrorModal}
        openErrorModal={openErrorModal}
        retry={handleSubmit}
      />
      <Card variant="contained" sx={{ p: 3, mb: 3 }}>
        {finalPrice > 0 ? <PaymentElement id="payment-element" /> : <Box sx={{ height: 100, width: '100%' }} />}
      </Card>
      {AcceptTerms()}
      <Box sx={{ height: { xs: 64, md: 0 } }} />
      {PayButton()}
    </>
  );

  function AcceptTerms() {
    return (
      <>
        <Stack direction="row" alignItems="center" mb={3}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ color: checkboxError ? 'red' : undefined }}
          />
          <Typography variant="lg" fontWeight={600} color="GrayText">
            <Trans
              i18nKey="checkout.acceptTerms"
              components={[
                <Link
                  href="https://tickieapp.notion.site/Politique-de-confidentialit-d08f2ac3727b45d896a07510518035fb"
                  target="_blank"
                />,
                <Link
                  href="https://tickieapp.notion.site/Conditions-g-n-rales-de-vente-c2dcaf87635c441182bab9982d20e426"
                  target="_blank"
                />
              ]}
            />
          </Typography>
        </Stack>
      </>
    );
  }

  function PayButton() {
    return (
      <Box sx={payButtonStyle} zIndex={200}>
        <Box m={3}>
          <Button
            variant="gradient"
            fullWidth
            disabled={isLoading || !stripe || !elements}
            onClick={handleSubmit}
            sx={{ boxShadow: shadows.primaryButton, height: 58 }}
          >
            {isLoading ? <CircularProgress /> : t('checkout.pay')}
          </Button>
        </Box>
      </Box>
    );
  }
};

export default CheckoutForm;
