import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import themeSlice from '../services/theme';
import shoppingCartSlice from '../services/shoppingCart';
import authSlice from '../services/auth';
import baseApi from '../services/api/base';

export const store = configureStore({
  reducer: {
    /**
     * Api reducers
     */
    [baseApi.reducerPath]: baseApi.reducer,

    /**
     * Slices reducers
     */
    [authSlice.name]: authSlice.reducer,
    [themeSlice.name]: themeSlice.reducer,
    [shoppingCartSlice.name]: shoppingCartSlice.reducer
  },
  // @ts-ignore
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseApi.middleware)

  //devTools: import.meta.env.PROD
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
